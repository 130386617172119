<template>
<div class="antialiased bg-gray-50 dark:bg-gray-900 relative">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="md:ml-64 h-auto pt-20">
        <div class="p-4 justify-center content">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">My Experience</h1>
            </div>
                        <div>
                            <button type="button" @click="canInsert=!canInsert" v-if="datas.workExperience!='' && !canInsert"
                                    class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Add Experience
                            </button>
                        </div>
        </div>
        </div>
        <div class="grid p-4">
            <form v-if="isLoaded" id="formData" @submit.prevent="sendData">
                <div class="flex flex-col md:flex-row gap-4 w-full">
                    <div class="w-full md:w-1/2" v-if="datas.workExperience=='' || canInsert">
                        <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full">
                            <FormInput placeholder="Institution" label="Institution" inputType="text"  required=true small=false name="institution" :value="curExperience.institution "></FormInput>
                            <FormInput placeholder="Position" label="Position" inputType="text"  required=true small=false name="position" :value="curExperience.position"></FormInput>
                            <div class="flex items-center col-span-2">
                                <input id="default-checkbox" :checked="curExperience.youStillWorkHere==1" type="checkbox" @click="youStillWorkHere=!youStillWorkHere" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Current Work here</label>
                            </div>
                            <FormInput placeholder="From" label="From" :max="maxDate" inputType="date"  required=true small=false name="from" :value="curExperience.from"></FormInput>
                            <FormInput placeholder="To" label="To" :max="maxDate" inputType="date"  :required="!youStillWorkHere" small=false name="till" :value="curExperience.till"></FormInput>
                          
                            <input type="hidden" name="youStillWorkHere" :value="youStillWorkHere==false?0:1">
                            <FormInput placeholder="Name Of Referee" label="Name Of Referee" inputType="text"  required=true small=false name="NameOfReferee" :value="curExperience.NameOfReferee"></FormInput>
                            <FormInput placeholder="Position Of Referee" label="Position Of Referee" inputType="text"  required=true small=false name="PositionOfReferee" :value="curExperience.PositionOfReferee"></FormInput>
                            <FormInput placeholder="Email Of Referee" label="Email Of Referee" inputType="email"  required=true small=false name="EmailOfReferee" :value="curExperience.EmailOfReferee"></FormInput>
                            <FormInput placeholder="Phone Of Referee" label="Phone Of Referee" inputType="text"  required=true small=false name="PhoneOfReferee" :value="curExperience.PhoneOfReferee"></FormInput>
                            <FormInput placeholder="Skills Gained" label="Skills Gained" inputType="text"  required=true small=false name="SkillsGained" :value="curExperience.SkillsGained"></FormInput>
                            <div class="flex gap-4 col-span-2">
                                    
                            <button type="submit" :disabled="canSend"
                            class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {{!isEdit?'Save':'Update'}}
                            </button>
                                <!-- <FormButton type="submit" :label="!isEdit?'Save':'Update'" bstyle="primary"></FormButton> -->
                                <!-- <FormButton type="button" label="Reset" bstyle="secondary" @click="curExperience=[]"></FormButton> -->
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/2">
                        <h1 class="text-2xl text-gray-500 mb-4">My Experiences</h1>
                        <div class="card-hover flex justify-between" v-for="(school,index) in datas.workExperience" :key="index">
                            <div class="">
                                <h1><b>Institution</b>: {{ school.institution }}</h1>
                                <h1><b>Postion</b>: {{ school.position }}</h1>
                                <h1><b>Period</b>: {{ school.from }} <span v-if="school.youStillWorkHere==0">- {{ school.till }}</span></h1>
                                <h1><b>Current</b>: {{ school.youStillWorkHere==1?'Yes':'No' }}</h1>
                            </div>
                            <div class="flex flex-col justify-between items-end">
                                <button type="button" @click="setExperience(index)">Edit</button>
                                <button type="button" v-if="sendDelete==null" @click="deleteExpirience(index)">Delete</button>
                                <div class="flex gap-2"><button class="text-red-500" v-if="sendDelete==index" @click="deleteNow(index)">Yes Delete</button> <button v-if="sendDelete==index" @click="sendDelete=null">No</button></div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            <div v-if="!isLoaded">
            </div>
            <div class="flex items-center justify-end" v-if="isLoaded">
                <div class="flex justify-between md:justify-end gap-1 md:gap-4 w-full md:w-auto">
                    <a href="/seeker/profile/education"><FormButton type="button" label="&larr; Education" bstyle="normal"></FormButton></a>
                    <a href="/seeker/profile/certificate"><FormButton type="button" label="Certificate &rarr;" bstyle="normal"></FormButton></a>
                </div>
            </div>
        </form>
        </div>
    </main>
    </div>
</template>

<script>
import headerNavVue from '../seeker/utils/headerNav.vue'
import menuNav from '../seeker/utils/menuNav.vue'
import FormInput from '../utils/FormInput.vue';
import FormButton from '../utils/FormButton.vue';
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications"
import $ from 'jquery'

let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
}

let signupOption =  {
  success: "Profile",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

    export default {
        name: 'educationPage',
        data(){
            return{
                datas:[],
                curExperience:[],
                activeCat:'',
                isLoaded:false,
                isModal: false,
                youStillWorkHere:false,
                isEdit:false,
                canSend:false,
                canInsert:false,
                dataId:null,
                sendDelete:null,
            }
        },
        components:{
            headerNavVue,
            menuNav,
            FormInput,
            FormButton, 
        },
        mounted(){
        },
        methods:{
            setExperience(id){
                this.curExperience = this.datas.workExperience[id]
                this.isEdit = true
                this.canInsert = true,
                this.dataId=id
                this.youStillWorkHere = this.curExperience.youStillWorkHere==1?true:false
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            sendData() {
                this.canSend=true
            const form = document.getElementById("formData");
            const serializedData = apiService.serializeFormData(form);
            let path = '';
            if(this.isEdit){
                path = 'applicants/'+this.datas._id+'/update/workExperience/'+this.dataId;
            }else{
                path = 'applicants/'+this.datas._id+'/insert/workExperiance';

            }
            apiService.handleForm(path,serializedData).then(
                response => {
                    $('form').trigger('reset')
                    notifier.success(response.message, signupOption)
                    this.$router.go(0);

                })
                .catch(error => {
                    console.error("Error uploading:", error);
                this.canSend=false
                });
            },
            deleteExpirience(id){
                this.sendDelete=id
            },
            getUser(data){
            this.datas = JSON.parse(data);
            this.selectedFilePreview = this.baseUrl+this.datas.picture
            this.isLoaded = true;
            document.title=this.datas.fname+" Experience Information";
            this.datas.dob = apiService.calendarDate(this.datas.dob)
            },
            modalDecision(modalAction){
                if(modalAction.status){
                    this.isModal=false
                    const id = this.datas._id;
                    apiService.deleteData('applicants/'+id+'/delete/workExperience/'+modalAction.id).then(res => {
                        this.datas.workExperience.splice(modalAction.id, 1);
                        notifier.success(res.message, signupOption)
                    });
                }
                else{
                    this.isModal=false
                }

            },
            deleteNow(id){
                const user = this.datas._id;
                apiService.deleteData('applicants/'+user+'/delete/workExperience/'+id).then(res => {
                    this.datas.workExperience.splice(id, 1);
                    notifier.success(res.message, signupOption)
                    this.sendDelete = null
                });
            }
        },
        computed: {
            maxDate() {
            const today = new Date();
            return today.toISOString().split('T')[0];
            },
        },
    }
</script>

<style scoped>
.homeImg{
    height: 400px;
}
</style>