<template>
  <div>
    <headerNavVue></headerNavVue>
    <div
      class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center flex flex-col"
    >
      <ul
        class="flex flex-wrap text-sm font-medium text-center mb-5 text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
      >
        <li @click="isActive = 'Applicant'" class="me-2 cursor-pointer">
          <a
            aria-current="page"
            :class="isActive == 'Applicant' ? 'text-blue-600 bg-gray-100' : ''"
            class="inline-block p-4 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500"
            >Applicant</a
          >
        </li>
        <li @click="isActive = 'Academia'" class="me-2 cursor-pointer">
          <a
            :class="isActive == 'Academia' ? 'text-blue-600 bg-gray-100' : ''"
            class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            >Academia</a
          >
        </li>
        <li @click="isActive = 'Industry'" class="me-2 cursor-pointer">
          <a
            :class="isActive == 'Industry' ? 'text-blue-600 bg-gray-100' : ''"
            class="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            >Industry</a
          >
        </li>
      </ul>
      <div v-if="isActive == 'Applicant'">
        <h1 class="text-2xl md:text-4xl mb-6 text-gray-600">
          Privacy & Policy ({{ isActive }})
        </h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Introduction</h2>
            <p>
              Welcome to INODIP, your gateway to meaningful opportunities. This
              Privacy Policy is designed to inform you about how we handle your
              personal information on this platform.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Information We Collect</h2>
            <p>
              To better connect you with relevant opportunities, we collect
              personal information, including your name, contact details, and
              educational/professional background.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">User-Generated Content</h2>
            <p>
              As a user, you are responsible for the accuracy and
              appropriateness of the information you share on INODIP. Please
              ensure that your content complies with our policies and Rwandan
              Republic laws.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">How We Use Your Information</h2>
            <p>
              We utilize the information you provide to enhance your experience
              on INODIP and facilitate connections with suitable opportunities.
              Rest assured, we do not share your personal information for
              marketing purposes.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Outside Links</h2>
            <p>
              External links shared on the platform are subject to the privacy
              policies of those websites. INODIP holds no responsibility for the
              content or practices of external websites.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Security Measures</h2>
            <p>
              Your security is our priority. We employ industry-standard
              security measures to protect your personal information. It's
              crucial to safeguard your login credentials for enhanced account
              security.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">
              Protection Against Requests for Sensitive Information
            </h2>
            <p>
              INODIP prioritizes your privacy and security. We will never
              request sensitive information such as credit card details,
              passwords, or any other high-privacy information. If you encounter
              any suspicious requests, please report them immediately through
              our support channels.
            </p>
          </div>
        </div>

        <h1 class="text-2xl md:text-4xl my-6 text-gray-600">
          Terms of Service ({{ isActive }})
        </h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">User Responsibilities</h2>
            <p>
              Accuracy is key. Ensure the information you provide on INODIP is
              truthful and up-to-date. Misuse or violation of platform policies
              may result in account suspension.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Prohibited Activities</h2>
            <p>
              Engaging in fraudulent activities, human trafficking, or any
              illegal/unethical conduct is strictly prohibited on INODIP.
              Violation of these terms may lead to legal action.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">No Payments Policy</h2>
            <p>
              INODIP does not facilitate payments for opportunities. Users are
              strongly advised against engaging in financial transactions on the
              platform.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl"></h2>
            <p></p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Dispute Resolution</h2>
            <p>
              Any disputes will be handled through our support channels. Legal
              action may be pursued if deemed necessary.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Changes to Terms and Privacy Policy</h2>
            <p>
              INODIP reserves the right to update the Terms of Service and
              Privacy Policy. Users will be notified of any changes to ensure
              transparency and compliance.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">
              Protection Against Requests for Sensitive Information
            </h2>
            <p>
              INODIP emphasizes the security of your information. Never respond
              to requests for sensitive information, including credit card
              details or passwords. If you come across any such requests, report
              them promptly through our support channels.
            </p>
          </div>
        </div>
      </div>

      <div v-if="isActive == 'Academia'">
        <h1 class="text-2xl md:text-4xl mb-6 text-gray-600">
          Privacy & Policy ({{ isActive }})
        </h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Introduction</h2>
            <p>
                Welcome to INODIP, where academia meets opportunities. This Privacy Policy outlines our practices regarding the handling of your institution's information on our platform.            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Information We Collect</h2>
            <p>
                To facilitate connections and opportunities, we collect information about your institution, including details, contact information, and academic offerings.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">User-Generated Content</h2>
            <p>
                Institutions bear the responsibility for the accuracy and appropriateness of the information they share on INODIP. It is crucial to ensure compliance with our policies and Rwandan Republic laws.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">How We Use Your Information</h2>
            <p>
                Your institution's information is used to connect with potential candidates and industry partners on INODIP. We do not share your institution's information for marketing purposes.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Outside Links</h2>
            <p>
                External links shared on the platform are subject to the privacy policies of those websites. INODIP is not responsible for the content or practices of external websites.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Security Measures</h2>
            <p>
                To ensure the confidentiality of your institution's information, we employ industry-standard security measures.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">
              Protection Against Requests for Sensitive Information
            </h2>
            <p>
                INODIP is dedicated to safeguarding your institution's information. Do not respond to requests for sensitive details, such as credit card information or passwords. Report any suspicious activities through our support channels.
            </p>
          </div>
        </div>

        <h1 class="text-2xl md:text-4xl my-6 text-gray-600">
          Terms of Service ({{ isActive }})
        </h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Institution Responsibilities</h2>
            <p>
                The accuracy of information provided by institutions on INODIP is paramount.
Misuse or violation of platform policies may result in account suspension.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Prohibited Activities</h2>
            <p>
                Engaging in fraudulent activities, human trafficking, or any illegal/unethical conduct is strictly prohibited on INODIP. Violation of these terms may lead to legal action.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">No Payments Policy</h2>
            <p>
                INODIP does not facilitate payments for opportunities. Institutions are strongly advised against engaging in financial transactions on the platform.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl"></h2>
            <p></p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Dispute Resolution</h2>
            <p>
                Any disputes will be addressed through our support channels. Legal action may be pursued if necessary.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Changes to Terms and Privacy Policy</h2>
            <p>
                INODIP reserves the right to update the Terms of Service and Privacy Policy. Users will be notified of any changes to ensure transparency and compliance.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">
              Protection Against Requests for Sensitive Information
            </h2>
            <p>
                INODIP prioritizes the security of your institution's information. Refrain from responding to requests for sensitive details, including credit card information or passwords. Report any suspicious activities promptly through our support channels.
            </p>
          </div>
        </div>
      </div>

      <div v-if="isActive == 'Industry'">
        <h1 class="text-2xl md:text-4xl mb-6 text-gray-600">
          Privacy & Policy ({{ isActive }})
        </h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Introduction</h2>
            <p>
                Welcome to INODIP, where industries connect with talent. This Privacy Policy outlines our practices regarding the handling of your organization's information on our platform.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Information We Collect</h2>
            <p>
                To facilitate connections and opportunities, we collect information about your organization, including details, contact information, and opportunity listings.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">User-Generated Content</h2>
            <p>
                Organizations bear the responsibility for the accuracy and appropriateness of the information they share on INODIP. It is crucial to ensure compliance with our policies and Rwandan Republic laws.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">How We Use Your Information</h2>
            <p>
                Your organization's information is used to connect with potential candidates and other entities on INODIP. We do not share your organization's information for marketing purposes.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Outside Links</h2>
            <p>
                External links shared on the platform are subject to the privacy policies of those websites. INODIP is not responsible for the content or practices of external websites.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Security Measures</h2>
            <p>
                To ensure the confidentiality of your organization's information, we employ industry-standard security measures.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">
              Protection Against Requests for Sensitive Information
            </h2>
            <p>
                INODIP is committed to ensuring the security of your organization's information. Be cautious of any requests for sensitive details such as credit card information or passwords. Report any suspicious activities through our support channels.

            </p>
          </div>
        </div>

        <h1 class="text-2xl md:text-4xl my-6 text-gray-600">
          Terms of Service ({{ isActive }})
        </h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Organization Responsibilities</h2>
            <p>
                The accuracy of information provided by organizations on INODIP is paramount.
Misuse or violation of platform policies may result in account suspension.

            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Prohibited Activities</h2>
            <p>
                Engaging in fraudulent activities, human trafficking, or any illegal/unethical conduct is strictly prohibited on INODIP. Violation of these terms may lead to legal action.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">No Payments Policy</h2>
            <p>
                INODIP does not facilitate payments for opportunities. Organizations are strongly advised against engaging in financial transactions on the platform.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl"></h2>
            <p></p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Dispute Resolution</h2>
            <p>
                Any disputes will be addressed through our support channels. Legal action may be pursued if necessary.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">Changes to Terms and Privacy Policy</h2>
            <p>
                INODIP reserves the right to update the Terms of Service and Privacy Policy. Users will be notified of any changes to ensure transparency and compliance.
            </p>
          </div>
          <div class="flex flex-col gap-3">
            <h2 class="text-xl">
              Protection Against Requests for Sensitive Information
            </h2>
            <p>
                INODIP prioritizes the security of your organization's information. Do not entertain requests for sensitive details, including credit card information or passwords. Report any suspicious activities promptly through our support channels.
            </p>
          </div>
        </div>
      </div>
    </div>
    <pageFooterVue></pageFooterVue>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import pageFooterVue from "./utils/pageFooter.vue";
export default {
  data() {
    return {
      isActive: "Applicant",
    };
  },
  components: {
    headerNavVue,
    pageFooterVue,
  },
  mounted() {
    document.title = "Privacy & Policy ";
  },
};
</script>

<style scoped></style>
