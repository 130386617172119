<script setup>
import { reactive,computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import {required,numeric,minLength,sameAs} from '@vuelidate/validators';
import apiService from '../assets/api/apiService.js';

import AWN from "awesome-notifications"
let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Reset",
  position: 'top-right',
}

let signupOption =  {
  success: "Your account is successful created.",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Reset",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

const formData = reactive({
    phone:'',
    code:'',
    isVerify:false,
    id:''
})
const confirm = reactive({
    isSet:false,
})

const verData = reactive({
    code:'',
    isVerify:false,
    id:''
})
const resetData = reactive({
    password:'',
    isReset:false,
    conf:''
})

const rules = computed(() => {
    return {
        phone:{required,numeric},
    }
})
const verrules = computed(() => {
    return {
        code:{required}
    }
})
const resrules = computed(() => {
    return {
        password:{required,minLength:minLength(8)},
        conf:{required,sameAs:sameAs(resetData.password)}
    }
})
const $v = useVuelidate(rules, formData);
const $vv = useVuelidate(verrules, verData);
const $res = useVuelidate(resrules, resetData);
const submitForm = async () => {
    const result = await $v.value.$validate();
    if(result){ 
        sendData()
    }
}

const verifyCode = async () => {
    const result = await $vv.value.$validate();
    if(result){ 
        verifyData()
    }
}

const resetFunc = async () => {
    const result = await $res.value.$validate();
    if(result){ 
        resetPassword()
    }
}
function verifyData(){
    const form = document.getElementById("formData");
        const serializedData = apiService.serializeFormData(form);
        apiService.handleForm('reset-password/verify-code/'+verData.id,serializedData).then(data=>{

            if(data){
                notifier.success(data.message, signupOption)
                verData.isVerify = false
                resetData.isReset = true
                document.title = 'Reset Password'

            }

        }
            ).catch(error=>{
                console.log(error.response.data)
                var info = error.response.data;
                notifier.alert(info.error, globalOptions)
            });
}
function resetPassword(){
    const form = document.getElementById("formData");
        const serializedData = apiService.serializeFormData(form);
        apiService.handleForm('reset-password/reset-password/'+verData.id,serializedData).then(data=>{

            if(data){
                notifier.success(data.message, signupOption)
                confirm.isSet = true
            }

        }
            ).catch(error=>{
                console.log(error.response.data)
                var info = error.response.data;
                notifier.alert(info.error, globalOptions)
            });
}
function sendData(){
        const form = document.getElementById("formData");
        const serializedData = apiService.serializeFormData(form);
        apiService.handleForm('reset-password/send-verification-code',serializedData).then(data=>{

            if(data){
                notifier.success(data.message, signupOption)
                verData.isVerify = true
                verData.id = data._id
                document.title = 'Verify Code'
            }

        }
            ).catch(error=>{
                console.log(error.response.data)
                var info = error.response.data;
                notifier.alert(info.error, globalOptions)
            });
        }
</script>
<template>
    <div>
        <headerNavVue></headerNavVue>
        <div class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center">
            <div class="border border-1 mb-5 rounded-lg overflow-hidden grid w-full grid-cols-1 md:grid-cols-3">
                <div class="flex flex-col items-center justify-center col-span-2 pt-3">
                    <!-- <h2 class="text-3xl my-3">Get Started</h2> -->
                    <img src="https://innodip.rw/img/illustrations/reset.svg" class="px-18 homeImg">
                </div>
                <div class="flex flex-col items-center bg-slate-100 border-l border-1">
                    <h1 class="text-3xl text-center border-b border-gray-300 w-full py-3">{{!verData.isVerify?'Reset Password':'Verify Code'}}</h1>
                    <form v-if="!verData.isVerify && !resetData.isReset" @submit.prevent="sendData(this)" class="w-full px-4 py-4" id="formData">
                        <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Phone number</label>
                      <input type="text" placeholder="Phone Number" @keyup="$v.phone.$touch" v-model="formData.phone" name="phone" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.phone.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        <div class="flex items-center justify-end mt-4">
                        <button type="button" @click="submitForm" class=" text-white bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send Code</button>
                        </div>
                        
                        <div class="text-sm mt-4">
                            <span>Have an account?</span> &nbsp;
                            <router-link to="/login/" class="link">Login Now</router-link>
                            </div>
                    </form>
                    <form v-if="verData.isVerify && !resetData.isReset" @submit.prevent="verifyData(this)" class="w-full px-4 py-4" id="formData">
                        <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Verification Code</label>
                      <input type="text" placeholder="Verification Code" @keyup="$vv.code.$touch" v-model="verData.code" name="code" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $vv.code.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        <div class="flex items-center justify-end mt-4">
                        <button type="button" @click="verifyCode" class=" text-white bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Verify</button>
                        </div>
                        
                        <div class="text-sm mt-4">
                            <span>Have an account?</span> &nbsp;
                            <router-link to="/login/" class="link">Login Now</router-link>
                            </div>
                    </form>
                    <form v-if="resetData.isReset && !confirm.isSet" @submit.prevent="resetPassword(this)" class="w-full px-4 py-4" id="formData">
                       
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" placeholder="Password" @keyup="$res.password.$touch" v-model="resetData.password" name="password" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $res.password.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                      <input type="password" placeholder="Confirm Password" @keyup="$res.conf.$touch" v-model="resetData.conf" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $res.conf.$errors" :key="error.$uid" class="text-red-500 text-sm">Both passwords should match</span>
                  </div>
                        <div class="flex items-center justify-end mt-4">
                        <button type="button" @click="resetFunc" class=" text-white bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset</button>
                        </div>
                        
                        <div class="text-sm mt-4">
                            <span>Have an account?</span> &nbsp;
                            <router-link to="/login/" class="link">Login Now</router-link>
                            </div>
                    </form>
                    <div v-if="confirm.isSet">
                        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <div class="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg aria-hidden="true" class="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Success</span>
            </div>
            <p class="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Password Successfully changed.</p>
            <a href="/login" type="button" class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900">
                Login
            </a>
        </div>
    </div>
                    </div>
                </div>
            </div>
            </div>
            
        <pageFooterVue></pageFooterVue>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import pageFooterVue from './utils/pageFooter.vue'
// let nextCallOptions = {}
    export default {
        data(){
            return{
                username: 'cyemezo',
                datas:[],
                images: [],
                activeCat:'',
            }
        },
        components:{
            headerNavVue,
            pageFooterVue,
        },
        methods:{
           
        },
        mounted(){
            document.title='Reset Password'
            
    },
    }
</script>

<style scoped>
.homeImg{
    height: 400px;
}
</style>