<template>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
        <headerNavVue></headerNavVue>
        <div class="p-4 justify-center">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                       
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">Curriculum Details</h1>
            </div>
            <div class="flex gap-2">
                <router-link :to="`/institution/curriculum/courses/${curId}/add/`" class="btn-primary" type="button">
                    Add Course
                    </router-link>
                    <router-link :to="`/institution/curriculum/review/${curId}`" class="btn-secondary" type="button">
                    Call For Review
                    </router-link>
            </div>
        </div>
        </div>
        
        <div class="grid mx-auto">

            <div class="container mx-auto px-4 sm:px-8">
                
            <section class="bg-white dark:bg-gray-900">
    <div class="p-4 border rounded-lg">
        <div class="max-w-screen-md">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{{details.curriculumName}}</h2>
            <p class="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="details.description"></p>
            <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <router-link :to="`/institution/curriculum/add/${details._id}`" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    Update
                </router-link>  
            </div>
        </div>
    </div>
</section>
<h2 class="mb-4 text-1xl tracking-tight font-extrabold text-gray-900 dark:text-white">Courses</h2>
        <div class="py-2">
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table class="min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Name
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(research,index) in datas" :key="index">
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div class="flex items-center">
                                        <!-- <div class="flex-shrink-0 w-10 h-10">
                                            <img class="w-full h-full rounded-full"
                                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                                                alt="" />
                                        </div> -->
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                               {{research.title}}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm text-end">
                                    
                                    <router-link :to="`/institution/curriculum/courses/${curId}/add/${research._id}`"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden
                                            class="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Details</span>
                                    </router-link>
                                    <router-link :to="`/institution/curriculum/courses/${curId}/add/${research._id}`"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden
                                            class="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Update</span>
                                    </router-link>
                                    <span v-if="sendDelete" @click="deleteNow(index,research._id)"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden 
                                            class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Yes Delete</span>
                                    </span>
                                    <span @click="sendDelete?sendDelete=false:sendDelete=true"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden
                                            class="absolute inset-0 bg-black-200 opacity-50 rounded-full"></span>
                                        <span class="relative">{{sendDelete?'No':'Delete'}}</span>
                                    </span>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    </div>

        </div>
        <pageFooterVue></pageFooterVue>
    </div>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import apiService from '../../assets/api/apiService.js'

import AWN from "awesome-notifications"

let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
}

let signupOption =  {
  success: "Profile",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

    export default {
        name: 'dashboardPage',
        data(){
            return{
                datas:[],
                details:[],
                isLoaded:false,
                sendDelete:null,
                userId:'',
                curId:null
            }
        },
        components:{
            headerNavVue,
            menuNav,
            pageFooterVue,
        },
        mounted(){
            this.curId =  this.$route.params.id;
            this.userId = JSON.parse(localStorage.getItem('currentUser'));
            apiService.getData('courses/by-curriculum-id/'+this.curId).then(res => {
                    this.datas = res.courses,
                    this.isLoaded = true
            }).catch(error=>{
                console.log(error.response)

            });
            apiService.getData('fetch-a-curricula-by-id/'+this.curId).then(res => {
                    this.details = res.curriculum,
                    this.isLoaded = true
            document.title="Curriculum Courses"
            }).catch(error=>{
                console.log(error.response)

            });
        },
        methods: {
            deleteNow(index,id){
                apiService.deleteData('delete-the-course/'+id).then(res => {
                    console.log(res.response)
                    this.datas.splice(index, 1);
                    notifier.success("Curriculum course deleted", signupOption)
                    this.sendDelete = false
                });
            }
        }
    }
</script>

<style scoped>
</style>