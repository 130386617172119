<template>
  <div>
    <router-view class="font-Poppins"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components:{

  }
}
</script>

<style>
*{
  font-family: 'Noto Sans', sans-serif;
}
</style>
