<template>
    
    <div>
        <div class="border-b border-gray-200 p-2 w-full flex flex-col  hover:bg-slate-200 transition ease-in-out duration-500">
            <div class="p pt-0">
                <h2 class="bg-gray-300 h-3 w-20 rounded-lg"></h2>
                <h1 class="bg-gray-300 h-3 w-40 rounded-lg mt-2"></h1>
                <div class="grid grid-cols-2 text-sm mt-3">
                    <div class="flex items-center sm:justify-center md:justify-start gap-2 mt-3">
                                <svg class="w-3 h-3 bg-gray-300 rounded-sm"></svg>
                                <span class="h-3 w-24 bg-gray-300 rounded-lg"></span>
                            </div>
                    <div class="flex items-center sm:justify-center md:justify-start gap-2 mt-3">
                                <svg class="w-3 h-3 bg-gray-300 rounded-sm"></svg>
                                <span class="h-3 w-24 bg-gray-300 rounded-lg"></span>
                            </div>
                    <div class="flex items-center sm:justify-center md:justify-start gap-2 mt-3">
                                <svg class="w-3 h-3 bg-gray-300 rounded-sm"></svg>
                                <span class="h-3 w-24 bg-gray-300 rounded-lg"></span>
                            </div>
                    <div class="flex items-center sm:justify-center md:justify-start gap-2 mt-3">
                                <svg class="w-3 h-3 bg-gray-300 rounded-sm"></svg>
                                <span class="h-3 w-24 bg-gray-300 rounded-lg"></span>
                            </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'JobCard',
    }
</script>

<style scoped>

</style>