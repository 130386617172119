<template>

    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <menuNav></menuNav>
        <headerNavVue @userData="getUser"></headerNavVue>
        <main class="p-4 md:ml-64 h-auto pt-20">
            <section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
      <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">My Networks</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Explore profile of different applicants that share same interests with you</p>
      </div> 
      <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <div class="text-center text-gray-500 dark:text-gray-400 flex flex-col justify-center items-center gap-2 p-3 border rounded-lg" v-for="net in datas" :key="net" >
              <div class="w-32 h-32 overflow-hidden flex rounded-full border"><img class="w-full h-full object-cover" :src="net.picture?baseUrl+net.picture:'https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo='" :alt="net.fname"></div>
              <h3 class="mb-1 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  {{net.fname}} {{net.lname}}
              </h3>
              <p>{{ net.academicProfile?net.academicProfile[net.academicProfile.length - 1].specialization:'' }}</p>
              <ul class="flex justify-center mt-4 space-x-4">
                 <a :href="`/seeker/applicant/resume/${net._id}`" class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Profile</a>
              </ul>
          </div>
      </div>  
  </div>
</section>
        </main>
        </div>
    </template>
    
    <script>
    import headerNavVue from './utils/headerNav.vue'
    import menuNav from './utils/menuNav.vue'
    import apiService from '../../assets/api/apiService.js'
        export default {
            name: 'dashboardPage',
            data(){
                return{
                    username: 'cyemezo',
                    datas:[],
                    user:[],
                    baseUrl: "https://backend.innodip.rw/",
                    activeCat:'',
                    isLoaded:false
                }
            },
            components:{
                headerNavVue,
                menuNav,
            },
            mounted(){
    
            },
            methods:{
                getUser(data){
                this.user = JSON.parse(data);
                this.isLoaded = true;
                document.title="Opportunities";
                    apiService.getData('view/my/networks/'+this.user._id).then(res => {
                        this.datas = res,
                        this.isLoaded = true
                    });
                }
                
            }
        }
    
    
    </script>
    
    <style scoped>
    .homeImg{
        height: 400px;
    }
    </style>