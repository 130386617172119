<template>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
        <headerNavVue @userData="getUser"></headerNavVue>
        <div class="p-4 justify-center">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                     
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">Add Opportunity</h1>
            </div>
            
        </div>
        </div>
        
        <div class="grid mx-auto">
            <div class="container mx-auto px-4 mb-10 sm:px-8">
                <div class="flex gap-4 mb-4">
                            <label for="">Opportunity Type: </label>
                            <div class="flex items-center">
                            <input @change="cat='int'"
                                checked
                                id="default-radio-1"
                                type="radio"
                                value=""
                                name="default-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                for="default-radio-1"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >Internal</label
                            >
                            </div>
                            <div class="flex items-center">
                            <input  @change="cat='ext'"
                                id="default-radio-2"
                                type="radio"
                                value=""
                                name="default-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                for="default-radio-2"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >External</label
                            >
                            </div>
                        </div>
                <form id="formData" @submit.prevent="sendData">
                    <input type="hidden" v-model="datas._id" name="provider">
                    
                <div class="flex flex-col md:flex-row gap-4 w-full">
                    <div class="w-full md:w-3/4">
                        <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full"  v-if="cat=='int'">
                            
                            <div class="col-span-2">
                                <FormInput placeholder="Opportunity Title" label="Opportunity Title" inputType="text"  required=true small=false name="name"></FormInput>
                            </div>
                            
                                                    <FormSelect @setCitizen="settingCitizen" placeholder="Select Category" label="Category" inputType="text" value="" name="category" required=true small=false >
                            <option v-for="cat in categories" :key="cat" :value="cat._id">{{cat.name}}</option>
                                                    </FormSelect>
                                                    <FormSelect @setCitizen="settingCitizen" placeholder="Select Facility" label="Facility" inputType="text" value="" name="facility" required=true small=false >
                            <option v-for="cat in facilities" :key="cat" :value="cat._id">{{cat.name}}</option>
                                                    </FormSelect>
                                                    <FormInput placeholder="Publication Date" label="Publication Date" inputType="date"  required=true small=false name="publicationDate"></FormInput>
                                                    <FormInput placeholder="Deadline" label="Deadline" inputType="date"  required=true small=false name="deadline"></FormInput>
                                                    <FormInput placeholder="Number of Positions" label="Number of Positions" inputType="number"  required=true small=false name="noOfApplicants"></FormInput>
                                                    <FormInput placeholder="Location" label="Location" inputType="text"  required=true small=false name="location"></FormInput>
                            
                                                    <div class="w-full flex flex-col col-span-2">
                            <label class="text-sm mb-2">Description<strong class="text-red-400">*</strong></label>
                            <textarea rows="5" v-model="descCont" class="w-full appearance-none hidden bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary" placeholder="Description" name="description" required></textarea>
                            <ckeditor :editor="editor" v-model="descCont" @ready="onReady" @input="onChange"></ckeditor>
                                                    </div>
                            <div class="flex items-center col-span-2">
                                <input id="default-checkbox" type="checkbox" @click="isSalary=!isSalary" value="1" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Salary Offered?</label>
                            </div>
                            
                                                    <FormSelect v-if="isSalary" placeholder="Select Currency" label="Currency" inputType="text" value="" name="salaryCurrency" required=true small=false >
                            <option v-for="cur in currencies" :key="cur" :value="cur.name">{{cur.name}}</option>
                                                    </FormSelect>
                            <FormInput v-if="isSalary" placeholder="Salary Range" label="Salary Range" inputType="text"  small=false name="salaryRange"></FormInput>
                            <div class="flex items-center col-span-2">
                                <input id="default-checkbox2" @click="isPayable=!isPayable" type="checkbox" value="0" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="default-checkbox2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Payment before Apply?</label>
                            </div>
                             
                                                    <FormSelect v-if="isPayable" placeholder="Select Carrency" label="Carrency" inputType="text" value="" name="payCurrency" required=true small=false >
                            <option v-for="cur in currencies" :key="cur" :value="cur.name">{{cur.name}}</option>
                                                    </FormSelect>
                            <FormInput v-if="isPayable" placeholder="Payment Amount" label="Payment Amount" inputType="text"  required=true small=false name="payAmount"></FormInput>
                            
                            <div class="w-full flex flex-col col-span-2">
                            <label class="text-sm mb-2">Requirements(Education, Experience, Skills)<strong class="text-red-400">*</strong></label>
                            <textarea rows="5" v-model="reqCont" class="w-full appearance-none hidden bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary" placeholder="Requirements" name="requirement" required></textarea>
                            <ckeditor :editor="editor1" v-model="reqCont" @ready="onReady" @input="onChange"></ckeditor>
                                                    </div>
                            <div class="w-full flex flex-col col-span-2">
                            <label class="text-sm mb-2">Qualification<strong class="text-red-400">*</strong></label>
                            <textarea rows="5" v-model="quaCont" class="w-full appearance-none hidden bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary" placeholder="Qualification" name="qualification" required></textarea>
                            <ckeditor :editor="editor2" v-model="quaCont" @ready="onReady" @input="onChange"></ckeditor>
                                                    </div>
                                                    
                                                    <div class="w-full flex flex-col col-span-2">
                            <FormInput placeholder="Keywords (Use semicolon (,) to separate keywords)" label="Keywords (Use semicolon (,) to separate keywords)" inputType="text"  small=false name="keyword"></FormInput>
                                                    </div>
                                                    <input type="hidden" name="isSalary" :value="isSalary?1:0">
                                                    <input type="hidden" name="isPayable" :value="isPayable?1:0">
                            <div class="flex gap-4 col-span-2">
                                <button
                                                    class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    Save 
                                                    <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9" />
                                                    </svg>
                                                </button>
                            <!-- <FormButton type="submit" label="Add Opportunity" bstyle="primary"></FormButton> -->
                        </div>
                    </div>
                    <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full"  v-if="cat=='ext'">
                            
                            <div class="col-span-2">
                                <FormInput placeholder="Opportunity Title" label="Opportunity Title" inputType="text"  required=true small=false name="name"></FormInput>
                            </div>
                            
                                                    <FormSelect @setCitizen="settingCitizen" placeholder="Select Category" label="Category" inputType="text" value="" name="category" required=true small=false >
                            <option v-for="cat in categories" :key="cat" :value="cat._id">{{cat.name}}</option>
                                                    </FormSelect><FormInput placeholder="Opportunity Address (Link)" label="Opportunity Address (Link)" inputType="text"  required=true small=false name="link"></FormInput>
                            
                            <div class="flex gap-4 col-span-2">
                                <button
                                                    class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    Save 
                                                    <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9" />
                                                    </svg>
                                                </button>
                            <!-- <FormButton type="submit" label="Add Opportunity" bstyle="primary"></FormButton> -->
                        </div>
                    </div>
                    </div>
                    </div>
                    </form>
            </div>
        </div>
        <pageFooterVue></pageFooterVue>
    </div>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import FormInput from '../utils/FormInput.vue';
import FormSelect from '../utils/FormSelect.vue';
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications"
import $ from 'jquery'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue"
let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
}

let signupOption =  {
  success: "Profile",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

    export default {
        name: 'dashboardPage',
        data(){
            return{
                datas:[],
                cat:'int',
                isLoaded:false,
                showModal: false,
                categories:[],
                facilities:[],
                isSalary:false,
                isPayable:false,
                currencies:[{name:'RWF'},{name:'USD'}],
                editor: ClassicEditor,
                editor1: ClassicEditor,
                editor2: ClassicEditor,
                reqCont: "",
                quaCont: "",
                descCont: "",
                maxDate: null
            }
        },
        components:{
            headerNavVue,
            menuNav,
            pageFooterVue,
            FormInput,
            FormSelect,
            ckeditor: CKEditor.component

        },
        mounted(){
            apiService.getData('all_opportunity_categories').then((response) => {
            this.categories = response;
            
            });
            apiService.getData('opportunity/facilities').then((response) => {
            this.facilities = response;
            });
            this.setMaxDate();
            document.title="Add Opportunity"
        },
        methods: {
            toggleModal: function(){
            this.showModal = !this.showModal;
            },
            sendData() {
                if(this.cat == 'int'){
            const form = document.getElementById("formData");
            const serializedData = apiService.serializeFormData(form);
            console.log(serializedData);
            apiService.handleForm('industry/add/opportunity',serializedData).then(res=>{
                console.log(res)
                notifier.success('Opportunity added successful', signupOption),
                $('form').trigger('reset')
            },
                ).catch(error=>{
                    console.log(error.response)
                    notifier.alert(error.response.data.error, signupOption)

                });
            }else{
                
            const form = document.getElementById("formData");
            const serializedData = apiService.serializeFormData(form);
            console.log(serializedData);
            apiService.handleForm('industry/add/opportunity/extenal',serializedData).then(res=>{
                console.log(res)
                notifier.success('Opportunity added successful', signupOption),
                $('form').trigger('reset')
            },
                ).catch(error=>{
                    console.log(error.response)
                    notifier.alert(error.response.data.error, signupOption)

                });
            }
            },
            getUser(data){
            this.datas = JSON.parse(data);
            this.selectedFilePreview = this.baseUrl+this.datas.picture
            this.isLoaded = true;
            },
            setMaxDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const day = String(today.getDate()).padStart(2, '0');
                this.maxDate = `${year}-${month}-${day}`;
            }
        }
    }
</script>

<style scoped>
</style>