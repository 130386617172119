<template>
    <modalPane :showModal="showModal" @triggerModal="toggleModal" :endpoint="end"
        alertTitle="Add Taining" class="z-1000">
        <template v-slot:title>
            <h1>Add Training</h1>
        </template>
        <template v-slot:form>
            <div class="flex gap-4 mb-4">
                    <label for="">Training Type: </label>
                    <div class="flex items-center">
                        <input @change="cat = 'int',end='industry/add/training/internal/'" checked id="default-radio-1" type="radio" value="" name="default-radio"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="default-radio-1"
                            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Internal</label>
                    </div>
                    <div class="flex items-center">
                        <input @change="cat = 'ext',end='industry/add/training/external/'" id="default-radio-2" type="radio" value="" name="default-radio"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="default-radio-2"
                            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">External</label>
                    </div>
                </div>
            <div class="flex flex-wrap -mx-3 mb-6 w-[500px]">
                <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Theme
                    </label>
                    <input type="hidden" v-model="user._id" name="provider">
                    <input name="theme" required
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name" type="text" placeholder="Theme">
                </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6 w-[500px]" v-if="cat=='ext'">
                <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Link
                    </label>
                    <input name="link" required
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name" type="url" placeholder="Link">
                </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6 w-[500px]" v-if="cat=='int'">
                <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Date
                    </label>
                    <input name="date" required
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name" type="date" placeholder="Concept">
                </div>
            </div>
            <div class="flex items-start flex-col justify-center w-full" v-if="cat=='int'">

                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                    Concept
                </label>
                <textarea name="concept" rows="4" required
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."></textarea>
            </div>


        </template>
    </modalPane>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
            <headerNavVue @userData="getUser"></headerNavVue>
            <div class="p-4 justify-center">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full md:w-auto px-2 md:px-0">

                        <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                            stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <h1 class="text-4xl text-gray-500">Trainings</h1>
                    </div>

                    <button class="btn-primary" type="button" v-on:click="toggleModal()">
                        Add Training
                    </button>
                </div>
            </div>

            <div class="grid mx-auto">
                <div class="container mx-auto px-4 sm:px-8">
                    <div class="py-8">
                        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table class="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <th
                                                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                Theme
                                            </th>
                                            <th
                                                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                Date
                                            </th>
                                            <th
                                                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="banner in datas" :key="banner">
                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div class="flex items-center">
                                                    <div class="ml-3">
                                                        <p class="text-gray-900 whitespace-no-wrap">
                                                            {{ banner.trainingTheme }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                <div class="flex items-center">
                                                    <div class="ml-3">
                                                        <p class="text-gray-900 whitespace-no-wrap">
                                                            {{ banner.trainingDate }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm text-end">

                                                <span
                                                    class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                    <span aria-hidden
                                                        class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                                    <span class="relative">Delete</span>
                                                </span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <div
                                    class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                                    <span class="text-xs xs:text-sm text-gray-900">
                                        Showing 1 to 4 of 50 Entries
                                    </span>
                                    <div class="inline-flex mt-2 xs:mt-0">
                                        <button
                                            class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                                            Prev
                                        </button>
                                        <button
                                            class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <pageFooterVue></pageFooterVue>
        </div>
    </div>
</template>

<script>
import headerNavVue from '../industry/utils/headerNav.vue'
import menuNav from '../industry/utils/menuNav.vue'
import pageFooterVue from '../industry/utils/pageFooter.vue'
import apiService from '../../assets/api/apiService.js'
import modalPane from '../admin/utils/modalPane.vue'
export default {
    name: 'dashboardPage',
    data() {
        return {
            datas: [],
            isLoaded: false,
            user: [],
            cat: 'int',
            end:'industry/add/training/internal/',
            showModal: false,
            baseUrl: 'https://innodip.rw/'
        }
    },
    components: {
        headerNavVue,
        menuNav,
        pageFooterVue,
        modalPane
    },
    mounted() {

    },
    methods: {
        toggleModal: function () {
            this.showModal = !this.showModal;
        },
        getUser(data) {
            this.user = JSON.parse(data);
            this.isLoaded = true;
            document.title = " Trainings";
            apiService.getData("get/training/by/industries/" + this.user._id).then((res) => {
                (this.datas = res.opportunities), (this.isLoaded = true);
            });
        },
    },
}
</script>

<style scoped></style>