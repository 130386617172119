<template>
  <div class="block md:flex relative">
    <div
      class="hidden md:block w-full md:w-[20%] sticky top-0 h-[100vh] mobile-menu"
    >
      <menuNav></menuNav>
    </div>
    <div class="w-[100%] md-[80%]">
      <headerNavVue @userData="getUser"></headerNavVue>
      <div class="p-4 justify-center content">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center justify-between w-full px-2 md:px-0">
            <div class="flex items-center gap-4">
              <svg
                class="w-16 h-16 text-secondary"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 class="text-4xl text-gray-500">Applicant Shortlist</h1>
            </div>

            <div>
              <router-link
                :to="`/industry/applications/${this.$route.params.job}`"
                class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                All Applications
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4">
        <div class="flex flex-col p-4 no-wrap md:-mx-2">
          <div class="flex gap-4 items-center mb-4">
            <label for="">Decision: </label>
            <div class="flex items-center">
              <input @change="cat='select'"
                
                id="default-radio-1"
                type="radio"
                value=""
                name="default-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-radio-1"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Approved</label
              >
            </div>
            <div class="flex items-center">
              <input  @change="cat='reject'"
                id="default-radio-2"
                type="radio"
                value=""
                name="default-radio"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-radio-2"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Rejected</label
              >
            </div>
          </div>

          <label
            for="message"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Your message</label
          >
          <textarea v-model="data.commentForRejection"
            id="message"
            rows="4"
            class="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Write your thoughts here..."
          ></textarea>

          <div>
              <button @click="sendShort()"
                class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
          </div>
        </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </div>
</template>

<script>
import headerNavVue from "../industry/utils/headerNav.vue";
import menuNav from "../industry/utils/menuNav.vue";
import pageFooterVue from "../industry/utils/pageFooter.vue";
import apiService from "../../assets/api/apiService.js";
import AWN from "awesome-notifications"
import axios from 'axios';

let globalOptions = {
    alert: "Oops! Something got wrong",
    position: 'top-right',

}
globalOptions.labels = {
    alert: "Shortlist",
    position: 'top-right',
}

let signupOption = {
    success: "Shortlist",
    position: 'top-right',

}
signupOption.labels = {
    alert: "Shortlist",
    position: 'top-right',
}

let notifier = new AWN(globalOptions)

export default {
  name: "profilePage",
  data() {
    return {
      user: [],
      cat:null,
      isLoaded: false,
      baseUrl: "https://backend.innodip.rw/",
      data:{
            "applicant": "",
            "opportunity": "",
            "commentForRejection": ""     // reason for the rejection
        }
    };
  },
  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,
  },
  mounted() {
    this.data.applicant = this.$route.params.id
    this.data.opportunity = this.$route.params.job
    const btn = document.querySelector(".toggleMobile");
    const menu = document.querySelector(".mobile-menu");
    const content = document.querySelector(".content");

    btn.addEventListener("click", () => {
      menu.classList.toggle("hidden");
      content.classList.toggle("content");
    });
    apiService
      .getData(`applicant/display/details/${this.$route.params.id}`)
      .then((res) => {
        (this.user = res.data),
          (this.isLoaded = true,
           document.title = this.user.fname + " Application");
      });
  },
  methods: {
    sendShort(){
        if(this.cat == null){
            notifier.alert('Please select Decision', signupOption)
        }else{
        axios.put(this.baseUrl+'api/opportunity-applications/'+this.cat, this.data)
        .then(response => {
            console.log("Upload successful:", response.data);
            notifier.success('Response sent', signupOption)
        })
        .catch(error => {
            console.error("Error uploading:", error);
        });
    }
    },
    getUser(data) {
      this.datas = JSON.parse(data);
      this.selectedFilePreview = this.baseUrl + this.datas.picture;
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped>
</style>