<template>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
            <headerNavVue @userData="getUser"></headerNavVue>
            <div class="p-4 justify-center">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full md:w-auto px-2 md:px-0">

                        <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                            stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <h1 class="text-4xl text-gray-500">{{ isEdit == true ? 'Edit' : 'Add' }} Publication</h1>
                    </div>

                    <button class="btn-primary" type="button" v-on:click="toggleModal()">
                        Add Publication
                    </button>
                </div>
            </div>

            <div class="grid mx-auto">
                <div class="container mx-auto px-4 mb-10 sm:px-8">
                    <form id="formData" @submit.prevent="sendData">
                        <input type="hidden" v-model="uploader" name="uploader_id">
                        <div class="flex flex-col md:flex-row gap-4 w-full">
                            <div class="w-full md:w-1/2">
                                <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full">
                                    <div class="col-span-2">
                                        <FormInput placeholder="Publication Title" label="Publication Title"
                                            inputType="text" :value="datas.title" required=true small=false name="title">
                                        </FormInput>
                                    </div>
                                    <div class="col-span-2">
                                        <FormInput placeholder="Author" label="Author" inputType="text" required=true
                                            small=false name="authors[]"></FormInput>
                                    </div>
                                    <div class="col-span-2">
                                        <FormInput placeholder="Publication Year" label="Publication Year" inputType="text"
                                            required=true small=false name="published_year" :value="datas.published_year">
                                        </FormInput>
                                    </div>
                                    <div class="col-span-2">
                                        <FormInput placeholder="Publication Link" label="Publication Link" inputType="text"
                                            required=true small=false name="url" :value="datas.url"></FormInput>
                                    </div>
                                    <div class="col-span-2">
                                        <FormInput placeholder="Publication Cover Image" label="Publication Cover Image"
                                            inputType="file" required=true small=false name="cover_image"></FormInput>
                                    </div>
                                    <div class="col-span-2">
                                        <label for="" class="mb-3">Descriptions</label>
                                        <textarea name="descriptions" v-model="datas.descriptions" placeholder="Abstract"
                                            class="w-full h-40 px-3 py-2 border rounded-md shadow-sm focus:ring focus:ring-indigo-400 focus:ring-opacity-50"></textarea>

                                    </div>
                                    <div class="flex gap-4 col-span-2">
                                        <button  :disabled="canSend" type="submit" class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <pageFooterVue></pageFooterVue>
        </div>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import FormInput from '../utils/FormInput.vue';
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications"
// import $ from 'jquery'
import axios from 'axios';

let globalOptions = {
    alert: "Oops! Something got wrong",
    position: 'top-right',

}
globalOptions.labels = {
    alert: "Profile",
    position: 'top-right',
}

let signupOption = {
    success: "Profile",
    position: 'top-right',

}
signupOption.labels = {
    alert: "Profile",
    position: 'top-right',
}

let notifier = new AWN(globalOptions)

export default {
    name: 'dashboardPage',
    data() {
        return {
            datas: [],
            user:[],
            isLoaded: false,
            showModal: false,
            categories: [],
            catHolder: [],
            subCategories: [],
            isEdit: false,
            uploader:null,
            canSend:false,

        }
    },
    components: {
        headerNavVue,
        menuNav,
        pageFooterVue,
        FormInput,
    },
    mounted() {
        let id = this.$route.params.id;
        if (id !== 'add') {
            apiService.getData('fetch-book-by-id/' + id).then((response) => {
                this.datas = response.publications;
                this.isEdit = true
                this.uploader = this.datas.uploader_id
            });
        }
    },
    methods: {
        toggleModal: function () {
            this.showModal = !this.showModal;
        },
        handleFormSubmission(form,id) {
            form.addEventListener("submit", function (e) {
                e.preventDefault();

                const formData = new FormData(form); 
                axios
                    .request({
                        method: 'POST', // Use PUT if id is not empty, otherwise use POST
                        url: `https://backend.innodip.rw/api/${id == 'add' ? `add-book-publications` : 'update-book-publication'}/${id != 'add' ? `${id}` : ''}`,
                        data: formData
                    })
                    .then((response) => {
                        console.log("Upload successful:", response.message);
                        notifier.success(response.data.message, signupOption)
                        window.location.reload()
                    })
                    .catch((error) => {
                        console.error("Error uploading:", error);
                        // notifier.alert(error.response.data.errors.attachment[0], signupOption)
                    });
            });
        },
        sendData() {

            this.canSend=true
            let id = this.$route.params.id;
            const uploadForm = document.getElementById("formData");
            this.handleFormSubmission(uploadForm, id);

        },
        getUser(data) {
            this.user = JSON.parse(data);
            this.isEdit?this.datas.uploader_id:this.user._id
        },
    }
}
</script>

<style scoped></style>