import { createRouter,createWebHistory } from "vue-router";
import homePage from '../pages/homePage';
import searchPage from '../pages/seeker/searchPage';
import handleForm from '../pages/handleForm';
import comingSoon from '../pages/comingSoon';
import loginPage from '../pages/loginPage';
import resetPage from '../pages/resetPage';
import aboutPage from '../pages/aboutPage';
import contactPage from '../pages/contactPage';
import whatwedoPage from '../pages/whatwedoPage';
import privacyPage from '../pages/privacyPage';
import confirmAccount from '../pages/confirmAccount';
import signupPage from '../pages/signupPage';
import jobsPage from '../pages/jobsPage';
import universitiesPage from '../pages/universitiesPage';
import universityPage from '../pages/universityPage';
import articlePage from '../pages/articlePage';
import industriesPage from '../pages/industriesPage';
import industryPage from '../pages/industryPage';
import researchPage from '../pages/researchPage';
import curriculumPage from '../pages/curriculumPage';
import publicationPage from '../pages/publicationPage';
import curriculumQuestionaire from '../pages/curriculumQuestionaire';
import consultancyPage from '../pages/consultancyPage';
import jobFiltered from '../pages/JobFiltered';
import trainingPage from '../pages/trainingPage';
import jobPage from '../pages/jobPage';
import signupSeeker from '../pages/seeker/signupSeeker';
import signupIndustry from '../pages/industry/signupIndustry';
import signupInstitution from '../pages/institution/signupInstitution';
import dashboardPageSeeker from '../pages/seeker/dashboardPage';
import profilePageSeeker from '../pages/seeker/profilePage';
import settingsPageSeeker from '../pages/seeker/settingPage';
import educationPageSeeker from '../pages/seeker/educationPage';
import expiriencePageSeeker from '../pages/seeker/expiriencePage';
import referencePageSeeker from '../pages/seeker/referencePage';
import resumePageSeeker from '../pages/seeker/resumePage';
import jobPageSeeker from '../pages/seeker/jobPage';
import opportunitiesPageSeeker from '../pages/seeker/opportunitiesPage';
import applicationsPageSeeker from '../pages/seeker/applicationsPage';
import applicationResearchPageSeeker from '../pages/seeker/applicationResearchPage';
import networksPageSeeker from '../pages/seeker/networksPage';
import applicationPageSeeker from '../pages/seeker/applicationPage';
import opportunityPageApplySeeker from '../pages/seeker/opportunityApplyPage';
import interestPageSeeker from '../pages/seeker/interestPage';
import interestAddPageSeeker from '../pages/seeker/interestAdd';
import universitiesPageSeeker from '../pages/seeker/universitiesPage';
import industriesPageSeeker from '../pages/seeker/industriesPage';
import universityPageSeeker from '../pages/seeker/universityPage';
import industryPageSeeker from '../pages/seeker/industryPage';
import publicationsPage from '../pages/seeker/publicationsPage';
import publicationReseachPage from '../pages/seeker/publicationsResearch';
import publicationReseachInd from '../pages/seeker/applicationResearchInd';
import researchesPage from '../pages/seeker/researchesPage';
import researchSeekerPage from '../pages/seeker/researchPage';
import researchQuestion from '../pages/seeker/researchApply';
import curriculumsPageSeeker from '../pages/seeker/curriculumsPage';
import curriculumPageSeeker from '../pages/seeker/curriculumPage';

import dashboardPageInstitution from '../pages/institution/dashboardPage';
import employeesPageInstitution from '../pages/institution/settingsPage';
import opportunitiesPageInstitution from '../pages/institution/opportunitiesPage';
import opportunityAddInstitution from '../pages/institution/addOpportunity';
import profilePageInstitution from '../pages/institution/profilePage';
import publicationPageInstitution from '../pages/institution/publicationsPage';
import researchesPageInstitution from '../pages/institution/researchesPage';
import researchAddPageInstitution from '../pages/institution/addResearch';
import researchAppsPageInstitution from '../pages/institution/applicantsResearch';
import addpublicationPageInstitution from '../pages/institution/addPublication';
import addArticlePageInstitution from '../pages/institution/AddResearchArticle';
import curriculumsPage from '../pages/institution/curriculumsPage';
import addcurriculumPageInstitution from '../pages/institution/addCurriculum';
import addcurriculumCourseInstitution from '../pages/institution/curriculumCourse';
import addcurriculumCourseAddInstitution from '../pages/institution/curriculumCourseAdd';
import addcurriculumReviewInstitution from '../pages/institution/curriculumReviewAdd';
import articles from '../pages/institution/articlesPage';

import dashboardPageIndustry from '../pages/industry/dashboardPage';
import opportunitiesIndustry from '../pages/industry/opportunitiesPage';
import opportunityAddIndustry from '../pages/industry/addOpportunity';
import profilePageIndustry from '../pages/industry/profilePage';
import setPage from '../pages/industry/settingsPage';
import applicationsInd from '../pages/industry/applicationsPage';
import applicationsIndResume from '../pages/industry/resumePage';
import applicationsShortlist from '../pages/industry/shortlistPage';
import applicationsUserResume from '../pages/industry/userResume';
import trainings from '../pages/industry/trainingsPage';
import confrence from '../pages/industry/confrencePage';
import applicationsUserResume2 from '../pages/institution/userResume';
import applicationsSeekResume from '../pages/seeker/userResume';
import applicationsMyResume from '../pages/seeker/myResume';


// admin
import homePageAdmin from '../pages/admin/homePage';
import categoriesPage from '../pages/admin/categoriesPage';
import userResumePage from '../pages/admin/userResume';
import subcategoryPage from '../pages/admin/subCategoryPage';
import applicantsPage from '../pages/admin/applicantsPage';
import academiaPage from '../pages/admin/academiaPage';
import industriesAdminPage from '../pages/admin/industriesPage';
import resetAdmin from '../pages/admin/resetPage';
import bannersAdmin from '../pages/admin/bannersPage';

import NotFound from '../pages/NotFound';

const routes = [
    {
        name: 'Home',
        path:'/home',
        component: comingSoon
    },
    {
        name: 'coming',
        path:'/',
        component: homePage
    },
    {
        name: 'Search',
        path:'/seeker/search/',
        component: searchPage
    },
    {
        name: 'LoginPage',
        path:'/login',
        component: loginPage
    },
    {
        name: 'ResetPage',
        path:'/reset',
        component: resetPage
    },
    {
        name: 'aboutPage',
        path:'/about',
        component: aboutPage
    },
    {
        name: 'ContactPage',
        path:'/contact',
        component: contactPage
    },
    {
        name: 'whatPage',
        path:'/whatwedo',
        component: whatwedoPage
    },
    {
        name: 'PrivacyPage',
        path:'/privacy',
        component: privacyPage
    },
    {
        name: 'ConfirmAccount',
        path:'/confirm',
        component: confirmAccount
    },
    {
        name: 'JobsPage',
        path:'/opportunities',
        component: jobsPage
    },
    {
        name: 'UniversitiesPage',
        path:'/academia',
        component: universitiesPage
    },
    {
        name: 'UniversityPage',
        path:'/university/:id',
        component: universityPage,
        props:true
    },
    {
        name: 'ArticlePage',
        path:'/research/article/:id',
        component: articlePage,
        props:true
    },
    {
        name: 'industryPage',
        path:'/industry/:id',
        component: industryPage,
        props:true
    },
    {
        name: 'ResearchPage',
        path:'/research',
        component: researchPage
    },
    {
        name: 'CurriculumPage',
        path:'/curriculum',
        component: curriculumPage
    },
    {
        name: 'PublicationPages',
        path:'/publication',
        component: publicationPage
    },
    {
        name: 'IndustriesPage',
        path:'/industries',
        component: industriesPage
    },
    {
        name: 'HandleForm',
        path:'/handelform',
        component: handleForm
    },
    {
        name: 'CurriculumQuestionaire',
        path:'/curriculumQuestionaire',
        component: curriculumQuestionaire
    },
    {
        name: 'ConsultancyPage',
        path:'/consultancy',
        component: consultancyPage
    },
    {
        name: 'jobFilterd',
        path:'/jobs',
        component: jobFiltered
    },
    {
        name: 'TrainingPage',
        path:'/internship',
        component: trainingPage
    },
    {
        name: 'JobPage',
        path:'/opportunity/:id',
        component: jobPage,
        props:true
    },
    {
        name: 'SignUp',
        path:'/signup',
        component: signupPage
    },
    {
        name: 'SignupSeeker',
        path:'/signup/seeker',
        component: signupSeeker
    },
    {
        name: 'SignupIndustry',
        path:'/signup/industry',
        component: signupIndustry
    },
    {
        name: 'SignupInstitution',
        path:'/signup/institution',
        component: signupInstitution
    },
    {
        name: 'trainings',
        path:'/industry/trainings',
        component: trainings
    },
    {
        name: 'confrence',
        path:'/industry/confrence',
        component: confrence
    },
    {
        name: 'HSeekerDashboardPage',
        path:'/seeker/',
        component: dashboardPageSeeker
    },
    {
        name: 'SeekerDashboardPage',
        path:'/seeker/dashboard',
        component: dashboardPageSeeker
    },
    {
        name: 'ProfilePage',
        path:'/seeker/profile',
        component: profilePageSeeker
    },
    {
        name: 'SettingPage',
        path:'/seeker/settings',
        component: settingsPageSeeker
    },
    {
        name: 'ProfilePageIndus',
        path:'/industry/profile',
        component: profilePageIndustry
    },
    {
        name: 'EducationPage',
        path:'/seeker/profile/education',
        component: educationPageSeeker
    },
    {
        name: 'ExpiriencePage',
        path:'/seeker/profile/experience',
        component: expiriencePageSeeker
    },
    {
        name: 'ReferencePage',
        path:'/seeker/profile/certificate',
        component: referencePageSeeker
    },
    {
        name: 'ResumePage',
        path:'/seeker/resume',
        component: resumePageSeeker
    },
    {
        name: 'pubPage',
        path:'/seeker/publications',
        component: publicationsPage
    },
    {
        name: 'pubResPage',
        path:'/seeker/publications/research',
        component: publicationReseachPage
    },
    {
        name: 'pubResIndPage',
        path:'/seeker/publications/research/:id',
        component: publicationReseachInd,
        props:true
    },
    {
        name: 'resPage',
        path:'/seeker/researches',
        component: researchesPage
    },
    {
        name: 'resOnePage',
        path:'/seeker/research/:id',
        component: researchSeekerPage,
        props:true
    },
    {
        name: 'researchQuestion',
        path:'/seeker/research/apply/:id',
        component: researchQuestion,
        props:true
    },
    {
        name: 'opportunitiesPageSeeker',
        path:'/seeker/opportunities/:category',
        component: opportunitiesPageSeeker,
        props: true
    },
    {
        name: 'JobPageSeeker',
        path:'/seeker/opportunity/:id',
        component: jobPageSeeker,
        props: true
    },
    {
        name: 'JobPageApplySeeker',
        path:'/seeker/opportunity/apply/:id',
        component: opportunityPageApplySeeker,
        props: true
    },
    {
        name: 'ApplicationResearchSeeker',
        path:'/seeker/applications/research',
        component: applicationResearchPageSeeker,
    },
    {
        name: 'ApplicationsSeeker',
        path:'/seeker/applications',
        component: applicationsPageSeeker,
    },
    {
        name: 'NetworksSeeker',
        path:'/seeker/networks',
        component: networksPageSeeker,
    },
    {
        name: 'ApplicationSeeker',
        path:'/seeker/application/:id',
        component: applicationPageSeeker,
        props:true
    },
    {
        name: 'InterestSeeker',
        path:'/seeker/interests',
        component: interestPageSeeker
    },
    {
        name: 'InterestAddSeeker',
        path:'/seeker/interests/add',
        component: interestAddPageSeeker
    },
    {
        name: 'UniversitiesSeeker',
        path:'/seeker/universities',
        component: universitiesPageSeeker
    },
    {
        name: 'UniversitySeeker',
        path:'/seeker/university/:id',
        component: universityPageSeeker,
        props:true
    },
    {
        name: 'curriSeeker',
        path:'/seeker/curriculum/:id',
        component: curriculumPageSeeker,
        props:true
    },
    {
        name: 'IndustriesSeeker',
        path:'/seeker/industries',
        component: industriesPageSeeker
    },
    {
        name: 'curriculumsPage',
        path:'/seeker/curriculums',
        component: curriculumsPageSeeker
    },
    {
        name: 'industrySeeker',
        path:'/seeker/industry/:id',
        component: industryPageSeeker,
        props:true
    },

    // admin

    
    {
        name: 'AdminHomePage',
        path:'/admin/',
        component: homePageAdmin
    },
    {
        name: 'CategoriesPage',
        path:'/admin/categories',
        component: categoriesPage
    },
    {
        name: 'subbCategoryPage',
        path:'/admin/category/:id',
        component: subcategoryPage,
        props:true
    },
    {
        name: 'userResumePage',
        path:'/admin/resume/:id',
        component: userResumePage,
        props:true
    },
    
    {
        name: 'sapplicantsPage',
        path:'/admin/applicants',
        component: applicantsPage
    },
    {
        name: 'academiaPage',
        path:'/admin/academia/:type',
        component: academiaPage,
        props:true
    },
    {
        name: 'industriesAdminPage',
        path:'/admin/industries/:type',
        component: industriesAdminPage,
        props:true
    },
    {
        name: 'ResetAdmin',
        path:'/admin/reset/:type/:id',
        component: resetAdmin,
        props:true
    },
    {
        name: 'Banners',
        path:'/admin/banners/',
        component: bannersAdmin
    },
    
    {
        name: 'EmployeesPage',
        path:'/institution/settings/',
        component: employeesPageInstitution
    },
    {
        name: 'OpportunitiesPage',
        path:'/institution/opportunities',
        component: opportunitiesPageInstitution
    },
    {
        name: 'OpportunityAdd',
        path:'/institution/opportunity/add',
        component: opportunityAddInstitution
    },
    {
        name: 'publicationAdd',
        path:'/institution/publication/add',
        component: addpublicationPageInstitution
    },
    {
        name: 'articleAdd',
        path:'/institution/article/add/',
        component: addArticlePageInstitution,
        props:true
    },
    {
        name: 'publicationEdit',
        path:'/institution/publication/:id',
        component: addpublicationPageInstitution,
        props:true
    },
    {
        name: 'reseaches',
        path:'/institution/research',
        component: researchesPageInstitution
    },
    {
        name: 'curriculums',
        path:'/institution/curriculums',
        component: curriculumsPage
    },
    {
        name: 'articles',
        path:'/institution/articles',
        component: articles
    },
    {
        name: 'curriculumEdit',
        path:'/institution/curriculum/add/:id',
        component: addcurriculumPageInstitution,
        props:true
    },
    {
        name: 'curriculumAdd',
        path:'/institution/curriculum/add',
        component: addcurriculumPageInstitution
    },
    {
        name: 'curriculumCourse',
        path:'/institution/curriculum/courses/:id',
        component: addcurriculumCourseInstitution,
        props:true
    },
    {
        name: 'curriculumCourseAdd',
        path:'/institution/curriculum/courses/:id/add',
        component: addcurriculumCourseAddInstitution,
        props:true
    },
    {
        name: 'curriculumReviewAdd',
        path:'/institution/curriculum/review/:id',
        component: addcurriculumReviewInstitution,
        props:true
    },
    {
        name: 'curriculumCourseEdit',
        path:'/institution/curriculum/courses/:id/add/:course',
        component: addcurriculumCourseAddInstitution,
        props:true
    },
    {
        name: 'reseachesAdd',
        path:'/institution/research/add',
        component: researchAddPageInstitution
    },
    {
        name: 'reseachesEdit',
        path:'/institution/research/add/:id',
        component: researchAddPageInstitution,
        props:true
    },
    {
        name: 'reseachesApps',
        path:'/institution/research/applicants/:id',
        component: researchAppsPageInstitution,
        props:true
    },
    
    // institution

    
    {
        name: 'HomeDashboardPage',
        path:'/institution/',
        component: dashboardPageInstitution
    },
    {
        name: 'DashboardPage',
        path:'/institution/dashboard',
        component: dashboardPageInstitution
    },
    // {
    //     name: 'EmployeesPage',
    //     path:'/institution/employees',
    //     component: employeesPageInstitution
    // },
    {
        name: 'PublicationPage',
        path:'/institution/publications',
        component:publicationPageInstitution
    },

    
    {
        name: 'InduDashboardPage',
        path:'/industry/',
        component: dashboardPageIndustry
    },
    {
        name: 'setPage',
        path:'/industry/settings/',
        component: setPage
    },
    {
        name: 'OpportunitiesPageInd',
        path:'/industry/opportunities',
        component: opportunitiesIndustry
    },
    {
        name: 'ApplicationsInd',
        path:'/industry/applications/:id',
        component: applicationsInd,
        props:true
    },
    {
        name: 'ApplicationsIndResume',
        path:'/industry/applicant/resume/:id/:job',
        component: applicationsIndResume,
        props:true
    },
    {
        name: 'ApplicationsShortlist',
        path:'/industry/applicant/shortlist/:id/:job',
        component: applicationsShortlist,
        props:true
    },
    {
        name: 'ApplicationsUserResume',
        path:'/industry/applicant/profile/:id',
        component: applicationsUserResume,
        props:true
    },
    {
        name: 'ApplicationsUserResume2',
        path:'/institution/applicant/profile/:id',
        component: applicationsUserResume2,
        props:true
    },
    {
        name: 'ApplicationsISeekResume',
        path:'/seeker/applicant/resume/:id',
        component: applicationsSeekResume,
        props:true
    },
    {
        name: 'ApplicationsMyResume',
        path:'/seeker/my/resume/',
        component: applicationsMyResume
    },
    
    {
        name: 'OpportunityAddInd',
        path:'/industry/opportunity/add',
        component: opportunityAddIndustry
    },
    {
        name: 'ProfilePageInst',
        path:'/institution/profile',
        component: profilePageInstitution
    }, 
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
];

const router = Router();
export default router;
function Router(){
    const router = new createRouter({
        history: createWebHistory(),
        routes,
    });
    return router;
}