<template>
  <div class="block md:flex relative">
    <div
      class="hidden md:block w-full md:w-[20%] sticky top-0 h-[100vh] mobile-menu"
    >
      <menuNav></menuNav>
    </div>
    <div class="w-[100%] md-[80%]">
      <headerNavVue @userData="getUser"></headerNavVue>
      <div class="p-4 justify-center content">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center w-full md:w-auto px-2 md:px-0">
            <svg
              class="w-14 h-14 text-secondary"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <h1 class="text-4xl text-gray-500">{{profile.fname}} Profile</h1>
          </div>
          <div class="w-full md:w-auto px-2 md:px-0"></div>
        </div>
      </div>
      
      <div class="p-4">
        <div class="md:flex no-wrap md:-mx-2">
          <!-- Left Side -->
          <div class="w-full md:w-3/12 md:mx-2">
            <!-- Profile Card -->
            <div class="bg-white p-3 border-t-4 border-green-400">
              <div class="image overflow-hidden">
                <img
                  class="h-24 w-24 rounded-full mx-auto"
                  :src="
                    profile.picture
                      ? baseUrl+profile.picture
                      : 'https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo='
                  "
                  alt=""
                />
              </div>

              <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">
                {{ profile.fname }} {{ profile.lname }}
              </h1>
              <p
                class="text-sm text-gray-500 hover:text-gray-600 leading-6"
                v-html="profile.headline"
              ></p>
            </div>
            <!-- End of profile card -->
          </div>
          <!-- Right Side -->
          <div class="w-full md:w-9/12 mx-2 h-64">
            <!-- Profile tab -->
            <!-- About Section -->
            <div class="bg-white p-3 shadow-sm rounded-sm">
              <div
                class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 relative"
              >
                <span clas="text-green-500">
                  <svg
                    class="h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
                <span class="tracking-wide">About</span>
              </div>
              <div class="text-gray-700">
                <div class="grid md:grid-cols-2 text-sm">
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">First Name</div>
                    <div class="px-4 py-2">{{ profile.fname }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Last Name</div>
                    <div class="px-4 py-2">{{ profile.lname }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Gender</div>
                    <div class="px-4 py-2">Female</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Contact No.</div>
                    <div class="px-4 py-2">{{ profile.phone }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Email.</div>
                    <div class="px-4 py-2">
                      <a
                        class="text-blue-800"
                        :href="`mailto:${profile.email}`"
                        >{{ profile.email }}</a
                      >
                    </div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">National ID</div>
                    <div class="px-4 py-2">{{ profile.nid }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Address</div>
                    <div class="px-4 py-2">
                      <span v-for="add in profile.address" :key="add"
                        >{{ add }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of about section -->

            <div class="my-4"></div>

            <!-- Experience and education -->
            <div class="bg-white p-3 shadow-sm rounded-sm">
              <div class="grid grid-cols-2">
                <div>
                  <div
                    class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3- relative"
                  >
                    <span clas="text-green-500">
                      <svg
                        class="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </span>
                    <span class="tracking-wide">Experience</span>
                  </div>
                  <ul class="list-inside space-y-2">
                    <li
                      v-for="(acad, key) in profile.workExperience"
                      :key="key"
                    >
                      <div class="text-teal-600">
                        {{ acad.position }} at {{ acad.institution }}
                      </div>
                      <div class="text-gray-500 text-xs">
                        {{ acad.from }} -
                        {{ acad.to != null ? acad.to : "Present" }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3 relative"
                  >
                    <span clas="text-green-500">
                      <svg
                        class="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path
                          fill="#fff"
                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </svg>
                    </span>
                    <span class="tracking-wide">Education</span>
                  </div>
                  <ul class="list-inside space-y-2">
                    <li
                      v-for="(acad, key) in profile.academicProfile"
                      :key="key"
                    >
                      <div class="text-teal-600">
                        {{ acad.degree }} in {{ acad.specialization }}
                      </div>
                      <div class="text-gray-500 text-xs">{{ acad.skul }}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div>
                  <div
                    class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3 relative"
                  >
                    <span clas="text-green-500">
                      <svg
                        class="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path
                          fill="#fff"
                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </svg>
                    </span>
                    <span class="tracking-wide">Reference</span>
                  </div>
                  <ul class="list-inside space-y-2">
                    <li
                      v-for="(acad, key) in profile.workExperience"
                      :key="key"
                    >
                      <div class="text-teal-600">{{ acad.NameOfReferee }}</div>
                      <div class="text-gray-500 text-xs">
                        {{ acad.PositionOfReferee }} at {{ acad.institution }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3 relative"
                  >
                    <span clas="text-green-500">
                      <svg
                        class="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path
                          fill="#fff"
                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </svg>
                    </span>
                    <span class="tracking-wide">Certificates</span>
                  </div>
                  <ul class="list-inside space-y-2">
                    <li
                      v-for="(acad, key) in profile.awardedCertificates"
                      :key="key"
                    >
                      <div class="text-teal-600">
                        {{ acad.certificateTitle }}
                      </div>
                      <div class="text-gray-500 text-xs">
                        in {{ acad.dateOfAward }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- End of profile tab -->
          </div>
        </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </div>
</template>

<script>
import headerNavVue from "../industry/utils/headerNav.vue";
import menuNav from "../industry/utils/menuNav.vue";
import pageFooterVue from "../industry/utils/pageFooter.vue";
// import FormButton from '../utils/FormButton.vue';
import apiService from "../../assets/api/apiService.js";
export default {
  name: "profilePage",
  data() {
    return {
      profilename: "cyemezo",
      profile: [],
      datas:[],
      activeCat: "",
      isLoaded: false,
      baseUrl: "https://backend.innodip.rw/",
      selectedFilePreview: "",
    };
  },
  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,
    // FormInput,
    // FormButton,
  },
  mounted() {
    const btn = document.querySelector(".toggleMobile");
    const menu = document.querySelector(".mobile-menu");
    const content = document.querySelector(".content");

    btn.addEventListener("click", () => {
      menu.classList.toggle("hidden");
      content.classList.toggle("content");
    });
    
    apiService.getData(`applicant/display/details/${this.$route.params.id}`).then(res => {
        this.profile = res.data,
        this.isLoaded = 
         document.title = this.profile.fname + " Resume";
        
    });
  },
  methods: {
    getUser(data) {
      this.datas = JSON.parse(data);
      this.selectedFilePreview = this.baseUrl + this.datas.picture;
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped>
:root {
  --main-color: #4a76a8;
}

.bg-main-color {
  background-color: var(--main-color);
}

.text-main-color {
  color: var(--main-color);
}

.border-main-color {
  border-color: var(--main-color);
}
</style>