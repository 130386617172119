<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="p-4 md:ml-64 h-auto pt-20">
      <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">
        Research <span class="text-blue-600 dark:text-blue-500">details</span>.</h1>
      <div class="flex flex-col gap-2 bg-white border rounded-lg p-4">
        <div class="flex flex-col gap-1"><b>Title</b><span>{{ datas.title }}</span></div>
        <!-- <div class="flex flex-col gap-1"><b>Category</b><p>{{ datas.category.name }}</p></div> -->
        <div class="flex flex-col gap-1"><b>Language</b>
          <p>{{ datas.language }}</p>
        </div>
        <div class="flex flex-col gap-1"><b>Application Deadline</b>
          <p>{{ datas.applicationDeadline }}</p>
        </div>
        <div class="flex flex-col gap-1"><b>Abstract</b>
          <p v-html="datas.abstract"></p>
        </div>
        <div class="flex flex-col gap-1"><b>Objective</b>
          <p v-html="datas.objective"></p>
        </div>
        <div class="flex flex-col gap-1"><b>Resources</b>
          <p v-html="datas.resources"></p>
        </div>
        <div class="flex flex-col gap-1"><b>Submission Instructions</b>
          <p v-html="datas.submissionInstructions"></p>
        </div>
        <div class="flex flex-col gap-1"><b>Applicants Requirements</b><span
            v-for="(team, index) in datas.applicantsRequirements" :key="index">{{ index+=1 }}. {{ team }},
          </span>
        </div>
        <div class="flex flex-col gap-1"><b>Required Skills</b><span v-for="(team, index) in datas.requiredSkills"
            :key="index">{{ index+=1 }}. {{ team }},
          </span>
        </div>
        <div class="flex flex-col gap-1"><b>References</b><span v-for="(team, index) in datas.references" :key="index">{{
          index+=1 }}. {{ team }},
          </span>
        </div>

        <div class="flex flex-col gap-1"><b>Notification Preferences</b>
          <p v-html="datas.notificationPreferences"></p>
        </div>
        <div class="flex flex-col gap-1"><b>Budget</b><span v-for="(team, index) in datas.budget" :key="index">{{ index+=1
        }}. {{ team }},
          </span>
        </div>

  <div class="my-4"><a :href="`/seeker/research/apply/${this.$route.params.id}`"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Apply Here</a></div>
  </div>

  </main>
</div></template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import apiService from "../../assets/api/apiService.js";
export default {
  name: "dashboardPage",
  data() {
    return {
      username: "cyemezo",
      datas: [],
      activeCat: "",
      isLoaded: false,
    };
  },
  components: {
    headerNavVue,
    menuNav,
  },
  mounted() {
    apiService.getData("research_call/" + this.$route.params.id).then((res) => {
      (this.datas = res), (this.isLoaded = true);
    });
  },
};
</script>

<style scoped>.homeImg {
  height: 400px;
}</style>