<template>
    
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
        <headerNavVue></headerNavVue>
        <div class="p-4 justify-center">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                     
                <svg aria-hidden="true" class="w-12 h-12 text-secondary" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
                <h1 class="text-4xl text-gray-500">Reset Password</h1>
            </div>
        </div>
        </div>
        
        <div class="grid mx-auto">
            <div class="container mx-auto px-4 sm:px-8">
        <div class="py-8">
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <h1 class="mb-4 text-lg">Reset password for <b>{{ datas.name }}</b></h1>
<label for="helper-text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telephone Number</label>
<input type="text" v-model="data.new_phone" id="helper-text" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Telephone Number">
<p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 dark:text-gray-400">New Password will be sent to this number.</p>

<button @click="sendReset()"
                class="inline-flex mt-4 items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Reset Password
              </button>
            </div>
        </div>
    </div>

        </div>
        <pageFooterVue></pageFooterVue>
    </div>
    </div>
</template>

<script>
import headerNavVue from '../admin/utils/headerNav.vue'
import menuNav from '../admin/utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications";
import axios from 'axios';

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: "top-right",
};
globalOptions.labels = {
  alert: "Academia",
  position: "top-right",
};

let signupOption = {
  success: "Academia",
  position: "top-right",
};
signupOption.labels = {
  alert: "Academia",
  position: "top-right",
};

let notifier = new AWN(globalOptions);
    export default {
        name: 'dashboardPage',
        data(){
            return{
                datas:[],
                isLoaded:false,
                baseUrl:'https://backend.innodip.rw/',
                data:{
                    "new_phone": ''
                }
            }
        },
        components:{
            headerNavVue,
            menuNav,
            pageFooterVue,
        },
        mounted(){
            if(this.$route.params.type=='intitution'){
                apiService
        .getData("institution/display/details/" + this.$route.params.id)
        .then((res) => {
          this.datas = res.data;
          this.data.new_phone = this.datas.phone
        });
            }if(this.$route.params.type=='industry'){
                apiService
        .getData("industry/display/details/" + this.$route.params.id)
        .then((res) => {
          this.datas = res.data;
          this.data.new_phone = this.datas.phone
        });
            }

        },
        methods: {
            sendReset(){
        if(this.data.new_phone == ''){
            notifier.alert('Please Provide phone number', signupOption)
        }else{
        axios.post(this.baseUrl+'api/reset-password/'+this.$route.params.type+'/'+this.$route.params.id, this.data)
        .then(response => {
            console.log("Upload successful:", response.data);
            notifier.success('New Password Sent', signupOption)
        })
        .catch(error => {
            console.error("Error uploading:", error);
        });
    }
    },
        }
    }
</script>

<style scoped>
</style>resetPa