<template>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
            <headerNavVue @userData="getUser"></headerNavVue>
            <div class="p-4 justify-center">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full md:w-auto px-2 md:px-0">

                        <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                            stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <h1 class="text-4xl text-gray-500">{{isEdit==true?'Edit':'Add'}} Research</h1>
                    </div>

                </div>
            </div>

            <div class="grid mx-auto">
                <div class="container mx-auto px-4 mb-10 sm:px-8">

                    <ol
                        class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 mb-4">
                        <li class="flex items-center" v-for="(tab, index) in tabs" :key="index"
                            @click="index < curTab ? activateTab(index) : ''" :class="{ 'activea': tab.active }">
                            <span class="flex items-center justify-center w-5 h-5 mr-2 text-xs border"
                                :class="{ 'activeb': tab.active }">
                                {{ tab.number }}
                            </span>
                            {{ tab.text }}
                            <svg v-if="!isLatestStep(index)" class="w-3 h-3 ml-2 sm:ml-4" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                            </svg>
                        </li>
                    </ol>
                    <form id="formData" @submit.prevent="sendData">
                        <input type="hidden" v-model="datas._id" name="provider_id">
                        <div class="flex flex-col md:flex-row gap-4 w-full">
                            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 0">
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Title
                                        <strong class="text-red-400">*</strong></label>
                                    <input
                                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                        required="required" placeholder="Title" type="text" v-model="researchData.title" />
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="text-sm mb-2">Language <strong class="text-red-400">*</strong></label>
                                    <div class="w-full flex gap-5">
                                        <input
                                            class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                            required="required" placeholder="Language" type="text"
                                            v-model="researchData.language" />
                                    </div>
                                </div>
                                <div class="w-full flex flex-col">
                                    <label class="text-sm mb-2">Application Deadline <strong class="text-red-400">*</strong></label>
                                    <div class="w-full flex gap-5">
                                        <input
                                            class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                            required="required" placeholder="Application Deadline" type="date"
                                            v-model="researchData.applicationDeadline" />
                                    </div>
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Abstract
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor" v-model="researchData.abstract" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Objective
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor1" v-model="researchData.objective" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Resources
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor2" v-model="researchData.resources" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Submission Instructions
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor3" v-model="researchData.submissionInstructions" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Review Process
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor4" v-model="researchData.reviewProcess" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Notification Preferences
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor5" v-model="researchData.notificationPreferences" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="flex gap-5">
                                    <button type="button" @click="activateTab(1)"
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Next
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>

                            </div>
                            
                            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 1">
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Applicants Requirements
                                        <strong class="text-red-400">*</strong></label>
                                    <div class="grid gap-4 grid-cols-4">
                                        <div class="flex justify-center gap-2" v-for="(fac, index) in researchData.applicantsRequirements"
                                            :key="index">
                                            <input
                                                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                required="required" placeholder="Applicant Requirement" type="text"
                                                v-model="researchData.applicantsRequirements[index]" />
                                            <svg @click="removeFacility(index)" class="text-red-600 h-12 w-12"
                                                aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <input
                                            class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                            required="required" placeholder="Applicant Requirement" type="text"
                                            v-model="newFacility" />


                                    </div>
                                    <div class="col-span-2 mt-4">
                                        <button type="button" @click="addFacility"
                                            class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Add
                                        </button>
                                    </div>
                                </div>


                                <div class="flex gap-5 col-span-2">
                                    <button type="button" @click="activateTab(0)"
                                        class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Back
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                    <button type="button" @click="activateTab(2)"
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Next
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 2">
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Required Skills
                                        <strong class="text-red-400">*</strong></label>
                                        <div class="grid gap-4 grid-cols-4">
                                        <div class="flex justify-center gap-2" v-for="(fac, index) in researchData.requiredSkills"
                                            :key="index">
                                            <input
                                                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                required="required" placeholder="Required Skill" type="text"
                                                v-model="researchData.requiredSkills[index]" />
                                            <svg @click="removeTimeline(index)" class="text-red-600 h-12 w-12"
                                                aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <input
                                            class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                            required="required" placeholder="Required Skill" type="text"
                                            v-model="newTimeline" />


                                    </div>
                                    <div class="col-span-2 mt-4">
                                        <button type="button" @click="addTimeline"
                                            class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Add
                                        </button>
                                    </div>
                                </div>


                                <div class="flex gap-5 col-span-2">
                                    <button type="button" @click="activateTab(1)"
                                        class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Back
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                    <button type="button" @click="activateTab(3)"
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Next
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 3">
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">References
                                        <strong class="text-red-400">*</strong></label>
                                        <div class="grid gap-4 grid-cols-4">
                                        <div class="flex justify-center gap-2" v-for="(fac, index) in researchData.references"
                                            :key="index">
                                            <input
                                                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                required="required" placeholder="Reference" type="text"
                                                v-model="researchData.references[index]" />
                                            <svg @click="removeTeam(index)" class="text-red-600 h-12 w-12"
                                                aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <input
                                            class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                            required="required" placeholder="Reference" type="text"
                                            v-model="newTeam" />


                                    </div>
                                    <div class="col-span-2 mt-4">
                                        <button type="button" @click="addTeam"
                                            class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            Add
                                        </button>
                                    </div>
                                </div>


                                <div class="flex gap-5 col-span-2">
                                    <button type="button" @click="activateTab(2)"
                                        class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Back
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                    <button type="button" @click="activateTab(4)"
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Next
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 4">
                                <div class="w-full flex flex-col">
                                    <label class="text-sm mb-2">Funding Secured
                                        <strong class="text-red-400">*</strong></label>
                                    <input
                                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                        required="required" placeholder="Funding Secured" type="text"
                                        v-model="researchData.budget.fundingSecured" />
                                </div>


                                <div class="w-full flex flex-col">
                                    <label class="text-sm mb-2">Funding Required
                                        <strong class="text-red-400">*</strong></label>
                                    <input
                                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                        required="required" placeholder="Funding Required" type="text"
                                        v-model="researchData.budget.fundingRequired" />
                                </div>

                                <div class="flex gap-5 col-span-2">
                                    <button type="button" @click="activateTab(3)"
                                        class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Back
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                    
                                    <button type="button" @click="sendData()" :disabled="canSend"
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Submit
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
            <pageFooterVue></pageFooterVue>
        </div>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications"
import $ from 'jquery'
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue"

let globalOptions = {
    alert: "Oops! Something got wrong",
    position: 'top-right',

}
globalOptions.labels = {
    alert: "Profile",
    position: 'top-right',
}

let signupOption = {
    success: "Profile",
    position: 'top-right',

}
signupOption.labels = {
    alert: "Profile",
    position: 'top-right',
}

let notifier = new AWN(globalOptions)

export default {
    name: 'dashboardPage',
    data() {
        return {
            datas: [],
            isLoaded: false,
            showModal: false,
            categories: [],
            curTab: 0,
            isEdit:false,
            canSend:false,
            editor: ClassicEditor,
            editor1: ClassicEditor,
            editor2: ClassicEditor,
            editor3: ClassicEditor,
            editor4: ClassicEditor,
            editor5: ClassicEditor,
            editor6: ClassicEditor,
            editor7: ClassicEditor,
            tabs: [
                {
                    "text": "Details",
                    "number": "1",
                    "active": true
                },
                {
                    "text": "Applicants Requirements",
                    "number": "2",
                    "active": false
                },
                {
                    "text": "Required Skills",
                    "number": "3",
                    "active": false
                },
                {
                    "text": "References",
                    "number": "4",
                    "active": false
                },
                {
                    "text": "Budget",
                    "number": "5",
                    "active": false
                }
            ],

            newFacility: '',
            newTimeline: '',
            newTeam: '',
            newForm: '',
            
            researchData:{
                "objective": "",
                "abstract": "",
                "applicationDeadline": "",
                "title": "",
                "description": "",
                "resources": "",
                "applicantsRequirements": [],
                "submissionInstructions": "",
                "language": "",
                "requiredSkills": [],
                "references": [],
                "institution": null,
                "reviewProcess": "",
                "notificationPreferences": "",
                "budget": {
                }
            },
        }
    },

    components: {
        headerNavVue,
        menuNav,
        pageFooterVue,
        ckeditor: CKEditor.component

    },
    mounted() {
        // apiService.getData('allResearchCategories').then((response) => {
        //     this.categories = response;
        // });
        const id = this.$route.params.id;
        if(id){
        apiService.getData('research_call/'+id).then((response) => {
            this.researchData = response;
            this.isEdit = true
        });
    }
    },
    methods: {
        toggleModal: function () {
            this.showModal = !this.showModal;
        },
            changeCategory(event){
                if(event.target.value!=''){
                this.researchData.category=event.target.value
                }
            },
        isLatestStep(index) {
            return index === this.tabs.length - 1;
        },
        activateTab(index) {
            // Deactivate all tabs
            this.curTab = index;
            this.tabs.forEach((tab) => {
                tab.active = false;
            });

            // Activate the clicked tab
            this.tabs[index].active = true;
        },
        addFacility() {
            if (this.newFacility) {
                this.researchData.applicantsRequirements.push(this.newFacility);
                this.newFacility = '';
            }
        },
        addForm() {
            if (this.newForm) {
                this.researchData.form.push(this.newForm);
                this.newForm = '';
            }
        },
        removeFacility(index) {
            this.researchData.applicantsRequirements.splice(index, 1);
        },
        removeForm(index) {
            this.researchData.form.splice(index, 1);
        },
        addTimeline() {
            if (this.newTimeline) {
                this.researchData.requiredSkills.push(this.newTimeline);
                this.newTimeline = '';
            }
        },
        removeTimeline(index) {
            this.researchData.requiredSkills.splice(index, 1);
        },
        addTeam() {
            if (this.newTeam) {
                this.researchData.references.push(this.newTeam);
                this.newTeam = '';
            }
        },
        removeTeam(index) {
            this.researchData.references.splice(index, 1);
        },
        handleFormSubmission(form) {
            form.addEventListener("submit", function (e) {
                e.preventDefault(); // Prevent the default form submission

                const formData = new FormData(form); // Use the submitted form

                let path = 'https://backend.innodip.rw/api/publication';
                // const endpoint = base+'api/applicants/'+id+'/insert/academicProfile';
                axios.post(path, formData)
                    .then(response => {
                        $('form').trigger('reset')
                        console.log("Upload successful:", response.data);
                        notifier.success(response.data.message, signupOption)
                    })
                    .catch(error => {
                        console.error("Error uploading:", error);
                        notifier.alert(error.response.data.errors.attachment[0], signupOption)
                    });
            });
        },
        sendData() {
        this.canSend=true
        let id = this.$route.params.id;
        axios
  .request({
    method: typeof id !== 'undefined' ? 'PUT' : 'POST', // Use PUT if id is not empty, otherwise use POST
    url: `https://backend.innodip.rw/api/research-call/${typeof id !== 'undefined' ? `update/${id}` : 'insert'}`,
    data: this.researchData
  })
  .then((response) => {
    // Handle the response from the server
    this.canSend=false
    console.log("Response:", response.data);
    notifier.success("Research Added.", signupOption);
    this.$router.go(0);
  })
  .catch((error) => {
    // Handle any errors that occur during the request
    console.error("Error:", error);
    notifier.alert("Research not added, some fields are not filled.", signupOption);
  });


    },
        getUser(data) {
            this.datas = JSON.parse(data);
            this.researchData.institution=this.datas._id
        },
    }
}
</script>

<style scoped></style>