<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="p-4 md:ml-64 h-auto pt-20">
      <h1 v-if="isLoaded"
        class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">
        {{ datas.title }} <span class="text-blue-600 dark:text-blue-500"> Application</span>.</h1>
      <form id="formData" @submit.prevent="sendData">
        <input type="hidden" v-model="datas._id" name="provider_id">
        <div class="flex flex-col md:flex-row gap-4 w-full">
          <div class="w-full grid grid-cols-2 gap-4">
            <div class="w-full flex flex-col col-span-2">
              <label class="text-sm mb-2">Applicant Name
                <strong class="text-red-400">*</strong></label>
              <input
                class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                required="required" placeholder="Applicant Name" type="text" v-model="answers.applicantName" />
            </div>
            <div class="w-full flex flex-col">
              <label class="text-sm mb-2">Applicant Email <strong class="text-red-400">*</strong></label>
              <div class="w-full flex gap-5">
                <input
                  class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                  required="required" placeholder="Applicant Email" type="email" v-model="answers.applicantEmail" />
              </div>
            </div>
            <div class="w-full flex flex-col">
              <label class="text-sm mb-2">Applicant Contact Number <strong class="text-red-400">*</strong></label>
              <div class="w-full flex gap-5">
                <input
                  class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                  required="required" placeholder="Applicant Contact Number" type="text"
                  v-model="answers.applicantContactNumber" />
              </div>
            </div>
            
            <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Cover Letter
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor :editor="editor" v-model="answers.coverLetter" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
          </div>
        </div>
        <div class="my-4"><button @click="sendData" type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit
            Application</button></div>
            </form>
    </main>
  </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import apiService from '../../assets/api/apiService.js'
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue"

import AWN from "awesome-notifications"

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Research Call",
  position: 'top-right',
}

let signupOption = {
  success: "Research Call",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Research Call",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)
export default {
  name: 'dashboardPage',
  data() {
    return {
      username: 'cyemezo',
      datas: [],
      isLoaded: false,
      editor: ClassicEditor,

      answers: {
        "researchCallId": "",
        "applicantId": "",
        "applicantName": "",
        "applicantEmail": "",
        "applicantContactNumber": "",
        "coverLetter": "",
        "participants": [             // Different from the applicant  (if not group, leave it empty)
          {
            // "name": "Jane Smith",
            // "email": "janesmith@example.com",
            // "contactNumber": "+9876543210",
            // "role": "Co-Investigator"
          }
        ]
      }
    }
  },
  components: {
    headerNavVue,
    menuNav,
    ckeditor: CKEditor.component

  },
  created() {
    // this.constructResponses();
  },
  async mounted() {
    const userId = JSON.parse(localStorage.getItem('currentUser'));

    try {
      const res = await apiService.getData('research_call/' + this.$route.params.id);
      this.datas = res;
      
      document.title = this.datas.tilte + " Application";
      this.answers.applicantId = userId;
      this.answers.researchCallId = this.$route.params.id;
      this.isLoaded = true;
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error as needed
    }
  },
  methods: {
    sendData() {
      axios
        .post("https://backend.innodip.rw/api/research-call/apply", this.answers, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // Handle the response from the server
          console.log("Response:", response.data);
          notifier.success(response.data.message, signupOption)
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error:", error);
          notifier.alert("You have applied to this research before..", signupOption);
        });
    },
    getUser(data) {
      let profile = JSON.parse(data);
      this.answers.applicantContactNumber = profile.phone
      this.answers.applicantEmail = profile.email
      this.answers.applicantName= profile.fname+' '+profile.lname
    },
  }
}
</script>

<style scoped></style>