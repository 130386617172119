<template>
<div class="antialiased bg-gray-50 dark:bg-gray-900">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="p-4 md:ml-64 h-auto pt-20">
        <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">Most popular <span class="text-blue-600 dark:text-blue-500">academia</span>.</h1>
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
        <industryCardVue v-for="job in institutions" :key="job" :datas="JSON.stringify(job)" router="/seeker/university" :hasDesc=true></industryCardVue>
      </div>
        <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">Most popular <span class="text-blue-600 dark:text-blue-500">industries</span>.</h1>
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
        <UniversityCardVue v-for="job in companies" :key="job" :datas="JSON.stringify(job)" router="/seeker/industry" :hasDesc=true></UniversityCardVue>
      </div>
        <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">Most recent <span class="text-blue-600 dark:text-blue-500">opportunities</span>.</h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
        <jobCardVue v-for="job in datas" :key="job" :datas="JSON.stringify(job)" router="/seeker/opportunity" :hasDesc=true></jobCardVue>
      </div>

    </main>
    </div>
</template>

<script>
import headerNavVue from '../seeker/utils/headerNav.vue'
import menuNav from '../seeker/utils/menuNav.vue'
import apiService from '../../assets/api/apiService.js'
// import pageFooterVue from '../seeker/utils/pageFooter.vue'
// import FormInput from '../utils/FormInput.vue';
// import FormButton from '../utils/FormButton.vue';
import jobCardVue from '../utils/jobCard.vue';
import UniversityCardVue from '../utils/academiaCard.vue';
import industryCardVue from '../utils/industryCard.vue';

    export default {
        name: 'dashboardPage',
        data(){
            return{
                username: 'cyemezo',
                datas:[],
                companies:[],
                institutions:[],
                activeCat:'',
                isLoaded:false
            }
        },
        components:{
            headerNavVue,
            menuNav,
            jobCardVue,
            // pageFooterVue,
            // FormInput,
            industryCardVue, 
            UniversityCardVue
        },
        mounted(){
            apiService.getOpportunities().then(jobsList => {
                this.datas = jobsList,
                this.isLoaded = true
                apiService.getData('all_opportunity_categories').then(res => {
                    this.categories = res,
                    this.isLoaded = true
                });
            });
            apiService.getIndustries().then(jobsList => {
                this.companies = jobsList;
                // this.activeCat = jobsList.categories[0].name;
                this.isLoaded = true
            });
            apiService.getInstitutions().then(instituteList => {
                this.institutions = instituteList;
            });
            document.title="Dashboard"
        },
    }
</script>

<style scoped>
.homeImg{
    height: 400px;
}
</style>