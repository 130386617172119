<template>
  <div>
    <headerNavVue></headerNavVue>
    <div
      class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center flex flex-col"
    >
      <h1 class="text-2xl md:text-4xl mb-6 text-gray-600">
       Contact US
      </h1>
      <div class="grid grid-cols-2">
        <div class="flex flex-col gap-4">
            <b>iNodip Offices</b>
<span>Inindi House, KN 3 Rd Kigali</span>
<span>Tel.: +250 788320688</span>
<span>Info@innodip.rw</span>
<span>www.innodip.rw</span>
        </div>
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d31900.13147487879!2d30.07563175413989!3d-1.9463630743003775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d-1.9258752!2d30.093907899999998!4m5!1s0x19dca65028a02b8b%3A0xb902353a466fa34c!2sInindi%20House%2C%20KN%203%20Rd%2C%20Kigali!3m2!1d-1.9662842999999999!2d30.1033947!5e0!3m2!1sen!2srw!4v1702643133779!5m2!1sen!2srw" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    <pageFooterVue></pageFooterVue>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import pageFooterVue from "./utils/pageFooter.vue";
export default {
  data() {
    return {};
  },
  components: {
    headerNavVue,
    pageFooterVue,
  },
  mounted() {
    document.title = "About Us";
  },
};
</script>

<style scoped></style>
