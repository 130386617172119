<template>
  <div class="block md:flex relative">
    <div
      class="hidden md:block w-full md:w-[20%] sticky top-0 h-[100vh] mobile-menu"
    >
      <menuNav></menuNav>
    </div>
    <div class="w-[100%] md:w-[80%]">
      <headerNavVue></headerNavVue>
      <div class="p-4 justify-center">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center w-full md:w-auto px-2 md:px-0">
            <svg
              class="w-16 h-16 text-secondary"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <h1 class="text-4xl text-gray-500">Networks</h1>
          </div>
          <div class="w-full md:w-auto px-2 md:px-0">
            <FormInput
              placeholder="Search..."
              label=""
              inputType="text"
              small="true"
            ></FormInput>
          </div>
        </div>
      </div>

      <div
        class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        <div
          class="text-center text-gray-500 dark:text-gray-400 flex flex-col justify-center items-center gap-2 p-3 border rounded-lg"
          v-for="net in datas"
          :key="net"
        >
          <div class="w-32 h-32 overflow-hidden flex rounded-full border">
            <img
              class="w-full h-full object-cover"
              :src="
                net.picture
                  ? baseUrl + net.picture
                  : 'https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo='
              "
              :alt="net.fname"
            />
          </div>
          <h3
            class="mb-1 text-lg font-bold tracking-tight text-gray-900 dark:text-white"
          >
            {{ net.fname }} {{ net.lname }}
          </h3>
          <p>
            {{
              net.academicProfile
                ? net.academicProfile[net.academicProfile.length - 1]
                    .specialization
                : ""
            }}
          </p>
          <ul class="flex justify-center mt-4 space-x-4">
                 <a :href="`/industry/applicant/profile/${net._id}`" class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Profile</a>
              </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerNavVue from "../industry/utils/headerNav.vue";
import menuNav from "../industry/utils/menuNav.vue";
import apiService from "../../assets/api/apiService.js";

export default {
  name: "dashboardPage",
  data() {
    return {
      username: "cyemezo",
      datas: [],
      activeCat: "",
      isLoaded: false,
      baseUrl: "https://backend.innodip.rw/",
    };
  },
  components: {
    headerNavVue,
    menuNav,
  },
  mounted() {
    apiService.getData("display/rand-lmt/applicants/").then((res) => {
      (this.datas = res), (this.isLoaded = true);
    });
  },
};
</script>

<style scoped>
.homeImg {
  height: 400px;
}
</style>
