<template>
<div class="antialiased bg-gray-50 dark:bg-gray-900 relative">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="md:ml-64 h-auto pt-20">
        <div class="p-4 justify-center content">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">My Education</h1>
            </div>
                    <div>
                        <button type="button" @click="canInsert=!canInsert" v-if="datas.academicProfile!='' && !canInsert"
                                class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Add Education
                        </button>
                    </div>
        </div>
        </div>
        <div class="grid p-4">
            <form v-if="isLoaded" id="formData" @submit.prevent="sendData" multipart>
                <div class="flex flex-col md:flex-row gap-4 w-full">
                    <div class="w-full md:w-1/2" v-if="datas.academicProfile=='' || canInsert">
                        <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full">
                            <FormInput placeholder="School" label="School" inputType="text"  required=true small=false name="skul" :value="curSchool.skul"></FormInput>
                            <FormInput placeholder="Specialization" label="Specialization" inputType="text"  required=true small=false name="specialization" :value="curSchool.specialization"></FormInput>
                            <FormInput placeholder="Qualification" label="Qualification" inputType="text"  required=true small=false name="degree" :value="curSchool.degree"></FormInput>
                            <FormInput placeholder="Graduation Year" label="Graduation Year" inputType="number"  required=true small=false name="year" :value="curSchool.year"></FormInput>
                        
                            <div class="flex gap-1 md:gap-4">
                                
                            <button type="submit" :disabled="canSend"
                            class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {{!isEdit?'Save':'Update'}}
                            </button>
                                <!-- <FormButton type="submit" :disabled="canSend" :label="!isEdit?'Save':'Update'" bstyle="primary"></FormButton> -->
                                <!-- <FormButton type="button" label="Reset" bstyle="secondary" @click="curSchool=[]"></FormButton> -->
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full md:w-1/2">
                        <h1 class="text-2xl text-gray-500 mb-4">My Education</h1>
                        <div class="card-hover flex justify-between" v-for="(school,index) in datas.academicProfile" :key="index">
                            <div class="">
                                <h1><b>School</b>: {{ school.skul }}</h1>
                                <h1><b>Specialization</b>: {{ school.specialization }}</h1>
                                <h1><b>Degree</b>: {{ school.degree }}</h1>
                                <h1><b>Graduation Year</b>: {{ school.year }}</h1>
                            </div>
                            <div class="flex flex-col justify-between items-end">
                                <button type="button" @click="setSchool(index)">Edit</button>
                                <button type="button" v-if="sendDelete==null" @click="deleteSchool(index)">Delete</button>
                                <div class="flex gap-2"><button class="text-red-500" v-if="sendDelete==index" @click="deleteNow(index)">Yes Delete</button> <button v-if="sendDelete==index" @click="sendDelete=null">No</button></div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            <div v-if="!isLoaded">
            </div>
            <div class="flex items-center justify-end" v-if="isLoaded">
                <div class="flex justify-between md:justify-end gap-1 md:gap-4 w-full md:w-auto">
                    <a href="/seeker/profile/"><FormButton type="button" label="&larr; Profile" bstyle="normal"></FormButton></a>
                    <a href="/seeker/profile/experience"><FormButton type="button" label="Experience &rarr;" bstyle="normal"></FormButton></a>
                </div>
            </div>
        </form>
        </div>
    </main>
    </div>
</template>

<script>
import headerNavVue from '../seeker/utils/headerNav.vue'
import menuNav from '../seeker/utils/menuNav.vue'
import FormInput from '../utils/FormInput.vue'
import FormButton from '../utils/FormButton.vue'
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications"
import axios from 'axios';
import $ from 'jquery'

let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
}

let signupOption =  {
  success: "Profile",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

    export default {
        name: 'educationPage',
        data(){
            return{
                datas:[],
                curSchool:[],
                modalData:[],
                activeCat:'',
                isLoaded:false,
                isModal:false,
                baseUrl : 'https://backend.innodip.rw/',
                userId: null,
                isEdit:false,
                dataId:'',
                canSend:false,
                canInsert:false,
                sendDelete:null,
            }
        },
        components:{
            headerNavVue,
            menuNav,
            FormInput,
            FormButton, 
        },
        mounted(){
        },
        methods:{
            setSchool(id){
                this.dataId=id,
                this.curSchool = this.datas.academicProfile[id],
                this.isEdit = true,
                this.canInsert = true,
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            handleFormSubmission(form,id,base,index) {
                const formData = new FormData(form); // Use the submitted form
                
                    let path = '';
                    if(this.curSchool!=''){
                        path = base+'api/applicants/'+id+'/update/academicProfile/'+index;
                    }else{
                        path = base+'api/applicants/'+id+'/insert/academicProfile';
                    }
                        // const endpoint = base+'api/applicants/'+id+'/insert/academicProfile';
                        this.canSend=true
                        axios.post(path, formData, {
                headers: {
                    "Content-Type": "application/json",
                },
                })
                .then(response => {
                    $('form').trigger('reset')
                    console.log("Upload successful:", response.data);
                    notifier.success(response.data.message, signupOption)
                    this.canSend=false
                    this.$router.go(0);
                })
                .catch(error => {
                    console.error("Error uploading:", error);
                    // notifier.alert(error.response.data.message, signupOption)
                    this.canSend=false
                });
            },
            sendData() {
                
                this.canSend=true
            const uploadForm = document.getElementById("formData");
            this.handleFormSubmission(uploadForm,this.datas._id,this.baseUrl,this.dataId);
            
            },
            getUser(data){
            this.datas = JSON.parse(data);
            this.selectedFilePreview = this.baseUrl+this.datas.picture
            this.isLoaded = true;
            document.title=this.datas.fname+" Education Information";
            this.datas.dob = apiService.calendarDate(this.datas.dob)
            },
            deleteSchool(id){
                // let data = {
                //     "title":'Delete Education',
                //     "message": "Do you realy want to delete <b>"+this.datas.academicProfile[id].school+"</b> education?",
                //     "data":this.datas.academicProfile[id],
                //     "id":id
                // };
                // this.modalData = data
                this.sendDelete=id
            },
            modalDecision(modalAction){
                if(modalAction.status){
                    this.isModal=false
                    const id = this.datas._id;
                    apiService.deleteData('applicants/'+id+'/delete/academicProfile/'+modalAction.id).then(res => {
                        this.datas.academicProfile.splice(modalAction.id, 1);
                        notifier.success(res.message, signupOption)
                    });
                }
                else{
                    this.isModal=false
                }

            },
            deleteNow(id){
                const user = this.datas._id;

                apiService.deleteData('applicants/'+user+'/delete/academicProfile/'+id).then(res => {
                    this.datas.academicProfile.splice(id, 1);
                    notifier.success(res.message, signupOption)
                    this.sendDelete = null
                });
            }
        }
    }
</script>

<style scoped>
.homeImg{
    height: 400px;
}
</style>