<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <menuNav></menuNav>
        <headerNavVue @userData="getUser"></headerNavVue>
        <main class="p-4 md:ml-64 h-auto pt-20">
            <div class="justify-center content">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                        <div class="flex w-full items-center justify-between md:w-auto px-2 md:px-0">
                            <h1 class="text-4xl text-gray-500">Add Interests</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid p-4">
                <div v-if="isLoaded">
                    <div class="w-full" v-for="int in datas" :key="int._id">
                        <h3 class="mb-5 text-lg font-medium text-gray-900 dark:text-white">Choose from {{ int.category_name
                        }}:</h3>
                        <ul class="grid w-full gap-6 md:grid-cols-3">
                            <li v-for="cat in int.subcategories" :key="cat._id" @click="addInterest(cat.sub_category_name)">
                                <input type="checkbox" :id="int._id + cat.sub_category_name" value=""
                                    class="hidden peer inputInterest">
                                <label :for="int._id + cat.sub_category_name"
                                    class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 relative">

                                    <div class="block">
                                        <div class="w-full text-lg font-semibold">{{ cat.sub_category_name }}</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>

                    <hr>

                    <button @click="saveInterest"
                        class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Save Interest
                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                    </button>
                </div>

            </div>
        </main>
    </div>
</template>

<script>
import headerNavVue from '../seeker/utils/headerNav.vue'
import menuNav from '../seeker/utils/menuNav.vue'
import apiService from '../../assets/api/apiService.js'
import axios from 'axios';
import $ from 'jquery'
export default {
    name: 'profilePage',
    data() {
        return {
            datas: [],
            userId: '',
            activeCat: '',
            isLoaded: false,
            isActive: false,
            curValue: '',
            interestData: {
                interests:
                    [
                    ]
            }
        }
    },
    components: {
        headerNavVue,
        menuNav,
    },
    mounted() {
        apiService.getData('allInterests').then(res => {
            this.datas = res.interests,
                this.isLoaded = true
        });
        this.userId = JSON.parse(localStorage.getItem('currentUser'));
    },
    methods: {
        toggleClass() {
            this.isActive = !this.isActive;
        },
        getUser(data) {
            this.user = JSON.parse(data);
            this.isLoaded = true;
            document.title = "Opportunities";
            apiService.getData('applicant/my/applications/' + this.user._id).then(res => {
                this.user = res.opportunity,
                    this.isLoaded = true
            });
        },
        addInterest(sub) {
            this.curValue = sub

            if (!this.interestData.interests.includes(sub)) {
                this.interestData.interests.push(sub);

            }
            $('.inputInterest').each(() => {
                if (!$(this).is(':checked')) {
                    console.log(this.curValue)
                    if (this.interestData.interests.includes(this.curValue)) {
                        // this.interestData.interests.pop(sub);
                        delete this.interestData.interests[this.curValue];
                    }
                }
            });

        },
        saveInterest() {
            axios.post("https://backend.innodip.rw/api/add/interest/" + this.userId, this.interestData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((response) => {
                    console.log("Response:", response.data);
                    // notifier.success(response.data.message, signupOption)
                    window.location = '/seeker/interests'
                })
                .catch((error) => {
                    // Handle any errors that occur during the request
                    console.error("Error:", error);
                    // notifier.alert("You can not apply twice.", signupOption);
                });
        }

    }
}
</script>

<style scoped>.image {
    aspect-ratio: 1/1;
}</style>