<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="p-4 md:ml-64 h-auto pt-20">
      <h1
        class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white"
      >
        Curriculum
        <span class="text-blue-600 dark:text-blue-500">call application</span>.
      </h1>
      <div class="flex flex-col gap-2 bg-white border rounded-lg p-4">
        <div class="flex flex-col gap-1">
          <b>Title</b><span>{{ datas.curriculumName }}</span>
        </div>

        <div class="flex flex-col gap-1">
          <b>Description</b>
          <p v-html="datas.description"></p>
        </div>
        <div>
          <button @click="sendData"
            class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Apply
            <svg
              class="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import apiService from "../../assets/api/apiService.js";
import axios from "axios";
import AWN from "awesome-notifications";

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: 'top-right',
};
globalOptions.labels = {
  alert: "Application",
  position: 'top-right',
};

let signupOption = {
  success: "Application",
  position: 'top-right',
};
signupOption.labels = {
  alert: "Application",
  position: 'top-right',
};

let notifier = new AWN(globalOptions);

export default {
  name: "dashboardPage",
  data() {
    return {
      username: "cyemezo",
      datas: [],
      curr:[],
      activeCat: "",
      isLoaded: false,
      curriculum:{
        "applicantId": "",
        "curriculumId": "",
        "curriculum_review_call_id" : "",
        "status": "pending"
        }
    };
  },
  components: {
    headerNavVue,
    menuNav,
  },
  methods:{
    sendData() {
            axios
                .post(`https://backend.innodip.rw/api/curricula-review/apply`, this.curriculum)
                .then((response) => {
                    // Handle the response from the server
                    console.log("Response:", response.data);
                    notifier.success("Applcation Sent.", signupOption);
                    // this.$router.go(0);
                })
                .catch((error) => {
                    // Handle any errors that occur during the request
                    console.error("Error:", error);
                    notifier.alert("Already appled for this call.", signupOption);
                });

        },
    getUser(data){
            this.user = JSON.parse(data);
            this.curriculum.applicantId = this.user._id
        }
  },
  mounted() {
    apiService
      .getData("fetch-a-curricula-by-id/" + this.$route.params.id)
      .then((res) => {
        (this.datas = res.curriculum), (this.isLoaded = true);
      });
    apiService
      .getData("curriculum-review-call/by-curriculum-id/" + this.$route.params.id)
      .then((res) => {
        (this.curr = res.reviewCall,
        this.curriculum.curriculum_review_call_id=this.curr._id);
      });
      this.curriculum.curriculumId=this.$route.params.id

  },
};
</script>

<style scoped>
.homeImg {
  height: 400px;
}
</style>
