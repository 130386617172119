<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
      <menuNav></menuNav>
      <headerNavVue @userData="getUser"></headerNavVue>
      <main class="p-4 md:ml-64 h-auto pt-20">
      <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">Personalized <span class="text-blue-600 dark:text-blue-500">curriculum</span>.</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
      <UniversityCardVue v-for="job in datas" :key="job" :datas="JSON.stringify(job.curriculum)" router="/industry" :hasDesc=true></UniversityCardVue>
    </div>

  </main>
  </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import UniversityCardVue from '../utils/curriculumCard.vue';
import apiService from '../../assets/api/apiService.js'
  export default {
      name: 'dashboardPage',
      data(){
          return{
              username: 'cyemezo',
              datas:[],
              activeCat:'',
              isLoaded:false
          }
      },
      components:{
          headerNavVue,
          menuNav,
          UniversityCardVue,
      },
      mounted(){
        apiService.getData('curriculum-review-call/fetch-all-curriculum-to-be-reviewed').then(res => {
            this.datas = res.curriculaForReview,
            this.isLoaded = true
        });

      },
  }
</script>

<style scoped>
</style>