<template>
    <div>
      <headerNavVue></headerNavVue>
      <div
        class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center flex flex-col"
      >
        <h1 class="text-2xl md:text-4xl mb-6 text-gray-600">
            What We Do
        </h1>
          <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-5">
                  <!-- <h2 class="text-2xl">Why do we use it?</h2> -->
                  <p>
This UTB-RISA project is intended to provide a sustainable
technological solution to bridge the skills gap through developing an
automated digital platform that will improve collaboration through
improved research and consultancy, curriculum development &amp;
review, and industry attachment &amp; job placement that will improve
graduates’ employable skills.The innovative digital platform will have a longterm positive effect of reducing un-employment in Rwanda</p>
              </div>
          </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </template>
  
  <script>
  import headerNavVue from "./utils/headerNav.vue";
  import pageFooterVue from "./utils/pageFooter.vue";
  export default {
    data() {
      return {};
    },
    components: {
      headerNavVue,
      pageFooterVue,
    },
    mounted() {
      document.title = "What We Do";
    },
  };
  </script>
  
  <style scoped></style>
  