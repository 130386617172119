<script setup>
import { reactive,computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import {required,minLength,email,sameAs,numeric,maxLength} from '@vuelidate/validators';
import apiService from '../../assets/api/apiService.js';

import AWN from "awesome-notifications"
let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Sign Up",
  position: 'top-right',
}

let signupOption =  {
  success: "Your account is successful created.",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Sign Up",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

const formData = reactive({
    fname : "",
    lname : "",
    password:'',
    nid:'',
    phone:'',
    email:'',
    ConfirmPassword:'',
    accept:false

})
const rules = computed(() => {
    return {
        fname : {required},
        lname : {},
        email : {required,email},
        nid:{required,minLength:minLength(16),numeric,maxLength:maxLength(16)},
        phone:{numeric},
        password:{required,minLength:minLength(8)},
        ConfirmPassword:{required,sameAs:sameAs(formData.password)},
        accept:{required}
    }
})
const $v = useVuelidate(rules, formData);
const submitForm = async () => {
    const result = await $v.value.$validate();
    if(result){ 
        sendData()
    }
}
function sendData(){
        const form = document.getElementById("formData");
        const serializedData = apiService.serializeFormData(form);
        apiService.signApplicant(serializedData).then(data=>{

            if(data.message=='success'){
                notifier.success('Your account is successful created.', signupOption)
                window.location = '/login';
            }
            if(data.errors){
                notifier.alert(data.errors[0], globalOptions)
            }

        }
            ).catch(error=>{
                var extra;
                console.log(error.response.data)
                var info = error.response.data;
                if(info.errors.phone){
                    extra = info.errors.phone[0];
                }
                if(info.errors.email){
                    extra = info.errors.email[0];
                }
                if(info.errors.nid){
                    extra = info.errors.nid[0];
                }
                notifier.alert(info.message+'\n'+extra, globalOptions)
            });
        }
</script>
<template>
    <div>
        <headerNavVue></headerNavVue>
        <div class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center">
            <div class="border border-1 mb-5 rounded-lg overflow-hidden grid w-full grid-cols-1 md:grid-cols-2">
                <div class="flex flex-col items-center justify-center">
                    <h2 class="text-2xl md:text-3xl p-3 my-3">Create Applicant account</h2>
                <img src="https://innodip.rw/img/illustrations/seeker.svg" class="px-18 homeImg">
            </div>
            <div class="flex flex-col items-center  bg-slate-100 border-l border-1">
                <h1 class="text-3xl text-center border-b border-gray-300 w-full py-3">Sign Up</h1>
                <form class="w-full px-4 py-4" @submit.prevent="sendData" id="formData">
                    <div class="grid grid-cols-2 gap-2">
                        <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Firstname</label>
                      <input type="text" placeholder="Firstname" @keyup="$v.fname.$touch" v-model="formData.fname" name="fname" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.fname.$errors" :key="error.$uid" class="text-red-500 text-xs">{{ error.$message }}</span>
                  </div>
                        <div>
                      <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lastname</label>
                      <input type="text" placeholder="Lastname" @keyup="$v.lname.$touch" v-model="formData.lname"  name="lname"  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.lname.$errors" :key="error.$uid" class="text-red-500 text-xs">{{ error.$message }}</span>
                  </div>
                    </div>
                        <div>
                      <label for="text" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address</label>
                      <input type="email" placeholder="Email Address" @keyup="$v.email.$touch" v-model="formData.email"  name="email"  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.email.$errors" :key="error.$uid" class="text-red-500 text-xs">{{ error.$message }}</span>
                  </div>
                    <div>
                        <FormSelect @setCitizen="settingCitizen" placeholder="Select Citizenship" label="Citizenship" inputType="text" value="" name="isLocalSignUp"  small=false >
                            <option value=1>Rwandan</option>
                            <option value=0>Non-Rwandan</option>
                        </FormSelect>
                        
                    <div v-if="citizen">
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">National ID</label>
                      <input type="text" placeholder="National ID" @keyup="$v.nid.$touch" v-model="formData.nid" name="nidOrPhone" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.nid.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        <div v-if="!citizen" class="grid grid-cols-2 gap-2">
                            <FormSelect @setCitizen="settingCitizen" placeholder="Select Country" label="Country" inputType="text" value="" name=""  small=false >
                                <option v-for="cnt in countries" :key="cnt" :value="cnt.phone_code">{{ cnt.country_en }} (+{{cnt.phone_code}})</option>
                            </FormSelect>
                            
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                      <input required type="text" placeholder="Phone Number" @change="$v.phone.$touch" @keyup="setNewVal(formData.phone)" v-model="formData.phone"  autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.phone.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        </div>
                    </div>
                    <input v-if="!citizen" type="hidden"  v-model="phoneNumber" name="nidOrPhone">
                    
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" placeholder="Password" @keyup="$v.password.$touch" v-model="formData.password" name="currentPassword" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.password.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                      <input type="password" placeholder="Confirm Password" @keyup="$v.ConfirmPassword.$touch" v-model="formData.ConfirmPassword" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.ConfirmPassword.$errors" :key="error.$uid" class="text-red-500 text-sm">Both passwords should match</span>
                  </div>
                    <div class="flex items-center justify-between mt-4">
                        <div>
                            <div class="flex items-center">
                                <input @change="formData.accept=!formData.accept" required id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Accept <a class="link light" href="/privacy">Pricacy & Policy</a> </label>
                            </div>
                            <span v-for="error in $v.accept.$errors" :key="error.$uid" class="text-red-500 text-sm">Please accept privacy and policy</span>
                        </div>

                        <!-- <FormButton type="submit" label="Register" bstyle="primary"></FormButton> -->
                        <button type="button" @click="submitForm" class=" text-white bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign Up</button>
                    </div>
                        <div class="text-sm">
                            <span>Have an account?</span>&nbsp;
                            <a href="/login/" class="link light">Login Here</a>
                        </div>
                </form>
            </div>
            </div>
        </div>
        <pageFooterVue></pageFooterVue>
    </div>
</template>
<script>

import headerNavVue from '../utils/headerNav.vue'
import pageFooterVue from '../utils/pageFooter.vue'
import FormSelect from '../utils/FormSelect.vue';
export default {
            
    data() {
        return{
            username: 'cyemezo',
            datas:[],
            activeCat:'',
            citizen:true,
            isPasswordEqual:false,
            countries:[],
            phoneNumber:'',
            countryCode:'',
        }
    },
    components:{
        headerNavVue,
        pageFooterVue,
        FormSelect,
    },
    mounted(){
        apiService.getData('all_countries').then((response) => {
        this.countries = response;
        
        });
        document.title="Applicant Sign Up"


    },
    methods: {
        setPhone(){
            // this.phoneNumber = 
        },
        setCountry(data){
            this.countryCode = data
        },
       
        settingCitizen(event){
            if(event.target.value!=''){
            const val = event.target.value=='1'?true:false
            this.citizen = val
            this.countryCode='+'+event.target.value
            }
        },
        setNewVal(val){
            if(val!=''){
            this.phoneNumber=' '+this.countryCode+val
            }
        }
    },
}
</script>
<style scoped>
.homeImg{
    height: 400px;
}
</style>