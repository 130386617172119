<template>
  <div class="grid grid-cols-10">
    <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
      <menuNav></menuNav>
    </div>
    <div class="col-span-12 md:col-span-8">
      <headerNavVue @userData="getUser"></headerNavVue>
      <div class="p-4 justify-center">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center w-full md:w-auto px-2 md:px-0">
            <svg
              class="w-16 h-16 text-secondary"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <h1 class="text-4xl text-gray-500">
              {{ isEdit ? "Edit" : "Add" }} Curriculum Course
            </h1>
          </div>
        </div>
      </div>

      <div class="grid mx-auto">
        <div class="p-4 border rounded-lg m-4 sm:px-8" v-if="!canEdit">
        <div class="max-w-screen-md">
            <h2 class="mb-4 text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">{{researchData.courses[0].title}}</h2>
            <div class="flex flex-col gap-2">
                <b>Description</b>
                <p class="mb-4 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="researchData.courses[0].description"></p>
            </div>
            <div class="flex flex-col gap-2">
                <b>Year</b>
                <p class="mb-4 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="researchData.courses[0].year"></p>
            </div>
            <div class="flex flex-col gap-2">
                <b>Term</b>
                <p class="mb-4 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="researchData.courses[0].term"></p>
            </div>
            <div class="flex flex-col gap-2">
                <b>Credits</b>
                <p class="mb-4 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="researchData.courses[0].credits"></p>
            </div>
            <div class="flex flex-col gap-2">
                <b>Teaching Hours</b>
                <p class="mb-4 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="researchData.courses[0].teachingHours"></p>
            </div>
            <div class="flex flex-col gap-2">
                <b>Topics To Cover</b>
                <p class="mb-4 font-light text-gray-500 sm:text-xl dark:text-gray-400" v-html="researchData.courses[0].topicsToCover"></p>
            </div>
            <div class="flex flex-col gap-2 mb-4 ">
                <b>Prerequisites</b>
                <ol>
                    <li v-for="len in researchData.courses[0].prerequisites" :key="len" class="mb-1 font-light text-gray-500 sm:text-xl dark:text-gray-400"><span  v-html="len"></span></li>
                </ol>
            </div>
            <div class="flex flex-col gap-2 mb-4 ">
                <b>Learning Outcomes</b>
                <ol>
                    <li v-for="len in researchData.courses[0].learningOutcomes" :key="len" class="mb-1 font-light text-gray-500 sm:text-xl dark:text-gray-400"><span  v-html="len"></span></li>
                </ol>
            </div>
            <div class="flex flex-col gap-2 mb-4 ">
                <b>References And Materials</b>
                <ol>
                    <li v-for="len in researchData.courses[0].referencesAndMaterials" :key="len" class="mb-1 font-light text-gray-500 sm:text-xl dark:text-gray-400"><span  v-html="len.title"></span> (<a :href="len.url">Visit</a>)</li>
                </ol>
            </div>
            <div class="flex space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <button @click="canEdit=!canEdit" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    Update
                </button>  
                <button @click="deleteNow(researchData.courses[0]._id)" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 bg-danger rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    Delete
                </button>  
            </div>
        </div>
    </div>
        <div class="container mx-auto px-4 mb-10 sm:px-8" v-if="canEdit">
          <ol
            class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 mb-4"
          >
            <li
              class="flex items-center"
              v-for="(tab, index) in tabs"
              :key="index"
              @click="index < curTab ? activateTab(index) : ''"
              :class="{ activea: tab.active }"
            >
              <span
                class="flex items-center justify-center w-5 h-5 mr-2 text-xs border"
                :class="{ activeb: tab.active }"
              >
                {{ tab.number }}
              </span>
              {{ tab.text }}
              <svg
                v-if="!isLatestStep(index)"
                class="w-3 h-3 ml-2 sm:ml-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 12 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m7 9 4-4-4-4M1 9l4-4-4-4"
                />
              </svg>
            </li>
          </ol>
          <form id="formData" @submit.prevent="sendData">
            <input type="hidden" v-model="datas._id" name="provider_id" />
            <div class="flex flex-col md:flex-row gap-4 w-full">
              <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 0">
                <div class="w-full flex flex-col col-span-2">
                  <label class="text-sm mb-2"
                    >Title <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Title"
                    type="text"
                    v-model="researchData.courses[0].title"
                  />
                </div>
                <div class="w-full flex flex-col">
                  <label class="text-sm mb-2"
                    >Year <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Year"
                    type="text"
                    v-model="researchData.courses[0].year"
                  />
                  <span class="text-red-500 text-xs">{{ researchData.courses[0].year==''?'This field is required':(!/^\d+$/.test(researchData.courses[0].year)?'Please type only numbers':'') }}</span>

                </div>
                <div class="w-full flex flex-col">
                  <label class="text-sm mb-2"
                    >Term <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Term"
                    type="text"
                    v-model="researchData.courses[0].term"
                  />
                  <span class="text-red-500 text-xs">{{ researchData.courses[0].term==''?'This field is required':(!/^\d+$/.test(researchData.courses[0].year)?'Please type only numbers':'') }}</span>
                </div>
                <div class="w-full flex flex-col">
                  <label class="text-sm mb-2"
                    >Credits <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Credits"
                    type="text"
                    v-model="researchData.courses[0].credits"
                  />
                  <span class="text-red-500 text-xs">{{ researchData.courses[0].credits==''?'This field is required':(!/^\d+$/.test(researchData.courses[0].year)?'Please type only numbers':'') }}</span>
                </div>
                <div class="w-full flex flex-col">
                  <label class="text-sm mb-2"
                    >Teaching Hours
                    <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Teaching Hours"
                    type="text"
                    v-model="researchData.courses[0].teachingHours"
                  />
                  <span class="text-red-500 text-xs">{{ researchData.courses[0].teachingHours==''?'This field is required':(!/^\d+$/.test(researchData.courses[0].year)?'Please type only numbers':'') }}</span>
                </div>
                <div class="w-full flex flex-col col-span-2">
                  <label class="text-sm mb-2"
                    >Description <strong class="text-red-400">*</strong></label
                  >
                  <ckeditor
                    v-model="researchData.courses[0].description"
                    :editor="editor5"
                    @ready="onReady"
                    @input="onChange"
                  ></ckeditor>
                </div>
                <div class="w-full flex flex-col col-span-2">
                  <label class="text-sm mb-2"
                    >Topics To Cover
                    <strong class="text-red-400">*</strong></label
                  >
                  <ckeditor
                    v-model="researchData.courses[0].topicsToCover"
                    :editor="editor"
                    @ready="onReady"
                    @input="onChange"
                  ></ckeditor>
                </div>
                <div class="flex gap-5">
                  <div class="flex gap-5">
                    <button
                      type="button"
                      @click="activateTab(1)"
                      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Next
                      <svg
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  
                </div>
              </div>
              <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 1">
                <div
                  class="w-full flex flex-col col-span-2"
                  v-for="(outcome, index) in researchData.courses[0]
                    .prerequisites"
                  :key="index"
                >
                  <div class="flex justify-between items-center w-full">
                    <label class="text-sm mb-2"
                      >Prerequisites
                      <strong class="text-red-400">*</strong>
                    </label>

                    <svg
                      @click="removePre(index)"
                      class="text-red-600 h-12 w-12"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <textarea
                    v-model="researchData.courses[0].prerequisites[index]"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                  ></textarea>
                </div>

                <div class="col-span-2 mt-4">
                  <button
                    type="button"
                    @click="addPre()"
                    class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Add Prerequisite
                  </button>
                </div>
                <div class="flex gap-5 col-span-2">
                  <button
                    type="button"
                    @click="activateTab(0)"
                    class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Back
                    <svg
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    @click="activateTab(2)"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Next
                    <svg
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 2">
                <div
                  class="w-full flex flex-col col-span-2"
                  v-for="(outcome, index) in researchData.courses[0]
                    .learningOutcomes"
                  :key="index"
                >
                  <div class="flex justify-between items-center w-full">
                    <label class="text-sm mb-2"
                      >Learning Outcome
                      <strong class="text-red-400">*</strong>
                    </label>

                    <svg
                      @click="removeLern(index)"
                      class="text-red-600 h-12 w-12"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <textarea
                    v-model="researchData.courses[0].learningOutcomes[index]"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                  ></textarea>
                </div>

                <div class="col-span-2 mt-4">
                  <button
                    type="button"
                    @click="addLern()"
                    class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Add Learning Outcome
                  </button>
                </div>
                <div class="flex gap-5 col-span-2">
                  <button
                    type="button"
                    @click="activateTab(1)"
                    class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Back
                    <svg
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    @click="activateTab(3)"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Next
                    <svg
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 3">
                                <div class="w-full flex flex-col col-span-2">
                                    <div class="flex flex-col">
                                    <div class="flex flex-col">
                                        <label class="text-sm mb-2">Materials
                                            <strong class="text-red-400">*</strong></label>
                                        <div class="flex flex-col gap-3 w-full">
                                            <div class="w-full grid grid-cols-5 gap-3 relative p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                                                v-for="(fac, ind) in researchData.courses[0].referencesAndMaterials" :key="ind">

                                                <div class="flex flex-col col-span-2">
                                                    <label class="text-sm mb-2">Title
                                                        <strong class="text-red-400">*</strong></label>
                                                    <input
                                                        class="appearance-none w-full block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                        required="required" placeholder="Education Issue" type="text"
                                                        v-model="researchData.courses[0].referencesAndMaterials[ind].title" />
                                                </div>

                                                <div class="flex flex-col col-span-2">
                                                    <label class="text-sm mb-2">URL(link)
                                                        <strong class="text-red-400">*</strong></label>
                                                    <input
                                                        class="appearance-none w-full block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                        required="required" placeholder="Education Issue" type="text"
                                                        v-model="researchData.courses[0].referencesAndMaterials[ind].url" />
                                                </div>
                                                <svg @click="removeMat(ind)"
                                                    class="text-red-600 h-12 w-12 absolute top-4 right-4" aria-hidden="true"
                                                    fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                            </div>
                                            
                                        <label class="text-sm mb-2">Add New Material</label>
                                            <div class="w-full grid grid-cols-2 gap-3 relative p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                                               >

                                                <div class="flex flex-col">
                                                    <label class="text-sm mb-2">Title
                                                        <strong class="text-red-400">*</strong></label>
                                                    <input
                                                        class="appearance-none w-full block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                        required="required" placeholder="Title" type="text"
                                                        v-model="newMat.title" />
                                                </div>

                                                <div class="flex flex-col">
                                                    <label class="text-sm mb-2">URL(link)
                                                        <strong class="text-red-400">*</strong></label>
                                                    <input
                                                        class="appearance-none w-full block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                                        required="required" placeholder="URL" type="text"
                                                        v-model="newMat.url" />
                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-span-2 mt-4">
                                            <button type="button" @click="addMat(index)"
                                                class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                Add Material
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex gap-5 col-span-2 mt-6">
                                    <button type="button" @click="activateTab(1)"
                                        class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Back
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                    <button
                    type="button"
                    @click="sendData" :disabled="canSend"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm whitespace-nowrap px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {{ isEdit ? "Update" : "Save" }} Course
                    <svg
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                                </div>
                                </div>
                                </div>
          </form>
        </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import pageFooterVue from "../seeker/utils/pageFooter.vue";
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications";
// import $ from 'jquery'
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: 'top-right',
};
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
};

let signupOption = {
  success: "Profile",
  position: 'top-right',
};
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
};

let notifier = new AWN(globalOptions);

export default {
  name: "dashboardPage",
  data() {
    return {
      isError:false,
      datas: [],
      isLoaded: false,
      showModal: false,
      categories: [],
      curTab: 0,
      canSend:false,
      isEdit: false,
      canEdit:false,
      editor: ClassicEditor,
      editor2: ClassicEditor,
      editor3: ClassicEditor,
      editor4: ClassicEditor,
      editor5: ClassicEditor,
      editor6: ClassicEditor,
      editor7: ClassicEditor,
      tabs: [
        {
          text: "Details",
          number: "1",
          active: true,
        },
        {
          text: "Prerequisites",
          number: "2",
          active: false,
        },
        {
          text: "Learning Outcomes",
          number: "3",
          active: false,
        },
        {
          text: "References And Materials",
          number: "4",
          active: false,
        },
      ],
      newReq: "",
      newPre: "",
      newMat: {
                title: "",
                url: ""
            },
      researchData: {
        courses: [
          {
            curriculumId: "",
            title: "",
            prerequisites: [],
            description: "",
            learningOutcomes: [],
            year: "",
            term: "",
            credits: "",
            teachingHours: "",
            topicsToCover: "",
            referencesAndMaterials: [],
          },
        ],
      },
    };
  },

  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,
    ckeditor: CKEditor.component,
  },
  mounted() {
    this.researchData.courses[0].curriculumId = this.$route.params.id;
    let id = this.$route.params.course;
    if(id){
        apiService.getData('fetch-course-by-id/'+id).then((response) => {
            this.researchData.courses[0] = response.course;
            this.isEdit = true
            document.title = this.isEdit?'Edit Curriculum':'Add Curriculum'
        this.canEdit = false
        });
    }else{
            this.canEdit = true
        document.title = this.isEdit?'Edit Curriculum':'Add Curriculum'
    }
  },
  methods: {
    isLatestStep(index) {
      return index === this.tabs.length - 1;
    },
    activateTab(index) {
      // Deactivate all tabs
      this.curTab = index;
      this.tabs.forEach((tab) => {
        tab.active = false;
      });

      // Activate the clicked tab
      this.tabs[index].active = true;
    },
    addLern() {
      this.researchData.courses[0].learningOutcomes.push(this.newLern);
      this.newLern = "";
    },
    removeLern(index) {
      this.researchData.courses[0].learningOutcomes.splice(index, 1);
    },
    addPre() {
      this.researchData.courses[0].prerequisites.push(this.newPre);
      this.newLern = "";
    },
    removePre(index) {
      this.researchData.courses[0].prerequisites.splice(index, 1);
    },
    addMat() {
        if (this.newMat.title) {
            this.researchData.courses[0].referencesAndMaterials.push({ title: this.newMat.title, url: this.newMat.url});
            this.newMat.title = '';
            this.newMat.url = '';
        }
    },
    removeMat(id) {
        this.researchData.courses[0].referencesAndMaterials.splice(id, 1);
    },
    deleteNow(id){
        let conf = confirm('Do you want to delete this course?')
        if(conf){
        apiService.deleteData('delete-the-course/'+id).then(res => {
            console.log(res.response)
            notifier.success("Curriculum course deleted", signupOption)
            this.sendDelete = false
        });
    }
    },
    sendData() {
      this.canSend=true
      let course = this.$route.params.course;
      axios
        .post(
          `https://backend.innodip.rw/api/${
            typeof course !== "undefined"
              ? `update-the-course/${course}`
              : "add-courses"
          }`,
          this.researchData
        )
        .then((response) => {
          // Handle the response from the server
      this.canSend=false
          console.log("Response:", response.data);
          notifier.success("Curriculum Course Saved.", signupOption);
          this.$router.go(`en/institution/curriculum/courses/${this.$route.params.id}`);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error:", error);
          notifier.alert(
            "Curriculum course not added some filelds are not filled.",
            signupOption
          );
        });
    },
    getUser(data) {
      this.datas = JSON.parse(data);
      this.selectedFilePreview = this.baseUrl + this.datas.picture;
      (this.isLoaded = true),
        (this.researchData.institutionId = this.datas._id);
    },
  },
};
</script>

<style scoped></style>
