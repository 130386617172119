<template>
    <div>
        <headerNavVue></headerNavVue>

        <div class="max-w-screen-lg mx-auto px-4 md:px-0 py-4">
            <section class="bg-white dark:bg-gray-900">
                <div class="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                    <h2 class="mb-2 text-xl font-semibold leading-none text-gray-900 md:text-2xl dark:text-white">
                        {{ datas.title }}</h2>
                    <p class="mb-4 text-xl font-extrabold leading-none text-gray-900 md:text-2xl dark:text-white">Published
                        in: {{ datas.published_year }}</p>
                    <dl>
                        <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Details</dt>
                        <dd class="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400">{{ datas.abstract }}</dd>
                    </dl>
                    <dl class="flex items-center space-x-6">
                        <div>
                            <!-- <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Author</dt>
              <dd class="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400"></dd> -->
                        </div>
                    </dl>
                    <div class="flex items-center space-x-4">
                        <a :href="`${datas.url}`" target="_blank"
                            class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Visit this Article
                            <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </a>
                    </div>
                </div>
            </section>
        </div>
        <pageFooterVue></pageFooterVue>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import pageFooterVue from './utils/pageFooter.vue'
import apiService from '../assets/api/apiService.js'
export default {
    data() {
        return {
            username: 'cyemezo',
            datas: [],
            activeCat: '',
            baseUrl: 'https://backend.innodip.rw/',
            isLoaded: false,
            userId:null
        }
    },
    components: {
        headerNavVue,
        pageFooterVue,
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem('currentUser'));
        const id = this.$route.params.id;
        console.log(this.userId,localStorage.getItem('currentType') != 'applicant')
        if (this.userId == null && localStorage.getItem('currentType') != 'applicant') {
            localStorage.setItem('url', '/seeker/publications/research/'+id);
            window.location = '/login';
        }
        apiService.getData('fetch-research-articles-by-id/' + id).then(res => {
            this.datas = res.researchArticle;
            // this.activeCat = jobsList.categories[0].name;
            this.isLoaded = true
        });
    },

}
</script>

<style scoped></style>