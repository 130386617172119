<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="p-4 md:ml-64 h-auto pt-20">
      <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">
        Personalized <span class="text-blue-600 dark:text-blue-500">opportunities</span> in <span
          class="text-blue-600 dark:text-blue-500">{{ categoryName }}</span>.</h1>
      <div v-if="datas == ''" id="alert-1"
        class="flex items-center p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
        role="alert">
        <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 20 20">
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span class="sr-only">Oops!</span>
        <div class="ml-3 text-sm font-medium">
          No available opportunities in {{ categoryName }}
        </div>
        <button type="button"
          class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
          data-dismiss-target="#alert-1" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
        <jobCardVue v-for="job in datas" :key="job" :datas="JSON.stringify(job)" router="/seeker/opportunity"
          :hasDesc=true></jobCardVue>
      </div>

    </main>
  </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import jobCardVue from '../utils/jobCard.vue';
import apiService from '../../assets/api/apiService.js'
export default {
  name: 'dashboardPage',
  data() {
    return {
      username: 'cyemezo',
      datas: [],
      activeCat: '',
      isLoaded: false
    }
  },
  components: {
    headerNavVue,
    menuNav,
    jobCardVue,
  },
  mounted() {
    apiService.getOpportunities().then(jobsList => {
      this.datas = jobsList;
      // this.activeCat = jobsList.categories[0].name;
      this.isLoaded = true
      const filteredJobsArray = Object.values(this.datas).filter((value) => {
        if (value.category && value.category.industryCategoryName === this.categoryName) {
          return true;
        }
        return false;
      });
      this.datas = filteredJobsArray;
    });
  },
  computed: {
    categoryName() {
      let cat = this.$route.params.category
      return cat.charAt(0).toUpperCase() + cat.slice(1);
    },
  },
}
</script>

<style scoped>.homeImg {
  height: 400px;
}</style>