<template>
    <div>
        <headerNavVue></headerNavVue>

<div id="indicators-carousel" class="relative w-full" data-carousel="slide">
    <!-- Carousel wrapper -->
    <div class="relative h-56 overflow-hidden  md:h-96">
         <!-- Item 1 -->
        <div class="hidden duration-1000 ease-in-out" data-carousel-item="active">
            <div class="h-[100%] flex justify-center object-cover relative items-center p-24">
                <div  class="z-10 absolute text-white flex flex-col items-center gap-5"><div class="list-wrapper">
                        <div class="word-list text-5xl md:text-8xl h-[100px] leading-[100px]">
                            <span>Innovative</span>
                            <span>Digital</span>
                            <span>Platform</span>
                        </div>
                    </div>
                </div>
                <img src="https://innodip.rw/img/inodip_bg.jpg" class="absolute z-1 object-cover block w-full h-[100%] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
            </div>
        </div>
         <!-- Item 1 -->
        <div class="hidden duration-1000 ease-in-out" data-carousel-item>
            <div class="h-[100%] flex object-cover relative items-center p-24">
                <div  class="z-10 absolute text-white flex flex-col gap-5">
                    <h1 class="text-5xl bg-gray-400 bg-opacity-50 p-3">Are you ready to seize new<br> opportunities?</h1>
                    <div>
                        <p class=" bg-gray-400 bg-opacity-50 p-3">This platform offers a vibrant ecosystem for growth and success.</p>
                    </div>
                </div>
                <img src="https://innodip.rw/img/applicant.jpg" class="absolute z-1 object-cover block w-full h-[100%] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
            </div>
        </div>
        <!-- Item 2 -->
        <div class="hidden duration-1000 ease-in-out" data-carousel-item>
            <div class="h-[100%] flex object-cover relative items-center p-24">
                <div  class="z-10 absolute text-white flex flex-col gap-5">
                    <h1 class="text-5xl bg-gray-400 bg-opacity-50 p-3">Are you an<br> Academia?</h1>
                    <div>
                        <p class=" bg-gray-400 bg-opacity-50 p-3">This platform offers a vibrant ecosystem for growth and success.</p>
                    </div>
                </div>
                <img src="https://innodip.rw/img/utb.jpg" class="absolute z-1 object-cover block w-full h-[100%] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
            </div>
        </div>
        <!-- Item 3 -->
        <div class="hidden duration-1000 ease-in-out" data-carousel-item>
            <div class="h-[100%] flex object-cover relative items-center p-24">
                <div  class="z-10 absolute text-white flex flex-col gap-5">
                    <h1 class="text-5xl bg-gray-400 bg-opacity-50 p-3">Are you an<br> Industry?</h1>
                    <div>
                        <p class=" bg-gray-400 bg-opacity-50 p-3">This platform offers a vibrant ecosystem for growth and success.</p>
                    </div>
                </div>
                <img src="https://innodip.rw/img/industry.jpg" class="absolute z-1 object-cover block w-full h-[100%] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
            </div>
        </div>
        <!-- Item 4 -->
        <div class="hidden duration-1000 ease-in-out" data-carousel-item>
            <div class="h-[100%] flex object-cover relative items-center p-24">
                <div  class="z-10 absolute text-white flex flex-col gap-5">
                    <h1 class="text-5xl bg-gray-400 bg-opacity-50 p-3">Please<br> Join Us?</h1>
                    <div>
                        <p class=" bg-gray-400 bg-opacity-50 p-3">This platform offers a vibrant ecosystem for growth and success.</p>
                    </div>
                </div>
                <img src="https://innodip.rw/img/join.jpg" class="absolute z-1 object-cover block w-full h-[100%] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
            </div>
        </div>
    </div>
    <!-- Slider indicators -->
    <div class="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
        <button type="button" class="w-3 h-3 rounded-full" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
        <button type="button" class="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
        <button type="button" class="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
        <button type="button" class="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
        <button type="button" class="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
    </div>
    <!-- Slider controls -->
    <button type="button" class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span class="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
    </button>
</div>
<div class="mx-auto max-w-screen-md  my-6 flex gap-8 w-full border rounded-lg p-6">
    <img src="@/assets/illustrations/about.gif" class="w-[200px] h-[200px]">
    <p>An innovative digital platform that seamlessly connects academia and industry. Our platform offers a holistic experience, enabling users to access several opportunities including; Jobs, Internships, Scholarships, Trainings, Book Publishing, Curriculum Review and Development, Conferences and Consultancies</p>
</div>
<section class="bg-white dark:bg-gray-900 grid grid-cols-1 md:grid-cols-5">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 col-span-3">
      <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Recent opportunities</h2>
          <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Explore from our most recent published opportunities.</p>
      </div> 
      <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
        <jobCardVue v-for="job in datas" :key="job" :datas="JSON.stringify(job)" router="/opportunity" :hasDesc=true></jobCardVue>
          
      </div>  
  </div>
  <div v-if="trainings" class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 col-span-2 bg-primary ">
      <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-white dark:text-white">Trainings</h2>
          <!-- <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Explore from our most recent published opportunities.</p> -->
      </div> 
      <div class="grid gap-8 mb-6 lg:mb-16">
        <trainingCard v-for="job in trainings" :key="job" :datas="JSON.stringify(job)" router="/opportunity" :hasDesc=true></trainingCard>
          
      </div>  
  </div>
</section>
<section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
      <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Popular Industries</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Explore most popular industries</p>
      </div> 
      <div class="grid gap-4 lg:gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
        <industryCardVue v-for="job in industries" :key="job" :datas="JSON.stringify(job)" router="/industry" :hasDesc=true></industryCardVue>
      </div>  
  </div>
</section>
<section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
      <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Popular Academia</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Explore most popular academia</p>
      </div> 
      <div class="grid gap-4 lg:gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
        <academiaCardVue v-for="job in institutions" :key="job" :datas="JSON.stringify(job)" router="/university" :hasDesc=true></academiaCardVue>
      </div>  
  </div>
</section>
<section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
      <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Recent Publications</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Explore most recent publications</p>
      </div> 
      <div class="grid gap-4 lg:gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
      <pubCardVue v-for="job in pubs" :key="job" :datas="JSON.stringify(job)" router="/industry" :hasDesc=true></pubCardVue>
      </div>  
  </div>
</section>
<section class="bg-white dark:bg-gray-900 grid grid-cols-1 md:grid-cols-5">
  <div v-if="conference" class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 col-span-2 bg-primary">
      <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-white dark:text-white">Conferences</h2>
          <!-- <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Explore from our most recent published opportunities.</p> -->
      </div> 
      <div class="grid gap-8 mb-6 lg:mb-16">
        <conferenceCard v-for="job in conference" :key="job" :datas="JSON.stringify(job)" router="/opportunity" :hasDesc=true></conferenceCard>
          
      </div>  
  </div>
  <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6 col-span-3">
      <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Recent Research Articles</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Explore most recent research articles</p>
      </div> 
      <div class="grid gap-4 lg:gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
      <articleCard v-for="job in articles" :key="job" :datas="JSON.stringify(job)" router="/research/article/" :hasDesc=true></articleCard>
      </div>  
  </div>
</section>


<div v-if="isBanner" id="animation-carousel" class="relative py-8 px-4 mx-auto max-w-screen-xl text-center lg:pb-16 lg:px-6" data-carousel="static">
    <!-- Carousel wrapper -->
    <div class="relative h-56 overflow-hidden rounded-lg md:h-96">
        <div class="hidden duration-700 ease-in-out" data-carousel-item v-for="banner in banners" :key="banner">
            <img :src="baseUrl+banner.bannerImage" class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
        </div>
    </div>
    <!-- Slider controls -->
    <button type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span class="sr-only">Previous</span>
        </span>
    </button>
    <button type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
    </button>
</div>

       <pageFooterVue></pageFooterVue>

    </div>
</template>

<script>

import headerNavVue from './utils/headerNav.vue'
import pageFooterVue from './utils/pageFooter.vue'
import jobCardVue from './utils/jobCardHome.vue';
import industryCardVue from './utils/industryCard.vue';
import academiaCardVue from './utils/academiaCard.vue';
import pubCardVue from './utils/publicationCard.vue';
import articleCard from './utils/articleCard.vue';
import trainingCard from './utils/trainingCard.vue';
import conferenceCard from './utils/conferenceCard.vue';
import apiService from '../assets/api/apiService.js';
    export default {
        data(){
            return{
                categories:[],
                datas:[],
                dummies:[],
                industries: [],
                institutions: [],
                articles: [],
                pubs: [],
                trainings:[],
                conference:[],
                banners:[],
                activeCat:'all',
                isLoaded:false,
                distanceToLeft: 0,
                itemsToShow:3,
                baseUrl: 'https://backend.innodip.rw/',
                isBanner:false
            }
        },
        components:{
            headerNavVue,
            pageFooterVue,
            jobCardVue,
            industryCardVue,
            academiaCardVue,
            pubCardVue,
            articleCard,
            trainingCard,
            conferenceCard
        },
        setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
        };
        },
        mounted(){
            apiService.getOpportunities().then(jobsList => {
                this.datas = jobsList,
                this.isLoaded = true
                apiService.getData('all_opportunity_categories').then(res => {
                    this.categories = res,
                    this.isLoaded = true
                });
            });
            apiService.getIndustries().then(industryList => {
                this.industries = industryList;
            });
            apiService.getInstitutions().then(instituteList => {
                this.institutions = instituteList;
            });
            apiService.getData('fetch-all-book-publications').then(res => {
                this.pubs = res.publications,
                this.isLoaded = true
            });
            apiService.getData('get/all/banners/').then(res => {
                this.banners = res,
                this.isBanner = true
            });
            apiService.getData('get/all/training/').then(res => {
                this.trainings = res
            });
            apiService.getData('get/all/conferences/').then(res => {
                this.conference = res
            });
            apiService.getData('fetch-all-research-articles').then(res => {
                this.articles = res.articles,
                this.isLoaded = true
            });
            this.getDistanceToLeft();
            document.title="Innovative Digital Platform - HOME"
        },
        methods:{
            hello(id){
                this.$router.push('industry/'+id)
            },
            sendData(){
                const form = document.getElementById('formData');
                const serializedData = apiService.serializeFormData(form);
                console.log(serializedData);
            },
            getDistanceToLeft() {
            const element = this.$refs.myElement;
            if (element) {
                const rect = element.getBoundingClientRect();
                this.distanceToLeft = rect.left;
            }
            },
            getFirstItemStyle(index) {
            if (index === 0) {
                return {
                /* Apply your CSS styles for the first item here */
                marginLeft: this.distanceToLeft+'px',
                };
            }
            return {}; // Empty object for other items (no additional styles)
            },
            async filterJobs(id){
                
                 apiService.getData('opportunities').then(res => {
                    this.datas = res
                    if(id!='all'){
                        this.activeCat=this.categories[id].name
                        const filteredJobsArray = Object.values(this.datas).filter((value) => {
                        if (value.category && value.category.industryCategoryName === this.activeCat) {
                            return true;
                        }
                        return false;
                        });
                        this.datas = filteredJobsArray;
                    }else{
                        this.activeCat=id
                    }
                })

            }

    },
    }
</script>

<style scoped>

.list-wrapper{
    display: inline-block;
    gap: 5px;
  text-align: center;
  overflow: hidden;
}
div.word-list span {
  display: block;
}
div.word-list {
  position: relative;
  animation: cycle ease 5s infinite;
}

@keyframes cycle {
  0% {top: 0;}
  20% {top: 0;}
  30% {top: -100px;}
  50% {top: -100px;}
  60% {top: -200px;}
  80% {top: -200px;}
  100% {top: 0px;}
}

/* colors */
span:nth-child(1) {
  color:#B58C1A;
}
span:nth-child(2) {
  color:#108A37;
}
span:nth-child(3) {
  color:#f7f76f;
}

</style>