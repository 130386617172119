<template>
  <div class="items-center bg-gray-50 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div class="p-5 flex flex-col gap-2">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      <a href="#">{{ job.title }}</a>
                  </h3>
                  <span class="text-gray-500 dark:text-gray-400">Language: {{ job.language }}</span>
                  <!-- <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400"
       >Researcher: {{ job.institutionUploader.name!='null'?job.institutionUploader.name:'' }}</p> -->
                  <ul class="flex space-x-4 sm:mt-0">
                    <a :href="`/seeker/research/${job._id}`" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        View Research
                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </a>
                  </ul>
              </div>
          </div>  
</template>

<script>
import apiService from "../../assets/api/apiService.js";
export default {
  name: "JobCard",
  props: {
    datas: String,
    hasDesc: Boolean,
    router: String,
  },
  data() {
    return {
      job: [],
      baseUrl: "https://backend.innodip.rw/",
    };
  },
  created() {
    this.job = JSON.parse(this.datas);
  },
  methods: {
    realDate(date) {
      return apiService.realDate(date);
    },
    fewWords(words, num) {
      return apiService.displayWords(words, num);
    },
  },
};
</script>

<style scoped>
</style>