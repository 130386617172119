<template>
    <div>
        <headerNavVue></headerNavVue>

        <div class="max-w-screen-lg mx-auto py-4">
            <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                    <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                        stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.25 21h19.5m-18-18en8m10.5-18en8m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <h1 class="text-4xl text-gray-500">Academia</h1>
                </div>
                <div class="w-full md:w-auto px-2 md:px-0">
                    <keep-alive>
                        <form action="">
                            <FormInput placeholder="Search..." label="" inputType="text" small="true"></FormInput>
                        </form>
                    </keep-alive>
                </div>
            </div>
        </div>
        <div class="grid max-w-screen-lg mx-auto px-3 md:px-0 py-4">
            <div v-if="isLoaded" class="grid grid-cols-2 md:grid-cols-5 gap-3">
                <UniversityCardVue v-for="job in datas" :key="job" :datas="JSON.stringify(job)" router="/university"
                    :hasDesc=true></UniversityCardVue>
            </div>
            <div v-if="!isLoaded">
                <jobCardVueSkeleton v-for="job in 5" :key="job" :hasDesc=true></jobCardVueSkeleton>
            </div>
            <div class="flex items-center justify-center" v-if="isLoaded">
                <FormButton v-if="!isLoaded" type="button" label="Load More" bstyle="normal"></FormButton>
            </div>

            <div class="flex items-center w-full md:w-auto py-4 px-2 md:px-0">
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                    stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">Academia Opportunities</h1>
            </div>
            <div v-if="jobs == ''" id="alert-1"
        class="flex items-center p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
        role="alert">
        <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 20 20">
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span class="sr-only">Oops!</span>
        <div class="ml-3 text-sm font-medium">
          No available opportunities in academia
        </div>
        <button type="button"
          class="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
          data-dismiss-target="#alert-1" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>
      </div>
            <div v-if="isLoaded && jobs!=''" class="flex gap-2 flex-wrap  overflow-hidden mb-4">

                <div class="mb-2 rounded-3xl py-2 px-4 text-white hover:bg-green-700 whitespace-nowrap cursor-pointer"
                    :class="'All' == activeCat ? 'bg-green-500' : 'bg-stone-700'" @click="filterJobs('All')">
                    All
                </div>
                <div v-for="(job, index) in categories" :key="index" :datas="JSON.stringify(job)"
                    class="mb-2 rounded-3xl py-2 px-4 text-white hover:bg-green-700 whitespace-nowrap cursor-pointer"
                    :class="job.name == activeCat ? 'bg-green-500' : 'bg-stone-700'" @click="filterJobs(index)">
                    {{ job.name }}
                </div>
            </div>
            <div v-if="!isLoaded" class="sliderPane flex gap-2 overflow-x-scroll px-2 md:px-4 lg:px-32 pb-2">
                <div v-for="job in 10" :key="job"
                    class="mb-2 rounded-3xl text-gray-300 h-10 py-2 px-4 hover:bg-gray-300 bg-gray-300">
                    sampletext</div>
            </div>
            <div v-if="isLoaded && jobs!=''" class="mb-4">Filtered by <b>{{ activeCat }}</b></div>
            <div v-if="isLoaded">
                <div class="alert-info" v-if="datas == ''"><b>Sorry! </b>No opportunity found in this category</div>
                <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                    <jobCardVue v-for="job in jobs" :key="job" :datas="JSON.stringify(job)" router="/opportunity"
                        :hasDesc=true></jobCardVue>
                </div>
            </div>
            <div v-if="!isLoaded">
                <jobCardVueSkeleton v-for="job in 3" :key="job" :hasDesc=true></jobCardVueSkeleton>
            </div>
            <div class="flex items-center w-full md:w-auto py-4 px-2 md:px-0">
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                    stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">Recent Academia Publications</h1>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                <pubCardVue v-for="job in pubs" :key="job" :datas="JSON.stringify(job)" router="/university"
                    :hasDesc=true></pubCardVue>
            </div>
        </div>
        <pageFooterVue></pageFooterVue>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import pageFooterVue from './utils/pageFooter.vue'
import FormInput from './utils/FormInput.vue';
// import FormButton from './utils/FormButton.vue';
import UniversityCardVue from './utils/academiaCard.vue';
import jobCardVueSkeleton from './utils/skeletons/jobCard.vue';
import apiService from '../assets/api/apiService.js'
import jobCardVue from './utils/jobCard.vue';
import pubCardVue from './utils/publicationCard.vue';
export default {
    data() {
        return {
            username: 'cyemezo',
            datas: [],
            jobs: [],
                pubs: [],
            categories: [],
            activeCat: 'All',
            baseUrl: 'https://backend.innodip.rw/',
            isLoaded: false
        }
    },
    components: {
        headerNavVue,
        pageFooterVue,
        FormInput,
        // FormButton, 
        UniversityCardVue,
        jobCardVueSkeleton,
            pubCardVue,
        jobCardVue
    },
    mounted() {
        apiService.getInstitutions().then(jobsList => {
            this.datas = jobsList;
            // this.activeCat = jobsList.name
            this.isLoaded = true,
                document.title = 'Universities'
        });
            apiService.getData('getAllPublications').then(res => {
                this.pubs = res.publication,
                this.isLoaded = true
            });

    },
    methods: {
        async filterJobs(id) {
            apiService.getData('opportunities').then(res => {
                this.jobs = res
                if (id != 'All') {
                    this.activeCat = this.categories[id].name
                    const filteredJobsArray = Object.values(this.jobs).filter((value) => {
                        if (value.category && value.category.industryCategoryName === this.activeCat) {
                            return true;
                        }
                        return false;
                    });
                    this.jobs = filteredJobsArray;
                } else {
                    this.activeCat = id
                }
            })

        }
    }
}
</script>

<style scoped>
.sliderPane .carousel__slide {
    margin: 0px 10px;
    cursor: pointer;
}

.sliderPane {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.sliderPane::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.sliderPane::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.sliderPane::-webkit-scrollbar-track {
    background-color: transparent;
}</style>