<template>
    <div>
        <headerNavVue></headerNavVue>
        <div class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center">
            <div class="border border-1 mb-5 rounded-lg overflow-hidden grid w-full grid-cols-1 md:grid-cols-5">
                <div class="flex flex-col items-center justify-center col-span-3">
                    <h2 class="text-3xl my-3">Get Started</h2>
                    <img src="https://innodip.rw/img/illustrations/Interview-cuate.svg" class="px-18 homeImg">
                </div>
                <section class="bg-gray-50 dark:bg-gray-900 col-span-2 ">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <!-- <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo">
          Flowbite    
      </a> -->
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
              </h1>
              <form class="space-y-4 md:space-y-6" id="formData" @submit.prevent="sendData(this)">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="">
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" name="currentPassword" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                  </div>
                  <div class="flex items-center justify-between">
                      <a href="/reset" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                  </div>
                  <button type="submit" class="w-full text-white bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                  <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet? <a href="/signup" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                  </p>
              </form>
          </div>
      </div>
  </div>
</section>
            </div>
            </div>
            
        <pageFooterVue></pageFooterVue>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import pageFooterVue from './utils/pageFooter.vue'
import Swal from 'sweetalert2'

import apiService from '../assets/api/apiService.js'
import AWN from "awesome-notifications"

let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Login",
  position: 'top-right',
}

let signupOption =  {
  success: "You are successful loged in.",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Login In",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)
// let nextCallOptions = {}
    export default {
        data(){
            return{
                username: 'cyemezo',
                datas:[],
                images: [],
                activeCat:'',
                isLoaded:false
            }
        },
        components:{
            headerNavVue,
            pageFooterVue,
        },
        methods:{
            sendData(){
            const form = document.getElementById("formData");
            const serializedData = apiService.serializeFormData(form);
            apiService.loginUser(serializedData).then(data=>{

                if(data.message=='pending'){
                    Swal.fire({
                            title: 'Pending',
                            html: `Welcome back! Your account is currently in the review process and awaits approval from our System Administrator. Please bear with us as we work to finalize the approval. We will notify you promptly once your login is fully activated. If you have any immediate concerns, feel free to reach out to our support team. Thank you for your understanding!`,
                            icon: 'info',
                            iconColor: '#108A37',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#108A37',
                        })
                }
                
                if(data.message=='disactive'){
                    Swal.fire({
                            title: 'Disactive',
                            html: `Your account is currently deactivated. If you believe this is an error or have any questions, please contact our support team for assistance.`,
                            icon: 'info',
                            iconColor: '#108A37',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#108A37',
                        })
                }
                if(data.message=='success'){
                    if(data.type=='applicant'){
                        localStorage.removeItem('currentUser');
                        localStorage.setItem('currentUser', JSON.stringify(data.data._id));
                        localStorage.setItem('currentType', 'applicant');
                        // console.log(data.data);
                        notifier.success('You are successful loged in.', signupOption)
                        let url = localStorage.getItem('url');
                        if(url){
                            localStorage.removeItem('url');
                            window.location = url
                        }else{
                            window.location = '/seeker/'
                        }
                        // this.$router.push('/seeker/');
                    }
                    if(data.type=='industry'){
                        localStorage.removeItem('currentUser');
                        localStorage.setItem('currentUser', JSON.stringify(data.data._id));
                        localStorage.setItem('currentType', 'industry');
                        // console.log(data.data);
                        notifier.success('You are successful loged in.', signupOption)
                        window.location = '/industry/'
                        // this.$router.push('/industry/');
                    }
                    if(data.type=='institution'){
                        localStorage.removeItem('currentUser');
                        localStorage.setItem('currentUser', JSON.stringify(data.data._id));
                        localStorage.setItem('currentType', 'institution');
                        // console.log(data.data);
                        notifier.success('You are successful loged in.', signupOption)
                        // this.$router.push('/institution/');
                        window.location = '/institution/'
                    }
                    if(data.type=='admin'){
                        localStorage.removeItem('currentUser');
                        localStorage.setItem('currentUser', JSON.stringify(data.data._id));
                        localStorage.setItem('currentType', 'admin');
                        // console.log(data.data);
                        notifier.success('You are successful loged in.', signupOption)
                        // this.$router.push('/institution/');
                        window.location = '/admin/'
                    }
                }

            }
                ).catch(error=>{
                    console.log(error.response.data.message)
                    notifier.alert('Incorrect email or password', globalOptions)
                });
            }
        },
        mounted(){
            document.title='Login'
            
    },
    }
</script>

<style scoped>
.homeImg{
    height: 400px;
}
</style>