<template>
  <div class="block md:flex relative">
    <div
      class="hidden md:block w-full md:w-[20%] sticky top-0 h-[100vh] mobile-menu"
    >
      <menuNav></menuNav>
    </div>
    <div class="w-[100%] md:w-[80%]">
      <headerNavVue @userData="getUser"></headerNavVue>
      <div class="p-4 justify-center">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center w-full md:w-auto px-2 md:px-0">
            <svg
              class="w-16 h-16 text-secondary"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <h1 class="text-4xl text-gray-500">{{ app.name }} Applications</h1>
          </div>
          <div class="w-full md:w-auto px-2 md:px-0">
          </div>
        </div>
      </div>

      <div class="grid p-4">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 py-3">Names</th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in datas"
                :key="data"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ data.fname }} {{ data.lname }}
                </th>
                <td class="px-6 py-4">
                  <router-link
                    :to="`/industry/applicant/resume/${data._id}/${this.$route.params.id}`"
                    class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                  >
                    <span
                      aria-hidden
                      class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span class="relative">Details</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import pageFooterVue from "./utils/pageFooter.vue";
// import FormButton from '../utils/FormButton.vue';
// import jobCardVue from '../utils/jobCard.vue';
import apiService from "../../assets/api/apiService.js";
export default {
  name: "dashboardPage",
  data() {
    return {
      datas: [],
      user: [],
      app: [],
      activeCat: "",
      isLoaded: false,
    };
  },
  components: {
    headerNavVue,
    menuNav,
    // jobCardVue,
    pageFooterVue,
    // FormButton,
  },
  mounted() {},
  methods: {
    getUser(data) {
      this.user = JSON.parse(data);
      this.isLoaded = true;
      document.title = "Opportunities";
      apiService
        .getData("opportunity/view/applicants/" + this.$route.params.id)
        .then((res) => {
          (this.datas = res.applicants), (this.isLoaded = true);
        });
      apiService.getData("opportunity/" + this.$route.params.id).then((res) => {
        (this.app = res.data), (this.isLoaded = true);
      });
    },
  },
};
</script>

<style scoped>
.homeImg {
  height: 400px;
}
</style>
