<template>
<div class="antialiased bg-gray-50 dark:bg-gray-900">
      <menuNav></menuNav>
      <headerNavVue @userData="getUser"></headerNavVue>
      <main class="p-4 md:ml-64 h-auto pt-20">

      <div class="flex flex-col md:grid gap-4">
        <div class="w-full">
          <div class="w-full col-span-10 md:col-span-7 grid-cols-10">
                <div class="w-full flex flex-col md:flex-row card relative" v-if="isLoaded">
                    <span class="md:absolute top-3 right-3 border border-1 px-2 py-1 rounded-lg bg-gray-400 text-white">{{ jobData.category.industryCategoryName }}</span>
                    <div class="col-span-2 flex justify-start">
                        <router-link :title="'Visit '+jobData.provider.industryName" :to="'/industry/'+jobData.provider.industryId" class="hidden md:flex w-44 h-44 md:w-32 md:h-32 items-center justify-center bg-gray-200 border border-gray-300 rounded-lg overflow-hidden"><img :src="baseUrl+jobData.provider.industryLogo" alt=""></router-link>
                    </div>
                    <div class="col-span-8 pl-3 pt-0">
                        <h2 class="text-xl text-gray-500">{{ jobData.name }}</h2>
                        <h1 class="text-3xl mb-3 text-gray-700">{{ jobData.title }}</h1>
                        <div class="flex flex-col md:flex-row gap-3 text-sm">
                            <div class="flex items-center sm:justify-center md:justify-start gap-2">
                                <svg class="w-6 h-6 text-secondary"  aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span>{{ jobData.location }}</span>
                                        </div>
                                        <div class="flex items-center sm:justify-center md:justify-start gap-2">
                                            <svg class="w-6 h-6 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span>Posted: {{ jobData.publicationDate }}</span>
                                        </div>
                                        <div v-if="!jobData.isSalary" class="flex items-center sm:justify-center md:justify-start gap-2">
                                            <svg class="w-6 h-6 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span>{{ jobData.salaryRange }} {{ jobData.salaryCurrency }}</span>
                        </div>
                        <div class="flex items-center sm:justify-center md:justify-start gap-2">
                            <svg class="w-6 h-6 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span>Deadline: {{ jobData.deadline }}</span>
                    </div>
                    </div>
                </div> 
            </div> 
            </div> 
          <!-- skeleton -->
          <div class="w-full flex card" v-if="!isLoaded">
            <div class="col-span-2 flex justify-start">
              <div
                class="hidden md:flex w-44 h-44 md:w-32 md:h-32 items-center justify-center bg-gray-200 border border-gray-300 rounded-lg overflow-hidden">
              </div>
            </div>
            <div class="col-span-10 pl-3 pt-0">
              <h2 class="bg-gray-300 h-4 w-40 rounded-lg"></h2>
              <h1 class="bg-gray-300 h-4 w-58 rounded-lg mt-4"></h1>
              <div class="flex gap-3 mt-6">
                <div class="flex items-center sm:justify-center md:justify-start gap-2">
                  <svg class="w-6 h-6 bg-gray-300 rounded-lg"></svg>
                  <span class="h-4 w-24 bg-gray-300 rounded-lg"></span>
                </div>
                <div class="flex items-center sm:justify-center md:justify-start gap-2">
                  <svg class="w-6 h-6 bg-gray-300 rounded-lg"></svg>
                  <span class="h-4 w-24 bg-gray-300 rounded-lg"></span>
                </div>
                <div class="flex items-center sm:justify-center md:justify-start gap-2">
                  <svg class="w-6 h-6 bg-gray-300 rounded-lg"></svg>
                  <span class="h-4 w-24 bg-gray-300 rounded-lg"></span>
                </div>
                <div class="flex items-center sm:justify-center md:justify-start gap-2">
                  <svg class="w-6 h-6 bg-gray-300 rounded-lg"></svg>
                  <span class="h-4 w-24 bg-gray-300 rounded-lg"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col" v-if="!isLoaded">
            <!-- description -->
            <div class="mb-4" v-for="p in 3" :key="p">
              <div class="flex w-full items-center gap-4 mb-4">
                <svg class="w-8 h-8 bg-gray-300 rounded-lg"></svg>
                <span class="h-4 w-32 bg-gray-300 rounded-lg"></span>
              </div>
              <p class="h-2 w-52 bg-gray-300 rounded-lg my-2"></p>
              <p class="h-2 w-42 bg-gray-300 rounded-lg my-2"></p>
              <p class="h-2 w-32 bg-gray-300 rounded-lg my-2"></p>
              <p class="h-2 w-62 bg-gray-300 rounded-lg my-2"></p>
            </div>
          </div>
          <div class="w-full flex flex-col" v-if="isLoaded">
            <div class="mb-4">
              <div class="flex flex-col w-full items-center gap-4 mb-4">
                <div class="flex gap-3 w-full">
                  <svg class="w-10 h-10 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                    stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" stroke-linecap="round" stroke-linejoin="round">
                    </path>
                  </svg>
                  <h1 class="text-3xl text-gray-500">
                    Apply for {{ jobData.name }}
                  </h1>
                </div>
                <ol
                  class="flex flex-col md:flex-row items-start md:items-center gap-2 w-full p-3  text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 mb-4">
                  <li class="flex items-center" v-for="(tab, index) in tabs" :key="index"
                    @click="index < curTab ? activateTab(index) : ''" :class="{ 'activea': tab.active }">
                    <span class="flex items-center justify-center w-5 h-5 mr-2 text-xs border"
                      :class="{ 'activeb': tab.active }">
                      {{ tab.number }}
                    </span>
                    {{ tab.text }}
                    <svg v-if="!isLatestStep(index)" class="w-3 h-3 ml-2 sm:ml-4" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                    </svg>
                  </li>
                </ol>
                <!-- {{ formData }} -->
                <div class="w-full gap-4" v-if="curTab == 0">
                  <p class="mb-6">Personal Informations <i class="text-red-400">You can not edit personal information
                      here.</i></p>
                  <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full">
                    <div class="w-full flex flex-col">
                      <p>Names: {{ userInfo.fname }} {{ userInfo.lname }}</p>
                      <p>Phone: {{ userInfo.phone }}</p>
                      <p>Email Address: {{ userInfo.email }}</p>
                      <p>Birth Date: {{ userInfo.dob }}</p>
                      <p>National ID: {{ userInfo.nid }}</p>
                      <p>
                        Address:
                        <span v-for="add in userInfo.address" :key="add"
                          >{{ add }} -
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="flex gap-5 col-span-2 mt-6">
                    <button type="button" @click="activateTab(1)"
                      class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Next
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>

                <!-- academic info -->

                <div class="w-full gap-4" v-if="curTab == 1">
                  <p class="mb-6">Academic Informations</p>
                  <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full border p-6 rounded-lg relative mb-5"
                    v-for="acad in formData.academicProfile" :key="acad">
                    <svg @click="removeAcad(index)" class="text-red-600 h-12 w-12 absolute top-[-20px] right-6"
                      aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                    </svg>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">School
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="School" type="text" v-model="acad.skul" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Specialization
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Specialization" type="text" v-model="acad.specialization" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Degree Obtained
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Degree Obtained" type="text" v-model="acad.degree" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Graduation Year
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Graduation Year" type="text" v-model="acad.year
                          " />
                    </div>
                  </div>

                  <h2>Add New Education</h2>
                  <div
                    class="grid grid-col-1 md:grid-cols-2 bg-slate-100 gap-4 w-full border p-6 rounded-lg relative mb-5">
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">School
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="School" type="text" v-model="newAcad.skul" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Specialization
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Specialization" type="text" v-model="newAcad.specialization" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Degree Obtained
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Degree Obtained" type="text" v-model="newAcad.degree" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Graduation Year
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Graduation Year" type="text" v-model="newAcad.year
                          " />
                    </div>

                  <div class="col-span-2 mt-4">
                    <button type="button" @click="addAcad"
                      class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Add Education
                    </button>
                  </div>
                  </div>
                  <div class="flex gap-5 col-span-2 mt-6">
                    <button type="button" @click="activateTab(0)"
                      class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Back
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                    <button type="button" @click="activateTab(2)"
                      class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Next
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>

                <!-- experience info -->

                <div class="w-full gap-4" v-if="curTab == 2">
                  <p class="mb-6">Experience Informations</p>
                  <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full border p-6 rounded-lg relative mb-5"
                    v-for="acad in formData.workExperience" :key="acad">
                    <svg @click="removeExp(index)" class="text-red-600 h-12 w-12 absolute top-[-20px] right-6"
                      aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                    </svg>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Position
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Position" type="text" v-model="acad.position" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Employer
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Employer" type="text" v-model="acad.institution" />
                    </div>
                    <div class="flex items-center col-span-2">
                      <input id="default-checkbox" :checked="acad.youStillWorkHere == 1" type="checkbox"
                        @click="youStillWorkHere = !youStillWorkHere"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="default-checkbox"
                        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Current Work here</label>
                    </div>

                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">From
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="From" type="date" v-model="acad.from" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">To
                        <strong class="text-red-400" v-if="!youStillWorkHere">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        :required="!youStillWorkHere" placeholder="To" type="date" v-model="acad.till" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee Name
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee Name" type="text" v-model="acad.NameOfReferee" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee Position
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee Position" type="text" v-model="acad.PositionOfReferee
                          " />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee Phone
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee Phone" type="text" v-model="acad.PhoneOfReferee
                          " />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee email
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee email" type="email" v-model="acad.EmailOfReferee
                          " />
                    </div>
                  </div>

                  <h2>Add New Experience</h2>
                  <div class="grid grid-col-1 md:grid-cols-2 bg-slate-100 gap-4 w-full border p-6 rounded-lg relative mb-5">
                   
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Position
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Position" type="text" v-model="newExp.position" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Employer
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Employer" type="text" v-model="newExp.institution" />
                    </div>
                    <div class="flex items-center col-span-2">
                      <input id="default-checkbox"  type="checkbox"
                        @click="youStillWorkHere = !youStillWorkHere"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="default-checkbox"
                        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Current Work here</label>
                    </div>

                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">From
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="From" type="date" v-model="newExp.from" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">To
                        <strong class="text-red-400" v-if="!youStillWorkHere">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        :required="!youStillWorkHere" placeholder="To" type="date" v-model="newExp.till" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee Name
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee Name" type="text" v-model="newExp.NameOfReferee" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee Position
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee Position" type="text" v-model="newExp.PositionOfReferee
                          " />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee Phone
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee Phone" type="text" v-model="newExp.PhoneOfReferee
                          " />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Referee email
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Referee email" type="email" v-model="newExp.EmailOfReferee
                          " />
                    </div>

                    <div class="col-span-2 mt-4">
                      <button type="button" @click="addExp"
                        class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Add Experience
                      </button>
                    </div>
                    </div>
                    <div class="flex gap-5 col-span-2 mt-6">
                      <button type="button" @click="activateTab(1)"
                        class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Back
                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round" stroke-linejoin="round">
                          </path>
                        </svg>
                      </button>
                      <button type="button" @click="activateTab(3)"
                        class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Next
                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round" stroke-linejoin="round">
                          </path>
                        </svg>
                      </button>
                  </div>
                </div>

                <!-- Certificate info -->

                <div class="w-full gap-4" v-if="curTab == 3">
                  <p class="mb-6">Certificate Informations</p>
                  <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full border p-6 rounded-lg relative mb-5"
                    v-for="acad in formData.awardedCertificates" :key="acad">
                    <svg @click="removeCert(index)" class="text-red-600 h-12 w-12 absolute top-[-20px] right-6"
                      aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round">
                      </path>
                    </svg>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Title
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Title" type="text" v-model="acad.certificateTitle" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Date awarded
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Date awarded" type="date" v-model="acad.dateOfAward" />
                    </div>
                    <div class="w-full flex flex-col col-span-2">
                      <label class="text-sm mb-2">Description
                        <strong class="text-red-400">*</strong></label>
                          <ckeditor :editor="editor" v-model="acad.certificateDescription" @ready="onReady" @input="onChange"></ckeditor>

                    </div>
                  </div>

                  <h2>Add New Certificate</h2>
                  <div class="grid grid-col-1 md:grid-cols-2 bg-slate-100 gap-4 w-full border p-6 rounded-lg relative mb-5">
                    
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Title
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Title" type="text" v-model="newCert.certificateTitle" />
                    </div>
                    <div class="w-full flex flex-col">
                      <label class="text-sm mb-2">Date awarded
                        <strong class="text-red-400">*</strong></label>
                      <input
                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                        required="required" placeholder="Date awarded" type="date" v-model="newCert.dateOfAward" />
                    </div>
                    <div class="w-full flex flex-col col-span-2">
                      <label class="text-sm mb-2">Description
                        <strong class="text-red-400">*</strong></label>
                          <ckeditor :editor="editor" v-model="newCert.certificateDescription" @ready="onReady" @input="onChange"></ckeditor>

                    </div>

                  <div class="col-span-2 mt-4">
                    <button type="button" @click="addCert"
                      class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Add Certificate
                    </button>
                  </div>
                  </div>
                  <div class="flex gap-5 col-span-2 mt-6">
                    <button type="button" @click="activateTab(2)"
                      class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Back
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                    <button type="button" @click="activateTab(4)"
                      class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Next
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
                 <!-- Certificate info -->

                 <div class="w-full gap-4" v-if="curTab == 4">
                  <p class="mb-6">Cover Letter</p>
                  <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full border p-6 rounded-lg relative mb-5"
                    >
                    
                    <div class="w-full flex flex-col col-span-2">
                      <label class="text-sm mb-2">Provide your cover letter
                        <strong class="text-red-400">*</strong></label>
                          <ckeditor :editor="editor" v-model="formData.coverLetter" @ready="onReady" @input="onChange"></ckeditor>

                    </div>
                    <div class="flex items-center mb-4">
                    <input id="default-checkbox" type="checkbox" value="" @click="isCorrect=!isCorrect" v-modal="isCorrect" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I confirm that information provided is correct.</label>
                </div>
                  </div>

                  <div class="flex gap-5 col-span-2 mt-6">
                    <button type="button" @click="activateTab(3)"
                      class="text-white bg-blue-300 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Back
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                    <button type="submit" @click="sendData"
                      class="text-white bg-primary hover:bg-secondary whitespace-nowrap focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Send Application
                      <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex justify-between items-center card" v-if="!isLoaded">
            <div class="flex gap-4">
              <div class="w-9 h-9 bg-gray-400 rounded-lg"></div>
              <div class="w-9 h-9 bg-gray-400 rounded-lg"></div>
              <div class="w-9 h-9 bg-gray-400 rounded-lg"></div>
            </div>
            <div class="w-20 h-9 bg-gray-400 rounded-lg"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import headerNavVue from "../seeker/utils/headerNav.vue";
import menuNav from "../seeker/utils/menuNav.vue";
import apiService from "../../assets/api/apiService.js";
import AWN from "awesome-notifications";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import CKEditor from "@ckeditor/ckeditor5-vue"
// import $ from 'jquery'
const axios = require("axios");
let globalOptions = {
  alert: "Oops! Something got wrong",
  position: 'top-right',
};
globalOptions.labels = {
  alert: "Application",
  position: 'top-right',
};

let signupOption = {
  success: "Application",
  position: 'top-right',
};
signupOption.labels = {
  alert: "Application",
  position: 'top-right',
};

let notifier = new AWN(globalOptions);
export default {
  name: "dashboardPage",
  data() {
    return {
      datas: [],
      jobData: [],
      curSchool: [],
      isLoaded: false,
      coverLetter: "",
      jodId: "",
      curTab: 0,
      baseUrl: "https://backend.innodip.rw/",
      userData: [],
      isReg: false,
      youStillWorkHere: false,
      isCorrect:false,
      editor: ClassicEditor,
      editor1: ClassicEditor,
      appId: null,
      userInfo: [],
      formData: {
        applicant_id: "",
        opportunity_id: "",
        academicProfile: [
          {
            degreeObtained: "",
            yearOfGraduation: "",
            school: "",
            specialization: "",
            document: null,
          },
        ],
        workExperience: [],
        awardedCertificates: [],
        coverLetter:'',

      },

      tabs: [
        {
          "text": "Personal Info",
          "number": "1",
          "active": true
        },
        {
          "text": "Academic Info",
          "number": "2",
          "active": false
        },
        {
          "text": "Experience",
          "number": "3",
          "active": false
        },
        {
          "text": "Certificates",
          "number": "4",
          "active": false
        },
        {
          "text": "Cover Letter",
          "number": "5",
          "active": false
        }
      ],

      newAcad:
      {
        "degree": "",
        "year": "",
        "skul": "",
        "specialization": "",
        "document": null
      },
      newCert:
      {
        "certificateTitle": "",
        "certificateDescription": "",
        "dateOfAward": "",
        "document": null
      },
      newExp:
      {
        "position": "",
        "from": "",
        "youStillWorkHere": "",
        "till": "",
        "institution": "",
        "NameOfReferee": "",
        "PositionOfReferee": "",
        "PhoneOfReferee": "",
        "EmailOfReferee": "",
        "SkillsGained": ""
      }
    };
  },
  components: {
    headerNavVue,
    menuNav,
    ckeditor: CKEditor.component
  },
  mounted() {
    this.jobId = this.$route.params.id;
    this.formData.opportunity_id = this.$route.params.id;
    this.loadJob();
    apiService.getOpportunities().then((jobsList) => {
      (this.datas = jobsList), (this.isLoadedRelated = true);
    });
  },
  methods: {
    isLatestStep(index) {
      return index === this.tabs.length - 1;
    },
    activateTab(index) {
      // Deactivate all tabs
      this.curTab = index;
      this.tabs.forEach((tab) => {
        tab.active = false;
      });

      // Activate the clicked tab
      this.tabs[index].active = true;
    },
    addAcad() {
      if (this.newAcad.degreeObtained != '') {
        {
          this.formData.academicProfile.push({ degree: this.newAcad.degree, specialization: this.newAcad.specialization, yearOfGraduation: this.newAcad.yearOfGraduation, skul: this.newAcad.skul, year: this.newAcad.year, document: this.newAcad.document });
          this.newAcad.degree = '';
          this.newAcad.year = '';
          this.newAcad.skul = '';
          this.newAcad.specialization = '';
          this.newAcad.document = '';
        }
      }else{
        notifier.alert("Plesse fill academic Info first.", signupOption);

      }
    },
    addExp() {
      if (this.newExp.position != '') {
        {
          this.formData.workExperience.push({ position: this.newExp.position, from: this.newExp.from,
             isTillNow: this.newExp.isTillNow, till: this.newExp.ttill, institution: this.newExp.institution,
             NameOfReferee: this.newExp.NameOfReferee, PositionOfReferee: this.newExp.PositionOfReferee, 
             refereePhone: this.newExp.refereePhone,
             EmailOfReferee: this.newExp.EmailOfReferee,
             SkillsGained: this.newExp.SkillsGained
            });
          this.newExp.position = '';
          this.newExp.from = '';
          this.newExp.till = '';
          this.newExp.isTillNow = '';
          this.newExp.institution = '';
          this.newExp.NameOfReferee = '';
          this.newExp.PositionOfReferee = '';
          this.newExp.EmailOfReferee = '';
          this.newExp.refereePhone = '';
          this.newExp.SkillsGained = '';
        }
      }else{
        notifier.alert("Plesse fill experience Info first.", signupOption);

      }
    },
    addCert() {
      if (this.newCert.title != '') {
        {
          this.formData.awardedCertificates.push({ certificateTitle: this.newCert.certificateTitle, certificateDescription: this.newCert.certificateDescription, dateOfAward: this.newCert.dateOfAward, document: this.newCert.document });
          this.newCert.certificateTitle = '';
          this.newCert.certificateDescription = '';
          this.newCert.dateOfAward = '';
          this.newCert.document = '';
        }
      }else{
        notifier.alert("Plesse fill certificate Info first.", signupOption);

      }
    },
    removeAcad(index) {
      this.formData.academicProfile.splice(index, 1);
    },
    removeExp(index) {
      this.formData.workExperience.splice(index, 1);
    },
    removeCert(index) {
      this.formData.awardedCertificates.splice(index, 1);
    },
    loadJob() {
      const id = this.$route.params.id;
      apiService.getData("opportunity/" + id).then((res) => {
        this.jobData = res.data;
        this.isLoaded = true;
        (document.title = this.jobData.name),
          (this.curUrl = window.location.href);
        this.title = res.data.name;
        this.msg = res.data.name + " Innovative Digital Platform";
        this.tags = res.data.keyword.join(",");
      });
    },
    sendDataFiles() {

      const uploadForm = document.getElementById("formDataFiles");
      const formData = new FormData(uploadForm); // Use the submitted form
      let path = '';
      path = this.baseUrl + 'api/applicant/apply/files';
      axios.post(path, formData)
        .then(response => {
          console.log("Upload successful:", response.data);
          notifier.success(response.data.message, signupOption)
          this.$router.push('/seeker/applications');
        })
        .catch(error => {
          console.error("Error uploading:", error);
        });
    },
    sendData() {
      if(this.formData.coverLetter==''){
        notifier.alert("Please provide cover letter.", signupOption);
      }
      else if(!this.isCorrect){
        notifier.alert("Please confirm if information provided is correct.", signupOption);
      }else{
      const jsonData = JSON.stringify(this.formData);
      const formDataToSend = new FormData();
      formDataToSend.append(
        "data",
        new Blob([jsonData], { type: "application/json" })
      );
      axios
        .post("https://backend.innodip.rw/api/applicant/apply", this.formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // Handle the response from the server
          console.log("Response:", response.data);
          notifier.success(response.data.message, signupOption)
          window.location = '/seeker/applications';
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error:", error);
          notifier.alert("You can not apply twice.", signupOption);
        });
      }
    },

    onChange() {
      // Get the CKEditor content
      const content = this.formData.coverLetter;

      // Count the words (assuming words are separated by spaces)
      const wordCount = content.split(/\s+/).filter(word => word !== '').length;

      // Define the word limit (e.g., 500 words)
      const wordLimit = 500;

      // Check if the word limit is exceeded
      if (wordCount > wordLimit) {
        // Truncate the content to the word limit
        const truncatedContent = content
          .split(/\s+/)
          .filter(word => word !== '')
          .slice(0, wordLimit)
          .join(' ');

        // Set the CKEditor content to the truncated content
        this.formData.coverLetter = truncatedContent;
        notifier.alert("Only "+wordLimit+" words allowed.", signupOption);

        // Optionally, you can show a message to the user that they have reached the word limit
        // alert('You have reached the word limit of 500 words.');
      }
    },
    getUser(data) {
      this.userInfo = JSON.parse(data);

      this.formData.applicant_id = this.userInfo._id;
      if(this.userInfo.academicProfile){
      this.formData.academicProfile = this.userInfo.academicProfile
      }
      if(this.userInfo.workExperience){
      this.formData.workExperience = this.userInfo.workExperience
      }
      if(this.userInfo.awardedCertificates){
      this.formData.awardedCertificates = this.userInfo.awardedCertificates
      }
    },
    applyTojob() {
      // Define the data you want to send in the request body
      const requestData = {
        applicant_id: this.userData._id,
        opportunity_id: this.$route.params.id,
      };

      // Send a POST request with the data in the body
      axios
        .post("https://backend.innodip.rw/api/applicant/apply", requestData)
        .then((response) => {
          // Handle the response here
          console.log("Response:", response.data);
          notifier.success("Application Sent.", signupOption);
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
          notifier.alert("You have already applied.", signupOption);
        });
    },
  },
  watch: {
    $route(to) {
      this.isLoaded = false;
      this.jobId = to.params.id;
      this.loadJob();
    },
  },
};
</script>

<style scoped>
.homeImg {
  height: 400px;
}
</style>