<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <menuNav></menuNav>
        <headerNavVue @userData="getUser"></headerNavVue>
        <main class="p-4 md:ml-64 h-auto pt-20">
            <div class="p-4 justify-center">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                        <svg class="w-12 h-12 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                            stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <h1
                            class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">
                            My Applications<span class="text-blue-600 dark:text-blue-500"> Research</span>.</h1>
                    </div>
                    <div class="w-full md:w-auto px-2 md:px-0">
                        <FormInput placeholder="Search..." label="" inputType="text" small="true"></FormInput>
                    </div>
                </div>
            </div>

            <div class="grid p-4">
                <div v-if="isLoaded">
                    <div class="card grid grid-cols-1 md:grid-cols-10 relative" v-for="data in datas" :key="data">
                        <div class="md:absolute top-3 right-3">
                            <span class=" border border-1 px-2 py-1 rounded-lg bg-secondary text-white">
                                Application Received
                            </span>
                            <!-- <span class="border border-1 px-2 py-1 rounded-lg bg-gray-400 text-white">{{
                                data.applicantName }}</span> -->
                        </div>
                        <div class="w-full col-span-8 p-3 pt-0">
                            <h1 class="text-1xl text-gray-500">{{ data.research_call.title }}</h1>
                            <h2 class="text-xl mb-3 text-gray-700">Deadline: {{ data.research_call.applicationDeadline }}</h2>
                            
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import FormInput from '../utils/FormInput.vue';
import apiService from '../../assets/api/apiService.js'
export default {
    name: 'dashboardPage',
    data() {
        return {
            datas: [],
            activeCat: '',
            isLoaded: false
        }
    },
    components: {
        headerNavVue,
        menuNav,
        FormInput,
    },
    mounted() {

    },
    methods: {
        getUser(data) {
            this.user = JSON.parse(data);
            this.isLoaded = true;
            document.title = "Research Applications";
            apiService.getData('research-call/my/applications/' + this.user._id).then(res => {
                this.datas = res.data,
                    this.isLoaded = true
            });
        }

    }
}


</script>

<style scoped>
.homeImg {
    height: 400px;
}
</style>