<template>
  <div class="grid grid-cols-10">
    <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
      <menuNav></menuNav>
    </div>
    <div class="col-span-12 md:col-span-8">
      <headerNavVue @userData="getUser"></headerNavVue>
      <div class="p-4 justify-center">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center w-full md:w-auto px-2 md:px-0">
            <svg
              class="w-16 h-16 text-secondary"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <h1 class="text-4xl text-gray-500">Add Article</h1>
          </div>
        </div>
      </div>

      <div class="grid mx-auto">
        <div class="container mx-auto px-4 mb-10 sm:px-8">
          <form id="formData" @submit.prevent="sendData">
            <input type="hidden" v-model="datas._id" name="uploader_id" />
            <div class="flex flex-col md:flex-row gap-4 w-full">
              <div class="w-full md:w-1/2">
                <div class="grid grid-col-1 md:grid-cols-2 gap-4 w-full">
                  <div class="col-span-2">
                    <FormInput
                      placeholder="Article Title"
                      label="Article Title"
                      inputType="text"
                      required="true"
                      small="false"
                      name="title"
                    ></FormInput>
                  </div>
                  <div class="col-span-2">
                    <FormInput
                      placeholder="Author"
                      label="Author"
                      inputType="text"
                      required="true"
                      small="false"
                      name="authors[]"
                    ></FormInput>
                  </div>
                  <div class="col-span-2">
                    <FormInput
                      placeholder="Article Year"
                      label="Article Year"
                      inputType="text"
                      required="true"
                      small="false"
                      name="published_year"
                    ></FormInput>
                  </div>
                  <div class="col-span-2">
                    <FormInput
                      placeholder="Article Link"
                      label="Article Link"
                      inputType="text"
                      required="true"
                      small="false"
                      name="url"
                    ></FormInput>
                  </div>
                  <div class="col-span-2">
                    <label for="" class="mb-3">Abstract</label>
                    <textarea
                      name="abstract"
                      placeholder="Abstract"
                      class="w-full h-40 px-3 py-2 border rounded-md shadow-sm focus:ring focus:ring-indigo-400 focus:ring-opacity-50"
                    ></textarea>
                  </div>
                  <div class="flex gap-4 col-span-2">
                    <button  v-if="!canSend"
                      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Save Article
                    </button>
                    <div v-if="canSend">Saving...</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import pageFooterVue from "../seeker/utils/pageFooter.vue";
import FormInput from "../utils/FormInput.vue";
import AWN from "awesome-notifications";
import $ from "jquery";
import axios from "axios";

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: "top-right",
};
globalOptions.labels = {
  alert: "Profile",
  position: "top-right",
};

let signupOption = {
  success: "Profile",
  position: "top-right",
};
signupOption.labels = {
  alert: "Profile",
  position: "top-right",
};

let notifier = new AWN(globalOptions);

export default {
  name: "dashboardPage",
  data() {
    return {
      datas: [],
      isLoaded: false,
      showModal: false,
      categories: [],
      catHolder: [],
      subCategories: [],
      canSend: false
    };
  },
  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,
    FormInput,
  },
  mounted() {
    
  },
  methods: {
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    setSubCat(e) {
      let index = e.target.selectedIndex;
      this.catHolder = this.categories;
      this.subCategories = this.catHolder[index - 1];
      console.log(this.catHolder);
    },
    sendData() {
        this.canSend=true

        const form = document.getElementById("formData");
        const formData = new FormData(form); // Use the submitted form

        let path = "https://backend.innodip.rw/api/add-research-article";
        // const endpoint = base+'api/applicants/'+id+'/insert/academicProfile';
        axios
          .post(path, formData)
          .then((response) => {
            this.canSend = false;
            $("form").trigger("reset");
            console.log("Upload successful:", response.data);
            notifier.success(response.data.message, signupOption);
          })
          .catch((error) => {
            console.error("Error uploading:", error);
            notifier.alert(
              error.response.data.errors.attachment[0],
              signupOption
            );
          });
    },
    getUser(data) {
      this.datas = JSON.parse(data);
      this.selectedFilePreview = this.baseUrl + this.datas.picture;
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped></style>
