<template>
    <div class="text-center flex flex-col items-center justify-between text-gray-500 dark:text-gray-400 border rounded-lg p-2">
              <div class="flex h-20 w-20 object-cover p-2 rounded-lg ml-4">
                  <img class="h-full w-full object-cover rounded-lg"  :src="baseUrl+job.logo" alt=".">
              </div>
              <h3 class="mb-1 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">
                  <span>{{ (job.name!=''?job.name:job.abbreviation) }}</span>
              </h3>
              <p>{{ job.location }}</p>
              <ul class="flex justify-center mt-4 space-x-4">
                    <a
                        :href="`${router + '/' + job._id}`" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Visit
                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </a>
              </ul>
          </div>
</template>

<script>
import apiService from "../../assets/api/apiService.js";
    export default {
        name: 'JobCard',
        props:{
            datas:String,
            hasDesc:Boolean,
            router: String
        },
        data(){
            return{
                baseUrl: 'https://backend.innodip.rw/',
                job: []
            }
        },
        components:{
        },
        methods:{
            
        fewWords(words, num) {
        return apiService.displayWords(words, num);
        },
        },
        created(){
            this.job = JSON.parse(this.datas)
        }
    }
</script>

<style scoped>
</style>