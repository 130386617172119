<template>
  <div class="grid grid-cols-10">
    <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
      <menuNav></menuNav>
    </div>
    <div class="col-span-12 md:col-span-8">
      <headerNavVue></headerNavVue>
      <section class="bg-gray-50 dark:bg-gray-900 p-4">
        <div class="flex flex-wrap -mx-3 mb-6 w-[500px]">
          <div class="w-full px-3">
            <div class="inline-flex rounded-md shadow-sm mb-4">
              <a
                href="/admin/industries/pending"
                :aria-current="status == 'pending'"
                :class="{ 'text-blue-700': status == 'pending' }"
                class="px-4 py-2 text-sm font-medium bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
              >
                Pending
              </a>
              <a
                href="/admin/industries/approved"
                :aria-current="status == 'approved'"
                :class="{ 'text-blue-700': status == 'approved' }"
                class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
              >
                Approved
              </a>
              <a
                href="/admin/industries/rejected"
                :aria-current="status == 'rejected'"
                :class="{ 'text-blue-700': status == 'rejected' }"
                class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
              >
                Rejected
              </a>
            </div>

            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              {{ status }} Industries
            </label>
          </div>
        </div>
        <div class="mx-auto max-w-screen-xl">
          <!-- Start coding here -->
          <div
            class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden"
          >
            <div class="overflow-x-auto">
              <div class="relative overflow-x-auto">
                <table
                  class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <thead
                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                  >
                    <tr>
                      <th scope="col" class="px-6 py-3">Names</th>
                      <th scope="col" class="px-6 py-3">Email</th>
                      <th scope="col" class="px-6 py-3">TIN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="app in datas" :key="app"
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {{ app.name }}
                      </th>
                      <td class="px-6 py-4">
                        {{ app.email }}
                      </td>
                      <td class="px-6 py-4">
                        {{ app.tin }}
                      </td>
                      <td class="px-6 py-4">
                        <router-link :to="`/admin/reset/industry/${app._id}`" v-if="status=='approved'"
                         
                          class="bg-green-600 whitespace-nowrap text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded "
                        >
                          Reset Password
                        </router-link>
                        <button v-if="status!='approved'"
                          type="button"
                          @click="approve(app._id)"
                          class="bg-green-600 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded "
                        >
                          Approve
                        </button>
                        <button v-if="status!='rejected'"
                          type="button"
                          @click="reject(app._id)"
                          class="bg-red-600 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <pageFooterVue></pageFooterVue>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import pageFooterVue from "../seeker/utils/pageFooter.vue";
import apiService from "../../assets/api/apiService.js";

import AWN from "awesome-notifications";

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: "top-right",
};
globalOptions.labels = {
  alert: "Academia",
  position: "top-right",
};

let signupOption = {
  success: "Academia",
  position: "top-right",
};
signupOption.labels = {
  alert: "Academia",
  position: "top-right",
};

let notifier = new AWN(globalOptions);
export default {
  name: "dashboardPage",
  data() {
    return {
      datas: [],
      status: "",
    };
  },
  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,
  },
  mounted() {
    this.status = this.$route.params.type;
    console.log(this.$route.params.type);
    this.getData()
  },
  methods: {
    approve(id) {
      let conf = confirm("Do you want to approve this industry");
      if (conf) {
        apiService.updateData("admin/approve-industry/" + id).then((res) => {
          (this.datas = res),
            notifier.success("Academia approved", globalOptions);
        this.getData()
        });
      }
    },
    reject(id) {
      let conf = confirm("Do you want to reject this academia");
      if (conf) {
        apiService.updateData("admin/reject-industry/" + id).then((res) => {
          (this.datas = res),
            notifier.success("Academia rejected", globalOptions);
        this.getData()
        });
      }
    },
        getData(){
          apiService
      .getData("admin/all-" + this.status + "/industries/")
      .then((res) => {
        (this.datas = res), (this.isLoaded = true);
      });
  },
  },
};
</script>

<style scoped></style>
