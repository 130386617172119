<template>
  <div class="grid grid-cols-10">
    <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
      <menuNav></menuNav>
    </div>
    <div class="col-span-12 md:col-span-8">
      <headerNavVue @userData="getUser"></headerNavVue>
      <div class="p-4 justify-center">
        <div
          class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
        >
          <div class="flex items-center w-full md:w-auto px-2 md:px-0">
            <svg
              class="w-16 h-16 text-secondary"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <h1 class="text-4xl text-gray-500">
              Curriculum Call For Application
            </h1>
          </div>
                <div class="flex gap-3">
                    <router-link :to="`/institution/curriculum/courses/${this.$route.params.id}`" class="btn-secondary" type="button">
                    Back
                    </router-link>
                <router-link :to="`/institution/curriculum/courses/${this.$route.params.id}`" class="btn-primary" type="button">
                    Applicants
                    </router-link>
                </div>

        </div>
      </div>

      <div class="grid mx-auto">
        <div class="container mx-auto px-4 mb-10 sm:px-8">
          <form id="formData" @submit.prevent="sendData">
            <div class="flex flex-col md:flex-row gap-4 w-full">
              <div class="w-full grid grid-cols-2 gap-4">
                <div class="w-full flex flex-col col-span-2">
                  <label class="text-sm mb-2"
                    >Deadline <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Deadline"
                    type="date"
                    v-model="Data.deadline"
                  />
                </div>
              </div>
            </div>
            <div class="w-full grid grid-cols-2 gap-4" v-if="addQuestion">
                <h1 class="text-2xl my-4">Add Application Questionaire</h1>
                <div class="w-full flex flex-col col-span-2">
                  <label class="text-sm mb-2"
                    >Title <strong class="text-red-400">*</strong></label
                  >
                  <input
                    class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                    required="required"
                    placeholder="Title"
                    type="text"
                    v-model="questions.title"
                  />
                </div>
                <div
                  class="w-full flex flex-col col-span-2"
                  v-for="(que, index) in questions.questions"
                  :key="index"
                >
                  <div class="flex justify-between items-center w-full">
                    <label class="text-sm mb-2"
                      >Question {{ i = index+1 }}
                      <strong class="text-red-400">*</strong>
                    </label>
                    <svg
                      @click="removePre(index)"
                      class="text-red-600 h-12 w-12"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <textarea
                    v-model="que.text"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                  ></textarea>

<label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label>
<div class="flex items-center mb-4">
    <input checked id="default-radio-1" @click="changeType(index,'Text')" type="radio" value="" :name="`defaultRadio${index}`" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label for="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Text</label>
</div>
<div class="flex items-center">
    <input id="default-radio-2" @click="changeType(index,'Rating')"  type="radio" value="" :name="`defaultRadio${index}`" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label for="default-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Rating</label>
</div>
<div v-if="que.type=='Rating'">
    <div
                      class="w-full flex flex-col col-span-2"
                      v-for="(opt, ind) in que.options"
                      :key="ind"
                    >
                      <div class="flex justify-between items-center w-full">
                        <label class="text-sm mb-2"
                          >Options
                          <strong class="text-red-400">*</strong>
                        </label>
    
                        <svg
                          @click="removeLern(index,ind)"
                          class="text-red-600 h-12 w-12"
                          aria-hidden="true"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <input
                        v-model="que.options[ind]"
                        rows="4"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Write your option here..."
                      >
                    </div>
    
                    <div class="col-span-2 mt-4">
                      <button
                        type="button"
                        @click="addLern(index)"
                        class="text-white bg-yellow-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Add Option
                      </button>
                    </div>
                    </div>
</div>
<hr class="my-4">

                <div class="col-span-2 mt-4">
                  <button
                    type="button"
                    @click="addPre()"
                    class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Add Question
                  </button>
                </div>
            </div>
            <button
              v-if="!isEdit"
              type="button"
              @click="sendData"
              class="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm whitespace-nowrap px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Save & Add Questionaire
              <svg
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg></button
            >
            <button
              v-if="isEdit && !addQuestion"
              type="button"
              @click="addQuestion = !addQuestion"
              class="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm whitespace-nowrap px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Add Questionaire
              <svg
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button><button
              v-if="isEdit && addQuestion"
              type="button"
              @click="sendQuestionare"
              class="text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm whitespace-nowrap px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Save Questionaire
              <svg
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
      <pageFooterVue></pageFooterVue>
    </div>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import menuNav from "./utils/menuNav.vue";
import pageFooterVue from "../seeker/utils/pageFooter.vue";
import apiService from "../../assets/api/apiService.js";
import AWN from "awesome-notifications";
// import $ from 'jquery'
import axios from "axios";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CKEditor from "@ckeditor/ckeditor5-vue";

let globalOptions = {
  alert: "Oops! Something got wrong",
  position: 'top-right',
};
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
};

let signupOption = {
  success: "Profile",
  position: 'top-right',
};
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
};

let notifier = new AWN(globalOptions);

export default {
  name: "dashboardPage",
  data() {
    return {
      datas: [],
      isLoaded: false,
      showModal: false,
      categories: [],
      curTab: 0,
      isEdit: false,
      addQuestion: false,
      tabs: [
        {
          text: "Details",
          number: "1",
          active: true,
        },
        {
          text: "Prerequisites",
          number: "2",
          active: false,
        },
        {
          text: "Learning Outcomes",
          number: "3",
          active: false,
        },
        {
          text: "References And Materials",
          number: "4",
          active: false,
        },
      ],
      newLern: '',
      newPre:
    {
      "questionId": "",
      "text": "",
      "type": "",
      "options": []
    },
      selectedType:'',
      Data: {
        curriculumId: "",
        deadline: "",
      },
      questions:{
  "curriculumId": "6537b5786f2500007f003c42",
  "title": "kSample Questionnaire",
  "questions": [
    {
      "questionId": "1",
      "text": "What is your name?",
      "type": "text",
      "options": []
    },
    {
      "questionId": "2",
      "text": "How would you rate the course?",
      "type": "rating",
      "options": ["1", "2", "3", "4", "5"]
    }
  ]
}
    };
  },

  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,
  },
  mounted() {
    this.Data.curriculumId = this.$route.params.id;
    this.questions.curriculumId = this.$route.params.id;
    if (this.Data.curriculumId != "") {
      apiService
        .getData(
          "curriculum-review-call/by-curriculum-id/" + this.Data.curriculumId
        )
        .then((response) => {
          this.Data = response.reviewCall;
          this.isEdit = true;
          this.canEdit = false;
        });
    }
    document.title = "Curriculum Call for Application";
  },
  methods: {
    changeType(index,val) {
      this.questions.questions[index].type = val;
      if(val=='Text'){
      this.questions.questions[index].options = [];

      }
    },
    addPre() {
      this.questions.questions.push(this.newPre);
      this.newLern = "";
    },
    removePre(index) {
      this.questions.questions.splice(index, 1);
    },
    addLern(index) {
      this.questions.questions[index].options.push(this.newLern);
      this.newLern = "";
    },
    removeLern(index,ind) {
      this.questions.questions[index].options.splice(ind, 1);
    },
    sendData() {
      axios
        .post(`https://backend.innodip.rw/api/call-for-curriculum-review`, this.Data)
        .then((response) => {
          console.log("Response:", response.data);
          notifier.success(
            "Curriculum call for application Saved.",
            signupOption
          );
          this.addQuestion = true;
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error:", error);
          notifier.alert(
            "Curriculum call for application not added some filelds are not filled.",
            signupOption
          );
        });
    },
    sendQuestionare() {
      axios
        .post(`https://backend.innodip.rw/api/questionnaire/add`, this.questions)
        .then((response) => {
          console.log("Response:", response.data);
          notifier.success(
            "Curriculum Questionare Saved.",
            signupOption
          );
          this.addQuestion = true;
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error:", error);
          notifier.alert(
            "Curriculum Questionare not added some filelds are not filled.",
            signupOption
          );
        });
    },
    getUser(data) {
      this.datas = JSON.parse(data);
    },
  },
};
</script>

<style scoped></style>
