<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <menuNav></menuNav>
        <headerNavVue @userData="getUser"></headerNavVue>
        <main class="p-4 md:ml-64 h-auto pt-20">
            <div class="justify-center content">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full  justify-between px-2 md:px-0">
                            <div class="flex items-center gap-4">
                                <svg class="w-14 h-14 text-secondary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 21 19">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M11 4C5.5-1.5-1.5 5.5 4 11l7 7 7-7c5.458-5.458-1.542-12.458-7-7Z" />
                                </svg>
                                <h1 class="text-4xl text-gray-500">My Interests</h1>
                            </div>
                            <div>
                                <a href="/seeker/interests/add"
                                    class="text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Add Interest
                                </a>
                            </div>
                    </div>
                </div>
            </div>
            <div class="grid p-4">
                <div v-if="isLoaded">
                        <ul class="grid w-full gap-6 md:grid-cols-3">
                            <li v-for="(cat,index) in interestData.interests" :key="index">
                                <input value=""
                                    class="hidden peer inputInterest">
                                <label :for="cat"
                                    class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 relative">
                                    <svg  @click="addInterest(index,cat)" class="absolute top-3 right-3 z-10 h-6 text-gray-800 hover:text-red-600  cursor-pointer dark:text-white"
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                        viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                                    </svg>
                                    <div class="block">
                                        <div class="w-full text-lg font-semibold">{{ cat }}</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                  </div>
            </div>
        </main>
    </div>
</template>

<script>
import headerNavVue from '../seeker/utils/headerNav.vue'
import menuNav from '../seeker/utils/menuNav.vue'
import apiService from '../../assets/api/apiService.js'
import axios from 'axios';
import AWN from "awesome-notifications"

let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Interest",
  position: 'top-right',
}

let signupOption =  {
  success: "Interest",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Interest",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

export default {
    name: 'profilePage',
    data() {
        return {
            datas: [],
            userId: '',
            activeCat: '',
            isLoaded: false,
            isActive: false,
            curValue: '',
            interestData: {
                interests:
                    [
                    ]
            }
        }
    },
    components: {
        headerNavVue,
        menuNav,
    },
    mounted() {
        this.userId = JSON.parse(localStorage.getItem('currentUser'));
        apiService.getData('view/my/interests/'+ this.userId).then(res => {
            this.interestData.interests = res.interests,
                this.isLoaded = true
        });
    },
    methods: {
        toggleClass() {
            this.isActive = !this.isActive;
        },
        addInterest(index,cat) {

this.interestData.interests.splice(index, 1);

         
                axios.post("https://backend.innodip.rw/api/add/interest/" + this.userId, this.interestData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then((response) => {
                        console.log("Response:", response.data);
                        notifier.success(cat+' interest removes',globalOptions)
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            
            

        }

    }
}
</script>

<style scoped>.image {
    aspect-ratio: 1/1;
}</style>