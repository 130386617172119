<template>

<div class="antialiased bg-gray-50 dark:bg-gray-900">
    <menuNav></menuNav>
    <headerNavVue @userData="getUser"></headerNavVue>
    <main class="p-4 md:ml-64 h-auto pt-20">
        <div class="justify-center content">
          <div
            class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4"
          >
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
              <svg
                class="w-16 h-16 text-secondary"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h1 class="text-4xl text-gray-500">My Profile</h1>
            </div>
            <div class="w-full md:w-auto px-2 md:px-0"></div>
          </div>
        </div>
        <div class="grid p-4">
          <div>
            <form
              id="formData"
              @submit.prevent="sendData"
              enctype="multipart/form-data"
              
            >
              <div class="grid grid-cols-8 w-full">
                <div class="col-span-8 mb-4 md:col-span-2 pr-0 md:pr-8">
                  <input type="file" name="photo" id="photo" class="hidden" @change="handleFileChange" />
                  <div
                    class="w-full image border border-gray-400 rounded-lg overflow-hidden flex object-fit relative"
                  >
                    <img
                      src="@/assets/images/photo.jpg"
                      alt=""
                      class="object-cover w-full h-full absolute z-0"
                    />
                    <img :src="datas.picture?selectedFilePreview:'https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?s=612x612&w=0&k=20&c=gkvLDCgsHH-8HeQe7JsjhlOY6vRBJk_sKW9lyaLgmLo='"
                      alt=""
                      class="object-cover w-full h-full absolute z-1  bg-white"
                    />
                    <label
                      for="photo"
                      type="button"
                      class="absolute bottom-5 left-1/2 translate-x-[-50%] bg-secondary hover:bg-primary text-white rounded-3xl py-1 px-3 whitespace-nowrap"
                      >Change Photo</label
                    >
                  </div>
                </div>
                <div class="col-span-8 md:col-span-6 w-full">
                  <span v-if="isError">sssss</span>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                    <input type="hidden" name="" v-model="datas.isLocalSignUp">
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Firstname</label>
                      <input type="text" placeholder="Firstname" v-model="datas.fname" name="f_name" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span class="text-red-500 text-xs">{{ datas.fname==''?'This field is required':'' }}</span>
                  </div>
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lastname</label>
                      <input type="text" placeholder="Lastname" v-model="datas.lname" name="l_name" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  </div>
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telphone</label>
                      <input type="text" placeholder="Telphone" v-model="datas.phone" name="phone_nmbr" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span class="text-red-500 text-xs">{{ datas.phone==''?'This field is required':(!/^\d+$/.test(datas.phone)?'Please type only numbers':'') }}</span>
                  </div>
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address</label>
                      <input type="email" placeholder="Email Address" v-model="datas.email" name="email_addr" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span class="text-red-500 text-xs">{{ datas.email==''?'This field is required':(!/^\S+@\S+\.\S+$/.test(datas.email)?'Invalid email address':'') }}</span>
                      <!-- <span v-if="validateEmail(datas.email)" class="text-red-500 text-xs">{{validateEmail(datas.email)}}</span> -->
                  </div>
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date of Birth </label>
                      <input type="date" placeholder="Date of Birth " v-model="datas.dod" name="d_o_b" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span class="text-red-500 text-xs">{{ datas.email==''?'This field is required':(!/^\S+@\S+\.\S+$/.test(datas.email)?'Invalid email address':'') }}</span>
                      <!-- <span v-if="validatePastDate(datas.dod)" class="text-red-500 text-xs">{{validatePastDate(datas.dod)}}</span> -->
                  </div>
                    <div v-if="datas.isLocalSignUp==1">
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">National Identification Number</label>
                      <input type="text" placeholder="National Identification Number" v-model="datas.nid" name="aplnt_nid" maxlength="16" minlength="16" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span class="text-red-500 text-xs">{{ datas.nid==''?'This field is required':(!/^\d+$/.test(datas.nid)?'Please type only numbers':'') }}</span>
                      <!-- <span v-if="validateLengthAndDigits(datas.nid,16)" class="text-red-500 text-xs">{{validateLengthAndDigits(datas.nid,16)}}</span> -->
                  </div>
                
                  </div>
                    
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                    <FormInput
                      placeholder="Address"
                      label="Address"
                      inputType="text"
                      required="true"
                      small="false"
                      name="aplnt_address"
                      :value="formattedAddress"
                    ></FormInput>
                  </div>
                  <div class="grid grid-cols-1 gap-4 w-full">
                    
    <div class="w-full flex flex-col">
        <label class="text-sm mb-2">Biography</label>
        <textarea rows="5" class="hidden w-full appearance-none bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary" placeholder="Biography" name="biograph" v-model="datas.headline"></textarea>
    </div>
    <ckeditor :editor="editor" v-model="datas.headline" @ready="onReady" @input="onChange"></ckeditor>
                  </div>
                </div>
              </div>
              <!-- <button type="button" @click="notify('Salama')">Test</button> -->
              <div class="flex items-center justify-between" v-if="isLoaded">
                <FormButton
                  type="submit"
                  label="Save Profile"
                  bstyle="primary"
                ></FormButton>
                <a href="/seeker/profile/education"
                  ><FormButton
                    type="button"
                    label="Education &rarr;"
                    bstyle="normal"
                  ></FormButton
                ></a>
              </div>
            </form>
          </div>
        </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import headerNavVue from "../seeker/utils/headerNav.vue";
import menuNav from "../seeker/utils/menuNav.vue";
import FormInput from "../utils/FormInput.vue";
import FormButton from "../utils/FormButton.vue";
import FormSelect from '../utils/FormSelect.vue';
import apiService from "../../assets/api/apiService.js";
import axios from 'axios';
import AWN from "awesome-notifications"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue"
  
let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',
}
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
}

let signupOption =  {
  success: "Profile",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

export default {
  name: "profilePage",
  data() {
    return {
      datas: [],
      userId: "",
      isLoaded: false,
      selectedFile: null,
      phoneNumber:'',
      baseUrl : 'https://backend.innodip.rw/',
      selectedFilePreview:null,
      countries:[],
      address:'',
      editor: ClassicEditor,
      editorData: "",
      isError:false

    };
  },
  components: {
    headerNavVue,
    menuNav,
    FormInput,
    FormButton,
    FormSelect,
    ckeditor: CKEditor.component
  },
  mounted() {
      // apiService.getData('all_countries').then((response) => {
      //       this.countries = response;
      // });
  },
  methods: {
    
    onReady( editor ) {
        console.log( "CKEditor5 Vue Component is ready to use!", editor );
      },
      onChange( data ) {
        console.log( data );
      },
    async handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      // this.selectedFilePreview = URL.createObjectURL(this.selectedFile); // Create data URL for preview
      const formData = new FormData();
      formData.append('picture', this.selectedFile);
      if(this.selectedFile!=null){
      try {
        const response = await axios.post('https://backend.innodip.rw/api/applicant/upload_picture/'+this.datas._id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.selectedFilePreview = this.baseUrl+response.data.data.picture

        notifier.success("Profile picture updated", globalOptions)

      } catch (error) {
        console.error('Error uploading file:', error),
        notifier.alert("Picture not uploaded", globalOptions)

      }

    }
    },
    sendData() {
      if(this.isError){
        notifier.alert('Please provide valid inputs', signupOption)
      }else{
      const form = document.getElementById("formData");
      const serializedData = apiService.serializeFormData(form);
      console.log(serializedData);
      apiService.handleForm('applicant/update/one/'+this.datas._id,serializedData).then(
        notifier.success('Profile Updated.', signupOption),
        );

      }
    },
    getUser(data){
      this.datas = JSON.parse(data);
      if(this.datas.picture){
      this.selectedFilePreview = this.baseUrl+this.datas.picture
      }
      this.isLoaded = true;
      document.title=this.datas.fname+" Personal Information";
      
      if(!this.datas.dod){
        this.datas.dod = ''
      }
      if(this.datas.nid){
      this.datas.dod = apiService.calendarDate(this.datas.dod)
      }
      const ad = this.datas.address
      // this.datas.address = Object.values(ad).join(', ')
      this.phoneNumber = this.datas.phone
      if(!this.datas.lname){
        this.datas.lname = ''
      }
      if(!this.datas.phone){
        this.datas.phone = ''
      }
      if(!this.datas.nid){
        this.datas.nid = ''
      }
      if(!this.datas.headline){
        this.datas.headline = ''
      }
      if(!this.datas.address){
        this.datas.address = ''
      }
    },
    settingCitizen(event){
        if(event.target.value!=''){
        const val = event.target.value=='1'?true:false
        this.citizen = val
        this.countryCode='+'+event.target.value
        }
    },
    setNewVal(event){
        if(event.target.value!=''){
        this.phoneNumber=this.countryCode+event.target.value
        }
    },
  validatePhone(phone) {
      if(phone == ''){
      this.isError = true
        return 'This field is required'
      }
      if(!/^\d+$/.test(phone)){
      this.isError = true
        return 'Please enter only numbers'
      }
    },
  validateEmail(email) {
      if(email == ''){
      this.isError = true
        return 'This field is required'
      }
      if(!/^\S+@\S+\.\S+$/.test(email)){
      this.isError = true
        return 'Please enter a valid email address'
      }
    },
  validateLetter(input) {
    if (input === '') {
      this.isError = true
      return 'This field is required';
    }
    // Validate that the input contains only letters
    if (!/^[A-Za-z]+$/.test(input)) {
      this.isError = true
      return 'Please enter only letters';
    }
      this.isError = false
    return null; // Return null when there is no error
  },
  validatePastDate(inputDate) {
    if (inputDate === '') {
      this.isError = true
      return 'This field is required';
    }
    const currentDate = new Date();
    const inputDateObj = new Date(inputDate);

    // Compare dates
    if (inputDateObj > currentDate) {
      this.isError = true
      return 'Please enter a past date';
    }

      this.isError = false
    return null; // Return null when there is no error
  },
  validateLengthAndDigits(input, length) {
    if (input === '') {
      this.isError = true
      return 'This field is required';
    }
    if (!/^\d+$/.test(input)) {
      this.isError = true
      return 'Please enter only digits';
    }
    if (input.length !== length) {
      this.isError = true
      return `Please enter exactly ${length} digits`;
    }
      this.isError = false
    return null; // Return null when there is no error
  },
  },
  computed: {
    formattedAddress() {
      if(this.datas.address){
      const nonEmptyValues = Object.values(this.datas.address).filter(value => value !== "");
      return nonEmptyValues.join(' ');
    }else{
    return '';
  }
  }
  }
};

</script>

<style scoped>
.image {
  aspect-ratio: 1/1;
}
</style>