<script setup>
import { reactive,computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import {required,minLength,sameAs,} from '@vuelidate/validators';
import axios from 'axios';
import apiService from '../../assets/api/apiService.js'



import AWN from "awesome-notifications"
let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Password",
  position: 'top-right',
}

let signupOption =  {
  success: "Your account is successful created.",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Password",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

const formData = reactive({
    oldpassword:'',
    password:'',
    ConfirmPassword:''

})
const rules = computed(() => {
    return {
        oldpassword:{required},
        password:{required,minLength:minLength(8)},
        ConfirmPassword:{required,sameAs:sameAs(formData.password)}
    }
})
const $v = useVuelidate(rules, formData);
const submitForm = async () => {
    const result = await $v.value.$validate();
    if(result){ 
        sendData()
    }
}
async function  sendData (){
        let userId = JSON.parse(localStorage.getItem('currentUser'));
        const form = document.getElementById("formData");
        const serializedData = apiService.serializeFormData(form);
        try {
        const response = await axios.post('https://innodip.rw/api/institution/change-password/'+userId, serializedData, {
          
        });

        notifier.success("Password Changed", globalOptions)

        }catch(error){
                notifier.alert(error.response.data.error, globalOptions)
            }
        }
</script>
<template>
  <div>
    <div class="block md:flex relative">
      <div
        class="hidden md:block w-full md:w-[20%] sticky top-0 h-[100vh] mobile-menu"
      >
        <menuNav></menuNav>
      </div>
      <div class="w-[100%] md:w-[80%]">
        <headerNavVue @userData="getUser"></headerNavVue>
        <div class="w-full bg-gray-700 relative h-36 md:h-48 flex border-b border-gray-300">
        <!-- <span class="absolute top-3 right-3 border border-1 px-2 py-1 rounded-lg bg-gray-400 text-white">+</span> -->
        <!-- <h1 class="hidden col-span-2 md:flex absolute left-1/2 top-12 md:top-20 text-3xl translate-x-[-50%] p-1 opacity-[.8] rounded-lg bg-gray-600 text-white z-10 text-center">{{ datas.name }}</h1> -->
        
        <label  v-if="canEdit"
                      for="banner"
                      type="button"
                      class="absolute bg-opacity-[.8] bottom-2 md:bottom-5 right-2 md:right-auto md:left-1/2 md:translate-x-[-50%] bg-secondary hover:bg-primary text-white rounded-3xl py-1 px-3 whitespace-nowrap"
                      >Change Banner</label
                    >
        <img v-if="selectedFile || selectedBannerPreview" :src="selectedBannerPreview" alt="" class="w-full h-full object-cover">
        <div class=" left-2 top-2 md:left-5 md:top-24 col-span-2 flex absolute">
        </div>
    </div>
    <div class="w-full col-span-8 p-3 pt-5 pl-3 md:pl-0 h-42 relative">
        <div class="grid p-4">
          <div v-if="isLoaded">
            <form
              id="formData"
              @submit.prevent="sendData"
              enctype="multipart/form-data"
              
            >
              <div class="grid grid-cols-2 w-full">
                <div class="w-full">
                  <span v-if="isError">sssss</span>
                  <div class="flex flex-col gap-4 w-full">
                    
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Password</label>
                      <input type="password" placeholder="Password" @keyup="$v.oldpassword.$touch" v-model="formData.oldpassword" name="current_password" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.oldpassword.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                    <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" placeholder="Password" @keyup="$v.password.$touch" v-model="formData.password" name="new_password" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.password.$errors" :key="error.$uid" class="text-red-500 text-sm">{{ error.$message }}</span>
                  </div>
                        <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                      <input type="password" placeholder="Confirm Password" @keyup="$v.ConfirmPassword.$touch" v-model="formData.ConfirmPassword" autocomplete="false" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <span v-for="error in $v.ConfirmPassword.$errors" :key="error.$uid" class="text-red-500 text-sm">Both passwords should match</span>
                  </div>
                    </div>
                    
                    <div class="flex items-center justify-end mt-4">
                        <!-- <FormButton type="submit" label="Register" bstyle="primary"></FormButton> -->
                        <button type="button" @click="submitForm" class=" text-white bg-primary focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Change Password</button>
                    </div>
              </div>
              </div>
            </form>
          </div>
          </div>
        </div>
        <pageFooterVue></pageFooterVue>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import headerNavVue from "../institution/utils/headerNav.vue";
import menuNav from "../institution/utils/menuNav.vue";
import pageFooterVue from "../institution/utils/pageFooter.vue";
export default {
  name: "profilePage",
  data() {
    return {
      datas: [],
      userId: "",
      isLoaded: false,
      selectedFile: null,
      baseUrl : 'https://innodip.rw/',
      selectedBannerPreview:null,
      canEdit:false

    };
  },
  components: {
    headerNavVue,
    menuNav,
    pageFooterVue,

  },
  mounted() {

    const btn = document.querySelector(".toggleMobile");
    const menu = document.querySelector(".mobile-menu");
    const content = document.querySelector(".content");

    btn.addEventListener("click", () => {
      menu.classList.toggle("hidden");
      content.classList.toggle("content");
    });
  },
  methods: {
    
   
    getUser(data){
      this.datas = JSON.parse(data);
      this.selectedFilePreview = this.baseUrl+this.datas.logo
      this.selectedBannerPreview = this.baseUrl+this.datas.banner
      this.isLoaded = true;
      document.title=this.datas.name+" Information";
    //   this.datas.dob = apiService.calendarDate(this.datas.dob)
      const ad = this.datas
      this.datas.address = Object.values(ad).join(', ')
    }
  },
};
</script>

<style scoped>
.image {
  aspect-ratio: 1/1;
}
</style>