<template>
  <div class="items-center bg-gray-50 rounded-lg shadow grid grid-cols-4 dark:bg-gray-800 dark:border-gray-700">
              <div class="flex h-26 w-26 rounded-lg ml-4 object-cover overflow-hidden">
                  <img class="h-full w-full object-cover"  :src="baseUrl + job.provider.industryLogo" alt="logo">
              </div>
              <div class="p-5 col-span-3 flex flex-col gap-3">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      <a href="#">{{ job.confTheme }}</a>
                  </h3>
                  <span v-if="!job.confLink" class="text-gray-500 dark:text-gray-400">{{ job.confDate }}</span>
                  <!-- <span v-if="!job.link" class="text-gray-500 dark:text-gray-400">Category: {{ job.category.industryCategoryName }}</span> -->
                  <!-- <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400"
        v-html="fewWords(job.description, 20)"></p> -->
                  <ul class="flex space-x-4 sm:mt-0">
                    <a v-if="!job.confLink"
    :href="`#`" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Visit
                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </a>
                    <a v-if="job.confLink" target="_blank"
    :href="`${job.confLink}`" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Visit Website
                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
                    </a>
                  </ul>
              </div>
          </div>  
</template>

<script>
import apiService from "../../assets/api/apiService.js";
export default {
  name: "JobCard",
  props: {
    datas: String,
    hasDesc: Boolean,
    router: String,
  },
  data() {
    return {
      job: [],
      baseUrl: "https://backend.innodip.rw/",
    };
  },
  created() {
    this.job = JSON.parse(this.datas);
  },
  methods: {
    realDate(date) {
      return apiService.realDate(date);
    },
    fewWords(words, num) {
      return apiService.displayWords(words, num);
    },
  },
};
</script>

<style scoped>
</style>