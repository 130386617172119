<template>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
        <headerNavVue></headerNavVue>
        <div class="p-4 justify-center">
        <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
            <div class="flex items-center w-full md:w-auto px-2 md:px-0">
                       
                <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <h1 class="text-4xl text-gray-500">Curricula</h1>
            </div>
            
    <router-link to="/institution/curriculum/add/" class="btn-primary" type="button">
      Add Curriculum
    </router-link>
        </div>
        </div>
        
        <div class="grid mx-auto">
            <div class="container mx-auto px-4 sm:px-8">
        <div class="py-8">
            <div class="my-2 flex sm:flex-row flex-col">
                <div class="block relative">
                    <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
                            <path
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                            </path>
                        </svg>
                    </span>
                    <input placeholder="Search" class="appearance-none rounded border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
                </div>
            </div>
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table class="min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Name
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(research,index) in datas" :key="index">
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div class="flex items-center">
                                        <!-- <div class="flex-shrink-0 w-10 h-10">
                                            <img class="w-full h-full rounded-full"
                                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                                                alt="" />
                                        </div> -->
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                               {{research.curriculumName}}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm text-end">
                                    
                                    <router-link :to="`/institution/curriculum/courses/${research._id}`"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden
                                            class="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Details</span>
                                    </router-link>
                                    <router-link :to="`/institution/curriculum/add/${research._id}`"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden
                                            class="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Update</span>
                                    </router-link>
                                    <span v-if="sendDelete" @click="deleteNow(index,research._id)"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden 
                                            class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                        <span class="relative">Yes Delete</span>
                                    </span>
                                    <span @click="sendDelete?sendDelete=false:sendDelete=true"
                                        class="relative cursor-pointer inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span aria-hidden
                                            class="absolute inset-0 bg-black-200 opacity-50 rounded-full"></span>
                                        <span class="relative">{{sendDelete?'No':'Delete'}}</span>
                                    </span>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    </div>

        </div>
        <pageFooterVue></pageFooterVue>
    </div>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import apiService from '../../assets/api/apiService.js'

import AWN from "awesome-notifications"

let globalOptions =  {
  alert: "Oops! Something got wrong",
  position: 'top-right',

}
globalOptions.labels = {
  alert: "Profile",
  position: 'top-right',
}

let signupOption =  {
  success: "Profile",
  position: 'top-right',

}
signupOption.labels = {
  alert: "Profile",
  position: 'top-right',
}

let notifier = new AWN(globalOptions)

    export default {
        name: 'dashboardPage',
        data(){
            return{
                datas:[],
                isLoaded:false,
                sendDelete:null,
                userId:''
            }
        },
        components:{
            headerNavVue,
            menuNav,
            pageFooterVue,
        },
        mounted(){
            this.userId = JSON.parse(localStorage.getItem('currentUser'));
            apiService.getData('fetch-curricula-by-institution-id/'+this.userId).then(res => {
                    this.datas = res.curriculum,
                    this.isLoaded = true
            }).catch(error=>{
                console.log(error.response)

            });
            document.title="Research"
        },
        methods: {
            deleteNow(index,id){
                apiService.deleteData('delete-curricula/'+id).then(res => {
                    console.log(res.response)
                    this.datas.splice(index, 1);
                    notifier.success("curriculum deleted", signupOption)
                    this.sendDelete = false
                });
            }
        }
    }
</script>

<style scoped>
</style>