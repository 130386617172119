<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
        <menuNav></menuNav>
        <headerNavVue @userData="getUser"></headerNavVue>
        <main class="p-4 md:ml-64 h-auto pt-20">
            <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl dark:text-white">
                results for <span class="text-blue-600 dark:text-blue-500">{{ query }}</span>.</h1>
            <div v-if="datas.institutions !=''">
                <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl dark:text-white">
                    results for <span class="text-blue-600 dark:text-blue-500">academia</span>.</h1>
                <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
                    <UniversityCardVue v-for="job in datas.institutions" :key="job" :datas="JSON.stringify(job)"
                        router="/seeker/university" :hasDesc=true></UniversityCardVue>
                </div>
            </div>
            <div v-if="datas.industries!=''">
            <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl dark:text-white">
                results for <span class="text-blue-600 dark:text-blue-500">industries</span>.</h1>
            <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
                <industryCardVue v-for="job in datas.industries" :key="job" :datas="JSON.stringify(job)"
                    router="/seeker/industry" :hasDesc=true></industryCardVue>
            </div>
            </div>
            <div v-if="datas.oportunities!=''">
                <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl dark:text-white">
                    results for <span class="text-blue-600 dark:text-blue-500">opportunities</span>.</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <jobCardVue v-for="job in datas.oportunities" :key="job" :datas="JSON.stringify(job)" router="/seeker/opportunity"
                        :hasDesc=true></jobCardVue>
                </div>
            </div>
            <div v-if="datas.curriculums!=''">
                <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl dark:text-white">
                    results for <span class="text-blue-600 dark:text-blue-500">curriculum</span>.</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <CurriculumCardVue v-for="job in datas.curriculums" :key="job" :datas="JSON.stringify(job)" router="/seeker/opportunity"
                        :hasDesc=true></CurriculumCardVue>
                </div>
            </div>
            <div v-if="datas.publications!=''">
                <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl dark:text-white">
                    results for <span class="text-blue-600 dark:text-blue-500">publications</span>.</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <pubCardVue v-for="job in datas.publications" :key="job" :datas="JSON.stringify(job)" router="/seeker/opportunity"
                        :hasDesc=true></pubCardVue>
                </div>
            </div>
            <div v-if="datas.researches!=''">
                <h1 class="mb-4 text-1xl font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl dark:text-white">
                    results for <span class="text-blue-600 dark:text-blue-500">researches</span>.</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <researchCar v-for="job in datas.researches" :key="job" :datas="JSON.stringify(job)" router="/seeker/opportunity"
                        :hasDesc=true></researchCar>
                </div>
            </div>

        </main>
    </div>
</template>
    
<script>
import headerNavVue from '../seeker/utils/headerNav.vue'
import menuNav from '../seeker/utils/menuNav.vue'
import apiService from '../../assets/api/apiService.js'
import jobCardVue from '../utils/jobCard.vue';
import UniversityCardVue from '../utils/academiaCard.vue';
import CurriculumCardVue from '../utils/curriculumCard.vue';
import industryCardVue from '../utils/industryCard.vue';
import pubCardVue from '../utils/publicationCard.vue';
import researchCar from '../utils/researchCard.vue';

export default {
    name: 'dashboardPage',
    data() {
        return {
            username: 'cyemezo',
            datas: [],
            companies: [],
            institutions: [],
            query: '',
            isLoaded: false
        }
    },
    components: {
        headerNavVue,
        menuNav,
        jobCardVue,
        // pageFooterVue,
        // FormInput,
        industryCardVue,
        UniversityCardVue,
        CurriculumCardVue,
        pubCardVue,
        researchCar
    },
    mounted() {
        this.query = this.$route.query.query
        apiService.getData('search?searchTerm=' + this.query).then(res => {
            this.datas = res,
                this.isLoaded = true
        });
        document.title = "Research for " + this.query
    },
}
</script>
    
<style scoped>.homeImg {
    height: 400px;
}</style>