<template>
  <div>
    <headerNavVue></headerNavVue>
    <div
      class="max-w-screen-lg mx-auto px-3 md:px-0 py-4 justify-center flex flex-col"
    >
      <h1 class="text-2xl md:text-4xl mb-6 text-gray-600">
        About Us
      </h1>
        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-5">
                <h2 class="text-2xl">WHO ARE WE?</h2>
                <p>An innovative digital platform that seamlessly
connects academia and industry. Our platform
offers a holistic experience, enabling users to access
several opportunities including; Jobs, Internships, Scholarships, Trainings, Book Publishing, Curriculum
Review and Development, Conferences and
Consultancies</p>
            </div>
        </div>
    </div>
    <pageFooterVue></pageFooterVue>
  </div>
</template>

<script>
import headerNavVue from "./utils/headerNav.vue";
import pageFooterVue from "./utils/pageFooter.vue";
export default {
  data() {
    return {};
  },
  components: {
    headerNavVue,
    pageFooterVue,
  },
  mounted() {
    document.title = "About Us";
  },
};
</script>

<style scoped></style>
