<template>
    <div class="grid grid-cols-10">
        <div class="col-span-12 md:col-span-2 h-full sticky z-49 top-0">
            <menuNav></menuNav>
        </div>
        <div class="col-span-12 md:col-span-8">
            <headerNavVue @userData="getUser"></headerNavVue>
            <div class="p-4 justify-center">
                <div class="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4">
                    <div class="flex items-center w-full md:w-auto px-2 md:px-0">

                        <svg class="w-16 h-16 text-secondary" aria-hidden="true" fill="none" stroke="currentColor"
                            stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <h1 class="text-4xl text-gray-500">{{isEdit?'Edit':'Add'}} Curriculum</h1>
                    </div>

                </div>
            </div>

            <div class="grid mx-auto">
                <div class="container mx-auto px-4 mb-10 sm:px-8">

                    <ol
                        class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 mb-4">
                        <li class="flex items-center" v-for="(tab, index) in tabs" :key="index"
                            @click="index < curTab ? activateTab(index) : ''" :class="{ 'activea': tab.active }">
                            <span class="flex items-center justify-center w-5 h-5 mr-2 text-xs border"
                                :class="{ 'activeb': tab.active }">
                                {{ tab.number }}
                            </span>
                            {{ tab.text }}
                            <svg v-if="!isLatestStep(index)" class="w-3 h-3 ml-2 sm:ml-4" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                            </svg>
                        </li>
                    </ol>
                    <form id="formData" @submit.prevent="sendData">
                        <input type="hidden" v-model="datas._id" name="provider_id">
                        <div class="flex flex-col md:flex-row gap-4 w-full">
                            <div class="w-full grid grid-cols-2 gap-4" v-if="curTab == 0">
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Name
                                        <strong class="text-red-400">*</strong></label>
                                    <input
                                        class="w-full appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-secondary"
                                        required="required" placeholder="Name" type="text" v-model="researchData.curriculumName" />
                                </div>
                                <div class="w-full flex flex-col col-span-2">
                                    <label class="text-sm mb-2">Description
                                        <strong class="text-red-400">*</strong></label>
                                    <ckeditor v-model="researchData.description" :editor="editor5" @ready="onReady"
                                        @input="onChange"></ckeditor>
                                </div>
                                <div class="flex gap-5">
                                    <button type="button" @click="sendData" :disabled="canSend"
                                        class="text-white bg-blue-700 hover:bg-blue-800 whitespace-nowrap focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {{isEdit?'Update':'Save'}} Curriculum
                                        <svg aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
            <pageFooterVue></pageFooterVue>
        </div>
    </div>
</template>

<script>
import headerNavVue from './utils/headerNav.vue'
import menuNav from './utils/menuNav.vue'
import pageFooterVue from '../seeker/utils/pageFooter.vue'
import apiService from '../../assets/api/apiService.js'
import AWN from "awesome-notifications"
// import $ from 'jquery'
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue"

let globalOptions = {
    alert: "Oops! Something got wrong",
    position: 'top-right',

}
globalOptions.labels = {
    alert: "Profile",
    position: 'top-right',
}

let signupOption = {
    success: "Profile",
    position: 'top-right',

}
signupOption.labels = {
    alert: "Profile",
    position: 'top-right',
}

let notifier = new AWN(globalOptions)

export default {
    name: 'dashboardPage',
    data() {
        return {
            datas: [],
            isLoaded: false,
            showModal: false,
            categories: [],
            curTab: 0,
            canSend:false,
            isEdit:false,
            editor: ClassicEditor,
            editor2: ClassicEditor,
            editor3: ClassicEditor,
            editor4: ClassicEditor,
            editor5: ClassicEditor,
            editor6: ClassicEditor,
            editor7: ClassicEditor,
            tabs: [
                {
                    "text": "Details",
                    "number": "1",
                    "active": true
                },
            ],

            researchData: {
                "curriculumName": "",
                "description": "",
                "institutionId": "",
            }
            ,
        }
    },

    components: {
        headerNavVue,
        menuNav,
        pageFooterVue,
        ckeditor: CKEditor.component

    },
    mounted() {
        const id = this.$route.params.id;
        if(id){
            apiService.getData('fetch-a-curricula-by-id/'+id).then((response) => {
                this.researchData = response.curriculum;
                this.isEdit = true
                document.title = this.isEdit?'Edit Curriculum':'Add Curriculum'
            });
        }else{
            document.title = this.isEdit?'Edit Curriculum':'Add Curriculum'
        }
    },
    methods: {
        isLatestStep(index) {
            return index === this.tabs.length - 1;
        },
        activateTab(index) {
            // Deactivate all tabs
            this.curTab = index;
            this.tabs.forEach((tab) => {
                tab.active = false;
            });
            this.tabs[index].active = true;
        },
        sendData() {
            this.canSend=true
        let id = this.$route.params.id;
            axios
                .post(`https://backend.innodip.rw/api/${typeof id !== 'undefined' ? `update-curricula/${id}` : 'curricula'}`, this.researchData)
                .then((response) => {
                    // Handle the response from the server
                    this.canSend=false
                    console.log("Response:", response.data);
                    notifier.success("Research Added.", signupOption);
                    this.$router.go(0);
                })
                .catch((error) => {
                    // Handle any errors that occur during the request
                    console.error("Error:", error);
                    notifier.alert("Research not added some filelds are not filled.", signupOption);
                });

        },
        getUser(data) {
            this.datas = JSON.parse(data);
            this.selectedFilePreview = this.baseUrl + this.datas.picture
            this.isLoaded = true,
            this.researchData.institutionId = this.datas._id
        },
    }
}
</script>

<style scoped></style>